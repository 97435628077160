import React, { useState } from "react";

import { FormattedMessage } from '../../util/reactIntl';

import Seller from "./components/Seller/Seller";
import Client from "./components/Client/Client";

import css from './SectionTwoUuperNew.module.css'
import classNames from "classnames";

const CLIENT = "client"
const SELLER = "seller"

const SectionTwoUuperNew = props => {
    const [active, setActive] = useState(CLIENT)

    return (
        <main className={css.root}>
            <div className={css.card}>
                <h2 className={css.mainTitle}>
                    <strong>
                        <FormattedMessage id="SectionTwoUuperNew.card1.mainTitle" />
                    </strong>
                </h2>
                <h3 className={css.text}>
                    <FormattedMessage id="SectionTwoUuperNew.card1.text1" />
                    <strong>
                        <FormattedMessage id="SectionTwoUuperNew.card1.text2" />
                    </strong>
                    <br />
                    <FormattedMessage id="SectionTwoUuperNew.card1.text3" values={{ lineBreak: <br /> }} />
                    <strong>
                        <FormattedMessage id="SectionTwoUuperNew.card1.text4" />
                    </strong>
                </h3>
                <div className={css.buttonsContainer}>
                    <div
                        className={classNames(css.button, { [css.active]: active === CLIENT })}
                        onClick={() => setActive(CLIENT)}
                    >
                        <FormattedMessage id="SectionTwoUuperNew.card1.button1" />
                    </div>
                    <div
                        className={classNames(css.button, { [css.active]: active === SELLER })}
                        onClick={() => setActive(SELLER)}
                    >
                        <FormattedMessage id="SectionTwoUuperNew.card1.button2" />
                    </div>
                </div>
            </div>

            {active === CLIENT && <Client />}
            {active === SELLER && <Seller />}
        </main >
    );
};

export default SectionTwoUuperNew;