import React, { Component, useEffect, useState } from 'react';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { NamedLink } from '../../components';
import { lazyLoadWithDimensions } from '../../util/contextHelpers';
import css from './SectionFourUuper.module.css';

class LocationImage extends Component {
  render() {
    const { alt, ...rest } = this.props;
    return <img alt={alt} {...rest} />;
  }
}

const LazyImage = lazyLoadWithDimensions(LocationImage);

const SectionFourUuper = props => {

  const [mounted, setMounted] = useState(false);
  const { rootClassName, className, latestListings } = props;

  useEffect(() => {
    setMounted(true);
  }, []);

  const classes = classNames(rootClassName || css.root, className)

  return (
    <>
    <div className={classes}>
      <div className={css.contailerTitle}>
        <div className={css.triangulo}></div>
        <div className={classNames(css.title, { [css.titleFEDelay]: mounted })}>
          <FormattedMessage id="SectionLocations.title" />
        </div>
        <div className={classNames(css.subtitle, { [css.subtitleFEDelay]: mounted })}>
          <FormattedMessage id="SectionLocations.subtitle1" /> <br />
          <FormattedMessage id="SectionLocations.subtitle2" />
          <span className={css.line}></span>
        </div>
      </div>
      {/* <div className={css.containerCard}>
        <div className={css.locations}>
          {latestListings.map(l => (
            <NamedLink name="SearchPage" to={{ search: '' }} className={css.location} key={l.id.uuid}>
            <div className={css.imageWrapper}>
              <div className={css.aspectWrapper}>
                <LazyImage src={l.images[0].attributes.variants['landscape-crop2x'].url} alt={l.attributes.title} className={css.locationImage} />
              </div>
            </div>
          </NamedLink>
          ))}
        </div>
        <NamedLink
          name="SearchPage" to={{ search: '' }}
          className={classNames(css.oneButton, { [css.oneButtonFEDelay]: mounted })}
        >
          <FormattedMessage id="SectionFourUuper.browseButton" />
        </NamedLink>
      </div> */}
    </div>
    </>
  )
};

export default SectionFourUuper;