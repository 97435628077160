import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import config from '../../config.js';
import { FormattedMessage } from '../../util/reactIntl';
import { ensureOwnListing } from '../../util/data';
import { findOptionsForSelectFilter } from '../../util/search';

import { ListingLink } from '../../components';
import { EditListingCapacityForm } from '../../forms';

// Create this file using EditListingDescriptionPanel.module.css
// as a template.
import css from './EditListingCapacityPanel.module.css';
const FEATURES_NAME = 'area_c';

const EditListingCapacityPanel = props => {
  const {
    className,
    rootClassName,
    listing,
    onSubmit,
    onChange,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    errors,
  } = props;

  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureOwnListing(listing);
  const { publicData } = currentListing.attributes;

  const panelTitle = currentListing.id ? (
    <FormattedMessage
      id="EditListingCapacityPanel.createListingTitle"
      values={{ listingTitle: <ListingLink listing={listing} /> }}
    />
  ) : (
    <FormattedMessage id="EditListingCapacityPanel.createListingTitle" />
  );

  const actividad_cOptions = findOptionsForSelectFilter(
    'actividad_c',
    [{
      id: 'actividad_c',
      label: 'Amenities del ÛUPO',
      type: '',
      group: '',
      queryParamNames: ['pub_actividad_c'],
      config: {
        options: [
          { key: 'actividad_c1', label: 'Alimentación y Bebidas', help: 'Alimentación y Bebidas' },
          { key: 'actividad_c2', label: 'Textil ', help: 'Confección, calzado, pieles y artículos de cuero' },
          { key: 'actividad_c3', label: 'Perfumería y Droguería', help: 'Productos de limpieza, cosméticos, químicos y herbolarios' },
          { key: 'actividad_c4', label: 'Hogar y Construcción', help: 'Decoración, menaje, mueblería, iluminación, materiales de construcción y jardinería' },
          { key: 'actividad_c5', label: 'Vehículos', help: 'Vehículos terrestres, aeronaves y embarcaciones. Accesorios y piezas' },
          { key: 'actividad_c6', label: 'Tecnología, Equipos y Complementos', help: 'Tecnología, Telefonía, Equipos, piezas y complementos' },
          { key: 'actividad_c7', label: 'Instrumentos Musicales', help: 'Instrumentos musicales y accesorios' },
          { key: 'actividad_c8', label: 'Arte, Antigüedades y Coleccionismo', help: 'Objetos de colección. Obras de Arte. Antigüedades. Minerales' },
          { key: 'actividad_c9', label: 'Mobiliario y Equipos industriales', help: 'Mobiliario y equipos destinados a la industria' },
          { key: 'actividad_c10', label: 'Aparatos e instrumentos médicos, ortopédicos y ópticos', help: 'Aparatos e instrumentos médicos, ortopédicos y ópticos' },
          { key: 'actividad_c11', label: 'Librería y Papelería', help: 'Libros, periódicos, artículos de papelería y escritorio, y artículos de dibujo y bellas artes' },
          { key: 'actividad_c12', label: 'Joyería', help: 'Artículos de joyería, relojería, platería y bisutería' },
          { key: 'actividad_c13', label: 'Juguetería y juegos recreativos', help: 'Juguetes' },
          { key: 'actividad_c14', label: 'Deporte', help: 'Artículos de deporte, prendas deportivas de vestido, calzado y tocado' },
          { key: 'actividad_c15', label: 'Agencias de viaje', help: 'Agencias de viaje' },
          { key: 'actividad_c16', label: 'Peluquería y Belleza', help: 'Salones de peluquería e institutos de belleza' },
          { key: 'actividad_c17', label: 'Bienes Usados', help: 'Venta de artículos de segunda mano' },
          
        ],
      },
    },]
  );

  return (
    <div className={classes}>
      <h1 className={css.title}>{panelTitle}</h1>
      <EditListingCapacityForm
        className={css.form}
        name={FEATURES_NAME}
        initialValues={{ actividad_c: publicData.actividad_c, area_c: publicData.area_c,  }}
        onSubmit={values => {
          const { actividad_c, area_c } = values;
          const updateValues = {
            publicData: {
              actividad_c,
              area_c,
            },
          };
          onSubmit(updateValues);
        }}
        onChange={onChange}
        saveActionMsg={submitButtonText}
        updated={panelUpdated}
        updateError={errors.updateListingError}
        updateInProgress={updateInProgress}
        actividad_cOptions={actividad_cOptions}
      />
    </div>
  );
};

const { func, object, string, bool } = PropTypes;

EditListingCapacityPanel.defaultProps = {
  className: null,
  rootClassName: null,
  listing: null,
};

EditListingCapacityPanel.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};

export default EditListingCapacityPanel;