import React, { Component, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { string } from 'prop-types';
import { FormattedMessage, } from '../../util/reactIntl';
import classNames from 'classnames';
import { NamedLink } from '../../components';

import css from './SectionHeroUupo.module.css';
import arrow from '../../assets/arrow.png';

const SectionHeroUupo = props => {
  const [mounted, setMounted] = useState(false);
  const { rootClassName, className, intl } = props;

  useEffect(() => {
    setMounted(true);
  }, []);

  const classes = classNames(rootClassName || css.root, className);

  return (
    <>
      <div className={`${css.heroContent} ${classes}`}>
        <div className={css.contentText}>
          <div className={css.containerArrow}>
            <img className={css.arrowLeft} src={arrow} alt="arrow" />
          </div>
          <div className={css.containerText}>
            <h1 className={classNames(css.heroMainTitle, { [css.heroMainTitleFEDelay]: mounted })}>
              <FormattedMessage id="SectionHeroUupo.title" values={{ lineBreak: <br /> }} />
            </h1>
            <h2 className={classNames(css.heroSubTitle, { [css.heroSubTitleFEDelay]: mounted })}>
              <FormattedMessage id="SectionHeroUupo.subTitle" values={{ lineBreak: <br /> }} />
            </h2>
            <h3 style={{ margin: 0 }}>
              <NamedLink
                className={classNames(css.heroButton, { [css.heroButtonFEDelay]: mounted })}
                name="SignupPage"
              >
                <FormattedMessage id="SectionHeroUupo.register" />
              </NamedLink>
            </h3>
          </div>
        </div>
        <div className={css.contentImg}>
          <iframe src={intl.formatMessage({ id: 'SectionHeroUupo.videoLink' })} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
        </div>
      </div>
    </>
  );
};

const { bool } = PropTypes;

SectionHeroUupo.defaultProps = { rootClassName: null, className: null };

SectionHeroUupo.propTypes = {
  rootClassName: string,
  className: string,
  isAuthenticated: bool.isRequired,
};

export default SectionHeroUupo;