import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import step1 from '../../assets/step1.svg';
import step2 from '../../assets/step2.svg';
import step3 from '../../assets/step3.svg';
import classNames from 'classnames';

import { NamedLink } from '../../components';

import css from './SectionHowItWorks.module.css';

const SectionHowItWorks = props => {
  const { rootClassName, className } = props;

  const classes = classNames(rootClassName || css.root, className);
  return (
    <div className={`${css.howWorksContent} ${classes}`}>
      <p className={css.title}>
        <FormattedMessage id="SectionHowItWorks.titleLineOne" />
      </p>
      <div className={css.line} />

      <div className={css.steps}>
        <div className={css.step}>
          <img src={step1} className={css.image} />
          <p className={css.stepTitle}>
            <FormattedMessage id="SectionHowItWorks.part1Title" />
          </p>
          <p className={css.stepSubtitle}>
            <FormattedMessage id="SectionHowItWorks.part1Text" />
          </p>
        </div>

        <div className={css.step}>
          <img src={step2} className={css.image} />
          <p className={css.stepTitle}>
            <FormattedMessage id="SectionHowItWorks.part2Title" />
          </p>
          <p className={css.stepSubtitle}>
            <FormattedMessage id="SectionHowItWorks.part2Text" />
          </p>
        </div>

        <div className={css.step}>
          <img src={step3} className={css.image} />
          <p className={css.stepTitle}>
            <FormattedMessage id="SectionHowItWorks.part3Title" />
          </p>
          <p className={css.stepSubtitle}>
            <FormattedMessage id="SectionHowItWorks.part3Text" />
            <a href="mailto:hola@uupo.es" className={css.enlaceEmail}>hola@uupo.es</a>
          </p>
        </div>
      </div>
    </div>
  );
};

SectionHowItWorks.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

SectionHowItWorks.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionHowItWorks;
