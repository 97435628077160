import React, { useState } from 'react';
import { bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import {
  autocompleteSearchRequired,
  autocompletePlaceSelected,
  composeValidators,
  required,
} from '../../util/validators';
import { Form, LocationAutocompleteInputField, Button, FieldTextInput } from '../../components';
import img2 from '../../assets/helpVerde.png';

import css from './EditListingLocationForm.module.css';

const identity = v => v;

export const EditListingLocationFormComponent = props => (
  <FinalForm
    {...props}
    render={formRenderProps => {
      const {
        className,
        disabled,
        ready,
        handleSubmit,
        intl,
        invalid,
        pristine,
        saveActionMsg,
        updated,
        updateInProgress,
        fetchErrors,
        values,
      } = formRenderProps;

      const [showHelpMedidas, setShowHelpMedidas] = useState(false);

      const titleRequiredMessage = intl.formatMessage({ id: 'EditListingLocationForm.address' });
      const addressPlaceholderMessage = intl.formatMessage({
        id: 'EditListingLocationForm.addressPlaceholder',
      });
      const addressRequiredMessage = intl.formatMessage({
        id: 'EditListingLocationForm.addressRequired',
      });
      const addressNotRecognizedMessage = intl.formatMessage({
        id: 'EditListingLocationForm.addressNotRecognized',
      });

      const optionalText = intl.formatMessage({
        id: 'EditListingLocationForm.optionalText',
      });

      // const buildingMessage = intl.formatMessage(
      //   { id: 'EditListingLocationForm.building' },
      //   { optionalText: optionalText }
      // );
      // const buildingPlaceholderMessage = intl.formatMessage({
      //   id: 'EditListingLocationForm.buildingPlaceholder',
      // });

      const { updateListingError, showListingsError } = fetchErrors || {};
      const errorMessage = updateListingError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingLocationForm.updateFailed" />
        </p>
      ) : null;

      const errorMessageShowListing = showListingsError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingLocationForm.showListingFailed" />
        </p>
      ) : null;

      const classes = classNames(css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled = invalid || disabled || submitInProgress;

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          {errorMessage}
          {errorMessageShowListing}
          <h3 className={css.title}>
            <FormattedMessage id="EditListingLocationForm.title" />
          </h3>
          <FieldTextInput
            className={css.title}
            type="text"
            name="title"
            id="title"
            autoFocus
            placeholder={intl.formatMessage({ id: 'EditListingLocationForm.titlePlaceholder' })}
          />

          <h3 className={css.title} style={{ marginTop: "32px" }}>{titleRequiredMessage}</h3>
          <LocationAutocompleteInputField
            className={css.locationAddress}
            inputClassName={css.locationAutocompleteInput}
            iconClassName={css.locationAutocompleteInputIcon}
            predictionsClassName={css.predictionsRoot}
            validClassName={css.validLocation}
            name="location"
            placeholder={addressPlaceholderMessage}
            useDefaultPredictions={false}
            format={identity}
            valueFromForm={values.location}
            validate={composeValidators(
              autocompleteSearchRequired(addressRequiredMessage),
              autocompletePlaceSelected(addressNotRecognizedMessage)
            )}
          />

          <h3 className={`${css.title} ${css.titleMedidas}`} style={{ marginTop: "32px" }}>
            <FormattedMessage id="EditListingLocationForm.dimensions" />
            <span
              onMouseEnter={() => setShowHelpMedidas(true)}
              onMouseLeave={() => setShowHelpMedidas(false)}
              style={{ marginLeft: ".4rem", position: "relative" }}
            >
              <img src={img2} style={{ marginBottom: "1rem" }} />
            </span>
            {showHelpMedidas && (
              <div style={{ transition: "opacity 0.1s ease", fontSize: "12px", position: "absolute", background: "#f5f5f5", color: "#4b4b4b", padding: "8px", maxWidth: "716px" }}>
                <div style={{ width: "0", height: "0", borderRight: "10px solid transparent", borderTop: "10px solid transparent", borderLeft: "10px solid transparent", borderBottom: "10px solid #F5F5F5", position: "absolute", top: "0", transform: "translateY(-90%)", left: "21%", }}></div>
                <span>
                  <ul>
                    <li>{intl.formatMessage({ id: "EditListingLocationForm.dimensions.XS" })}</li>
                    <li>{intl.formatMessage({ id: "EditListingLocationForm.dimensions.S" })}</li>
                    <li>{intl.formatMessage({ id: "EditListingLocationForm.dimensions.M" })}</li>
                    <li>{intl.formatMessage({ id: "EditListingLocationForm.dimensions.L" })}</li>
                    <li>{intl.formatMessage({ id: "EditListingLocationForm.dimensions.XL" })}</li>
                    <li>{intl.formatMessage({ id: "EditListingLocationForm.dimensions.XXL" })}</li>
                  </ul>
                </span>
              </div>
            )}
          </h3>
          <div className={css.medida}>
            <FieldTextInput
              type="text"
              name="medida1"
              id="medida1"
              placeholder="450"
            />
            <span>x</span>
            <FieldTextInput
              type="text"
              name="medida2"
              id="medida2"
              label=""
              placeholder="120"
            />
            <span>x</span>
            <FieldTextInput
              type="text"
              name="medida3"
              id="medida3"
              label=""
              placeholder="250"
            />
          </div>

          <h3 className={css.title}>
            <FormattedMessage id="EditListingLocationForm.description" />
          </h3>

          <FieldTextInput
            className={css.description}
            type="text"
            name="description"
            id="description"
            placeholder={intl.formatMessage({ id: "EditListingLocationForm.descriptionPlaceholder" })}
            validate={required(intl.formatMessage({ id: "EditListingLocationForm.descriptionRequired" }))}
          />

          <Button
            className={css.submitButton}
            type="submit"
            inProgress={submitInProgress}
            disabled={submitDisabled}
            ready={submitReady}
          >
            {saveActionMsg}
          </Button>
        </Form>
      );
    }}
  />
);

EditListingLocationFormComponent.defaultProps = {
  selectedPlace: null,
  fetchErrors: null,
};

EditListingLocationFormComponent.propTypes = {
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  selectedPlace: propTypes.place,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
};

export default compose(injectIntl)(EditListingLocationFormComponent);
