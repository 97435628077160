import React, { useState } from 'react';
import { arrayOf, bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import img1 from '../../assets/help.png';
import classNames from 'classnames';

// These relative imports need to point to correct directories
import {
  intlShape,
  injectIntl,
  FormattedMessage,
} from '../../util/reactIntl';
import { findOptionsForSelectFilter } from '../../util/search';
import { propTypes } from '../../util/types';
import { required } from '../../util/validators';
import config from '../../config';
import { Form, Button, FieldCheckboxGroup, FieldSelect } from '../../components';

// Create this file using EditListingFeaturesForm.module.css
// as a template.
import css from './EditListingCapacityForm.module.css';
import { localizeFilters } from '../../util/filtersLocalizationHelpers';

export const EditListingCapacityFormComponent = props => (
  <FinalForm
    {...props}
    mutators={{ ...arrayMutators }}
    render={formRenderProps => {
      const {
        className,
        name,
        disabled,
        handleSubmit,
        intl,
        invalid,
        pristine,
        saveActionMsg,
        updated,
        updateError,
        updateInProgress,
        actividad_cOptions,
        filterConfig,
        initialValues,
      } = formRenderProps;

      let actividadCInitial = ''
      if (initialValues.actividad_c !== undefined) {
        const a = actividad_cOptions.find((d) => {
          return d.key === initialValues.actividad_c
        })

        const localizedHelp = intl.formatMessage({ id: "Filters." + a.key + ".help" });
        actividadCInitial = localizedHelp
      }

      const [ActividadCHelp, setActividadCHelp] = useState(actividadCInitial);

      const actividad_cOptionsPlaceholder = intl.formatMessage({
        id: 'EditListingCapacityForm.actividad_cOptionsPlaceholder',
      });

      const actividad_cOptionsRequired = required(
        intl.formatMessage({
          id: 'EditListingCapacityForm.actividad_cOptionsRequired',
        })
      );

      const errorMessage = updateError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingCapacityForm.updateFailed" />
        </p>
      ) : null;

      const classes = classNames(css.root, className);
      const submitReady = updated && pristine;
      const submitInProgress = updateInProgress;
      const submitDisabled = invalid || disabled || submitInProgress;

      const options = localizeFilters(findOptionsForSelectFilter('area_c', filterConfig), intl);

      function handleChangeHelp(event) {
        if (event.target.value == '') {
          setActividadCHelp('')
        } else {
          const a = actividad_cOptions.find((d) => {
            return d.key === event.target.value
          })
          setActividadCHelp(
            intl.formatMessage({ id: "Filters." + event.target.value + ".help" })
          )
          // a.help)
        }
      }

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          {errorMessage}

          <h3 className={css.title}>
            <FormattedMessage id="EditListingCapacityForm.title" />
          </h3>
          <p className={css.textPlace} >
            <FormattedMessage id="EditListingCapacityForm.selectLocation" />
          </p>
          <FieldCheckboxGroup id={name} name={name} options={options} />
          <br />
          <h3 className={css.title}>
            <FormattedMessage id="EditListingCapacityForm.commercialActivity" />
          </h3>
          <FieldSelect
            className={css.actividad_c}
            name="actividad_c"
            id="actividad_c"
            validate={actividad_cOptionsRequired}
            handleinputchange={handleChangeHelp}
          >
            <option value="">{actividad_cOptionsPlaceholder}</option>
            {actividad_cOptions.map(c => (
              <option key={c.key} value={c.key}>
                {intl.formatMessage({ id: "Filters." + c.key })}
              </option>
            ))}
          </FieldSelect>
          {(ActividadCHelp) && <div className={css.textHelp}>
            <img src={img1} className={css.imageIcon} />
            <span>
              {ActividadCHelp}
            </span>
          </div>}
          <Button
            className={css.submitButton}
            type="submit"
            inProgress={submitInProgress}
            disabled={submitDisabled}
            ready={submitReady}
          >
            {saveActionMsg}
          </Button>
        </Form>
      );
    }}
  />
);

EditListingCapacityFormComponent.defaultProps = {
  selectedPlace: null,
  updateError: null,
  filterConfig: config.custom.filters,
};

EditListingCapacityFormComponent.propTypes = {
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  updated: bool.isRequired,
  updateError: propTypes.error,
  updateInProgress: bool.isRequired,
  filterConfig: propTypes.filterConfig,
  actividad_cOptions: arrayOf(
    shape({
      key: string.isRequired,
      label: string.isRequired,
    })
  ).isRequired,
};

export default compose(injectIntl)(EditListingCapacityFormComponent);