import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './SecurityPriority.module.css';

const SecurityPriority = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  // prettier-ignore
  return (
    <div className={classes}>
   
      <p>
        Para que puedas sentir que tu propuesta pertenece a cualquier rincón del mundo es necesario establecer una confianza basada en unas expectativas coherentes del comportamiento de los ÛUPOs y los ÛUPERs. Establecimos estas normas de la comunidad como guía de conducta y para concretar los valores que representan las bases de nuestra comunidad global.
      </p>

      <p>Este documento se actualiza con regularidad, ya que nunca dejamos de pulir nuestro enfoque a fin de satisfacer las necesidades de nuestra comunidad. Estos cinco estándares (seguridad, protección, imparcialidad, autenticidad y confiabilidad) son los pilares básicos de nuestros esfuerzos para ayudar a garantizar la seguridad y fomentar el sentimiento de pertenencia. Nos esforzamos continuamente por afianzarlos y ponerlos en práctica.</p>

      <p className={css.titleRefund}>
        Seguridad
      </p>
      
      <p>
        Tu aventura ÛUPO.com comienza en el momento en que te dejas llevar por lo nuevo y lo desconocido. Eso solo es posible cuando te sientes a salvo dentro de esta comunidad. Como resultado, te pedimos que te abstengas de poner en peligro o amenazar a los demás.
      </p>

      <p className={css.titleRefund}>
        Hacerte daño a ti mismo o a los demás
      </p>
      
      <p>
        No debes cometer agresiones físicas o sexuales, abuso sexual, acoso sexual, violencia doméstica, robo, tráfico de personas, otros actos de violencia, ni retener a nadie en contra de su voluntad. Los miembros de organizaciones peligrosas, incluyendo grupos terroristas, del crimen organizado y racistas, no son bienvenidos en esta comunidad. UUPOCOWORKING SLL se compromete a colaborar con las fuerzas del orden como sea necesario y responder a sus solicitudes válidas.
      </p>
      <p>Nos tomamos muy en serio el suicidio, los trastornos de la conducta alimentaria y el abuso de drogas, y trabajamos para ayudar a las personas en crisis.</p>

      <p className={css.titleRefund}>
        ¿Cómo funcionan las reclamaciones?
      </p>
      
      <p>
        Para ser elegible para un establecimiento alternativo o un reembolso, el ÛUPER que confirmó la reserva puede comunicarse con nosotros  para presentar una reclamación. Las reclamaciones deben enviarse, a más tardar, 72 horas después de haber tomado conocimiento del Contratiempo de Organización, y deberán ir acompañadas de pruebas relevantes, como fotografías o una confirmación de las condiciones por parte del ÛUPO. Evaluaremos las pruebas disponibles para determinar si se produjo un Contratiempo de Organización.
      </p>

      <p className={css.titleRefund}>
        Amenazar a alguien
      </p>
      
      <p>
        No debes intentar dañar a otras personas verbal ni físicamente. Del mismo modo, las amenazas de lastimarse deliberadamente son tan serias para nosotros como las medidas que tomamos al respecto y podremos en caso de que se produzca alguna.
      </p>

      <p className={css.titleRefund}>
        Crear situaciones peligrosas
      </p>
      
      <p>
        No debes dejar al alcance armas, factores de riesgo para enfermedades o animales peligrosos en tu espacio, ni debes crear condiciones que aumenten la probabilidad de un incendio o que obstruyan rutas de evacuación en caso de emergencia.
      </p>
    
      <p className={css.titleRefund}>
        Seguridad
      </p>
      
      <p>
        Los miembros de la comunidad de ÛUPO.com comparten sus potenciales, comercios y barrios. Ya sea que estés abriendo tu comercio como ÛUPO o que vivas la experiencia como ÛUPER, queremos que siempre puedas confiar en tu seguridad. Te pedimos que respetes la propiedad de los demás, su información y pertenencias personales.
      </p>

      <p className={css.titleRefund}>
        Robo, vandalismo o extorsión
      </p>
      
      <p>
      No debes tomar pertenencias que no sean tuyas, usar la propiedad de alguien más sin su permiso, copiar las llaves o identificaciones de otros, dañar la propiedad ajena, permanecer en un espacio después de que la estancia concluyó, ni amenazar a nadie con evaluaciones negativas o cualquier otra sanción o daño para obtener compensaciones u otros beneficios.
      </p>

      <p className={css.titleRefund}>
        Correo basura, robo de información confidencial o fraude
      </p>
      
      <p>
        No debes llevar a cabo transacciones fuera de la plataforma; apartar, cometer fraude crediticio o lavado de dinero; intentar desviar tráfico a otros sitios o productos no relacionados con el mercado; desviar pagos hechos a terceros; abusar de nuestro sistema de referencias; o levantar falso testimonio en contra de otro miembro de la comunidad.
      </p>

      <p className={css.titleRefund}>
        Atentar contra la privacidad o los derechos de propiedad intelectual de los demás
      </p>
      
      <p>
        No debes espiar a otras personas; no se permite tener cámaras en tu espacio, a menos de que reveles su presencia de antemano y sean visibles, y nunca se permiten en lugares privados (como baños o dormitorios). No debes acceder a las cuentas de otros sin su permiso, ni violar su privacidad, derechos de autor o marcas registradas.
      </p>

      <p className={css.titleRefund}>
        Trato justo
      </p>
      
      <p>
        La comunidad global de ÛUPO.com es tan diversa, única y vibrante como el mundo que nos rodea. Ser justos es lo que nos mantiene unidos, lo que permite que nos integrarnos en una comunidad y sintamos que en verdad podemos pertenecer en cualquier lugar.
      </p>

      <p className={css.titleRefund}>
        Conducta discriminatoria o incitaciones al odio
      </p>
      
      <p>
        Debes tratar a todas las personas respetuosamente en cada interacción. Así que debes apegarte a las leyes pertinentes y no tratar a nadie de manera distinta por su raza, etnia, nacionalidad, religión, orientación sexual, sexo, género, identidad de género, discapacidad o enfermedad grave. En consecuencia, no toleramos los insultos u ofensas a otras personas por estos motivos.
      </p>

      <p className={css.titleRefund}>
        Intimidar o acosar a los demás
      </p>
      
      <p>
        No debes compartir la información personal de otras personas con el propósito de humillarlas o chantajearlas ni mostrar una actitud abusiva hacia ellas o difamarlas o infringir nuestras reglas de evaluación y de contenidos.
      </p>

      <p className={css.titleRefund}>
        Alterar a la comunidad cercana
      </p>
      
      <p>
        No debes perturbar los espacios comunes, a los profesionales, o vecinos , crear molestias generalizadas y severas para los que te rodean, o dejar de responder a las preocupaciones de los profesionales, vecinos o de la comunidad.
      </p>

      <p className={css.titleRefund}>
        Autenticidad
      </p>
      
      <p>
        Tus experiencias como ÛUPO/ÛUPER deben estar llenas de momentos encantadores y eventos sorprendentes. Ya que nuestra comunidad se basa en la confianza, la honestidad es imprescindible; depende de un equilibrio entre expectativas comunes, interacciones auténticas e información veraz.
      </p>

      <p className={css.titleRefund}>
        Mentir sobre tu identidad
      </p>
      
      <p>
        No debes proporcionar un nombre o fecha de nacimiento falsos, usar anuncios para fines comerciales diferentes a los acordados sin autorización de tu ÛUPO, mantener cuentas duplicadas o crear una cuenta si eres menor de 18 años.
      </p>

      <p className={css.titleRefund}>
        Mentir sobre tus espacios
      </p>
      
      <p>
        No debes proporcionar información incorrecta o engañosa en tu anuncio sobre la ubicación, disponibilidad o tipo de propiedad de tu Pop Up, cambiar un Pop Up por otro, crear anuncios fraudulentos, dejar evaluaciones falsas, aplicar precios engañosos u ocultar información sobre posibles peligros o problemas de habitabilidad.
      </p>

      <p className={css.titleRefund}>
        Experiencias que solo son transacciones
      </p>
      
      <p>
        UUPOCOWORKING SLL inició como una forma de permitir a las personas compartir sus Espacios Comerciales de forma temporal. Desde sus comienzos, UUPOCOWORKING SLL ha crecido y los establecimientos compartidos han llegado a todos lados de España, pero seguimos esperando que cada espacio sea, más que una mera transacción, un lugar al que los demás pueden pertenecer.
      </p>

      <p className={css.titleRefund}>
        Confiabilidad
      </p>
      
      <p>
        Cada experiencia de ÛUPO.com es única y los detalles de cada local, población y ÛUPO son únicos. Ya que los compromisos de nuestra comunidad se basan en estos detalles, necesitamos poder confiar los unos en los otros, ya sea mediante comunicación oportuna, en las condiciones de un espacio o en las expectativas que fijamos.
      </p>

      <p className={css.titleRefund}>
        Ofrecer espacios inapropiados
      </p>
      
      <p>
        No debes proporcionar espacios comerciales con un nivel de limpieza inferior al estándar o que no cuenten con agua corriente o luz. Tampoco debes ofrecer espacios donde no se pueda exponer, que no reciban clientes potenciales, o que no tengan acceso a instalaciones sanitarias (por ejemplo, indicarle a los ÛUPERs que utilicen los baños públicos).
      </p>

      <p className={css.titleRefund}>
        Romper compromisos
      </p>
      
      <p>
        Salvo por causas de fuerza mayor, no debes cancelar después de la fecha límite que se encuentra en la política de cancelación del anuncio, imposibilitar el registro, dejar de pagar o romper las reglas del local y su comunidad.
      </p>

      <p className={css.titleRefund}>
        No responder
      </p>
      
      <p>
        No debes recibir calificaciones bajas de forma continua y reiterada, ausentarte durante el proceso de reserva o durante la celebración del evento comercial, dejar de proporcionar un punto de contacto adecuado o negarte a participar en nuestro proceso de resolución.
      </p>

    </div>
  );
};

SecurityPriority.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

SecurityPriority.propTypes = {
  rootClassName: string,
  className: string,
};

export default SecurityPriority;
