import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconSocialMediaLinkedin.module.css';

const IconSocialMediaLinkedin = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);
  // width="16"
  // height="14"
  // viewBox="0 0 16 14"
  return (
    <svg
      className={classes}
      width="16"
      height="14"
      viewBox="0 0 26 25"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_1086_66)">
        <path d="M0 2.90404C0 2.06227 0.292801 1.36783 0.878378 0.820707C1.46396 0.273561 2.22523 0 3.16216 0C4.08238 0 4.82689 0.269343 5.39575 0.808081C5.98133 1.36364 6.27413 2.08753 6.27413 2.9798C6.27413 3.78788 5.98971 4.46126 5.42085 5C4.83527 5.55556 4.06564 5.83333 3.11197 5.83333H3.08687C2.16666 5.83333 1.42214 5.55556 0.853282 5C0.284419 4.44444 0 3.74578 0 2.90404ZM0.326255 25V8.13131H5.89768V25H0.326255ZM8.98456 25H14.556V15.5808C14.556 14.9916 14.6229 14.537 14.7568 14.2172C14.991 13.6448 15.3465 13.1608 15.8234 12.7652C16.3002 12.3695 16.8983 12.1717 17.6178 12.1717C19.4916 12.1717 20.4286 13.4428 20.4286 15.9848V25H26V15.3283C26 12.8367 25.4144 10.947 24.2432 9.65909C23.0721 8.37121 21.5245 7.72727 19.6004 7.72727C17.4421 7.72727 15.7606 8.66162 14.556 10.5303V10.5808H14.5309L14.556 10.5303V8.13131H8.98456C9.01801 8.67003 9.03475 10.3451 9.03475 13.1566C9.03475 15.968 9.01801 19.9158 8.98456 25Z" />
      </g>
      <defs>
        <clipPath id="clip0_1086_66">
          <rect width="26" height="25" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

IconSocialMediaLinkedin.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

IconSocialMediaLinkedin.propTypes = { rootClassName: string, className: string };

export default IconSocialMediaLinkedin;
