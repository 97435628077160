import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './RefundPolicy.module.css';

const RefundPolicyEnglish = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  // prettier-ignore
  return (
    <div className={classes}>
      <p className={css.lastUpdated}>Effective date: December 1, 2022</p>

      <p>
        This Refund and Reservation Assistance Policy explains how we will assist in changing a reservation and how we manage refunds when a ÛUPO cancels a reservation or another type of Organizational Disruption disrupts a Pop Up event.
      </p>

      <p className={css.titleRefund}>
        What happens if a ÛUPO cancels the reservation before arrival?
      </p>
      
      <p>
        If a ÛUPO cancels a reservation before check-in, the ÛUPER will automatically receive a full refund. Additionally, if the ÛUPO cancels within 30 days or less before check-in and the ÛUPER contacts us, we will help the ÛUPER find a similar or better establishment.
      </p>

      <p className={css.titleRefund}>
        What happens if another Organizational Disruption disrupts my Pop Up event?
      </p>
      
      <p>
        Other Organizational Disruptions must be reported to us no later than 72 hours after becoming aware of them. If we determine that an Organizational Disruption has prevented the proper development of the Pop Up event, we will process a full or partial refund and, depending on the circumstances, we may assist the ÛUPER in finding a similar or better Pop Up event. The amount of the refund depends on the severity of the Organizational Disruption, the impact on the ÛUPER, the portion of the stay affected, and whether the ÛUPER leaves the establishment. If the ÛUPER decides to leave the Pop Up event due to the Organizational Disruption and contacts us, we will assist them in finding another similar or better space for the remaining days of their Pop Up event.
      </p>

      <p className={css.titleRefund}>
        What Organizational Disruptions are covered?
      </p>
      
      <p>
        The term “Organizational Disruption” refers to the following situations:
        <ul className={css.listRefund}>
          <li>The ÛUPO cancels the reservation before arrival.</li>
          <li>The ÛUPO does not provide access to the establishment.</li>
          <li>The ÛUPO does not disclose in the Listing that certain people or pets will be present at the ÛUPO during the Pop Up event.</li>
          <li>The establishment, or the Pop Up, is not in habitable condition at the time of check-in for any of the following reasons:
            <ul className={css.listRefund}>
              <li>The establishment, or any part of it, is not in adequate hygienic and sanitary condition.</li>
              <li>The establishment contains elements hazardous to health or safety.</li>
              <li>There are pests or vermin in the establishment.</li>
            </ul>
          </li>
          <li>The Listing contains a substantial inaccuracy, for example:
            <ul className={css.listRefund}>
              <li>An incorrect type of establishment or Pop Up is indicated (e.g., other dimensions or features).</li>
              <li>The location of the establishment is incorrect.</li>
              <li>A service or special features described in the Listing are not available or operational.</li>
            </ul>
          </li>
        </ul>
      </p>

      <p className={css.titleRefund}>
        How do claims work?
      </p>
      
      <p>
        To be eligible for an alternative establishment or a refund, the ÛUPER who confirmed the reservation can contact us to file a claim. Claims must be submitted no later than 72 hours after becoming aware of the Organizational Disruption and must be accompanied by relevant evidence, such as photographs or confirmation of the conditions from the ÛUPO. We will evaluate the available evidence to determine if an Organizational Disruption has occurred.
      </p>

      <p className={css.titleRefund}>
        How does this Policy affect ÛUPOs?
      </p>
      
      <p>
        If a ÛUPO cancels a reservation or if an Organizational Disruption prevents the proper development of a Pop Up event, the ÛUPO will not receive any payment or the amount of the refund sent to the ÛUPER will be deducted from their payment.
      </p>
      <p>In most cases, we will attempt to corroborate a ÛUPER's claim with their ÛUPO. ÛUPOs can also dispute claims related to an Organizational Disruption by contacting us.</p>

      <p className={css.titleRefund}>
        Other considerations to keep in mind
      </p>
      
      <p>
        This Policy applies to all confirmed reservations from the Effective Date, including the same day it takes effect. When this Policy applies to a reservation, these provisions will prevail over the corresponding cancellation policy for the reservation.
      </p>
      <p>Before filing a claim, whenever possible, the ÛUPER must inform the ÛUPO and attempt to resolve the Organizational Disruption with their ÛUPO. Regarding the resolution of the disruption, the ÛUPER can request a refund directly from the ÛUPO through the Resolution Center. We may reduce the amount of any refund or adjust any assistance provided for changing the reservation under this Policy when necessary to reflect refunds or compensations a ÛUPO has provided directly. As part of the reservation change assistance, UUPOCOWORKING SLL may, at its discretion, pay for an alternative Pop Up event or cover part of its cost. We may also offer the ÛUPER the option to apply the amount paid for the canceled Pop Up event towards booking another Pop Up event.</p>
      <p>If the ÛUPER demonstrates that it was not possible to report an Organizational Disruption in a timely manner, we may accept their notification outside the period provided in this Policy. This Policy does not cover Organizational Disruptions caused by the ÛUPER or their companions, guests, or pets. Filing a fraudulent claim will constitute a breach of our Terms and Conditions and may result in the removal of the account.</p>
      <p>Decisions made under this Policy will be binding but will not affect other contractual or legal rights that may apply. Any rights that ÛUPOs or ÛUPERs have to take legal action will not be affected. This Policy does not constitute insurance, and no ÛUPER or ÛUPO has paid a premium for it. All rights and obligations that this Policy confers on ÛUPOs and ÛUPERs of a reservation are individual and cannot be transferred or assigned. Any changes to this Policy will be made in accordance with our Terms and Conditions.</p>

    </div>
  );

};

RefundPolicyEnglish.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

RefundPolicyEnglish.propTypes = {
  rootClassName: string,
  className: string,
};

export default RefundPolicyEnglish;
