import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './PrivacyPolicy.module.css';

const PrivacyPolicy = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  // prettier-ignore
  return (
    <div className={classes}>
      <p className={css.lastUpdated}>Última actualización: 1 de diciembre de 2022</p>

      <p>
        UUPOCOWORKING SLL existe para ayudar a conectar a los profesionales y construir una economía más abierta e inclusiva. Nuestra comunidad se basa en la confianza. Para ganárnosla es fundamental que seamos transparentes con respecto a cómo utilizamos sus datos y cómo protegemos su derecho humano a la privacidad.
      </p>
      <p>Esta Política de Privacidad describe cómo UUPOCOWORKING SLL (“nosotros,” “nuestro(s), nuestra(s), nos,” o “UUPOCOWORKING SLL”), tratan datos personales en relación con el uso que usted hace de la Plataforma ÛUPO.com. Por favor, revise la información que describe cómo procesamos la información personal.</p>

      <p className={css.titleRefund}>
        POLITICA DE PRIVACIDAD
      </p>
      
      <p>
      UUPOCOWORKING S.L.L pone a su disposición a través de esta página web la presente política de privacidad con la finalidad de informarle de forma detallada, sobre cómo tratamos sus datos personales y protegemos su privacidad y la información que nos proporciona.
      </p>
      <p>En esta web, UUPOCOWORKING S.L.L garantiza la seguridad y confidencialidad de los datos de carácter personal facilitados por las personas físicas con las que se relaciona mediante la implementación de las medidas técnicas y organizativas tendentes a tal fin.</p>
      <p>En caso de introducir modificaciones en un futuro se lo comunicaremos a través de la página web o a través de otros medios de modo que pueda conocer las nuevas condiciones de privacidad introducidas.</p>
      <p>En cumplimiento del Reglamento (UE) 2016/679, General de Protección de Datos y de la Ley Orgánica 3/2018, de 5 de diciembre, de Protección de Datos Personales y garantía de los derechos digitales le informamos de lo siguiente:</p>

      <p className={css.titleRefund}>
        Responsable del tratamiento
      </p>
      
      <p>
        UUPOCOWORKING S.L.L. CIF: B72446073. Correo electrónico: hola@uupo.es
      </p>

      <p className={css.titleRefund}>
        ¿Con qué finalidad tratamos sus datos personales?
      </p>
      
      <div>
        En UUPOCOWORKING S.L.L recabamos y tratamos su información personal con carácter general para gestionar la relación que mantenemos con Ud. siendo las principales finalidades que tenemos identificadas las siguientes: 
        <ul className={css.listRefund}>
          <li>
            Gestión y contratación de los productos y servicios ofrecidos por nuestra empresa
          </li>
          <li>Canalizar las solicitudes de información, sugerencias y reclamaciones que nos pueda hacer llegar por vía electrónica o de otra naturaleza.</li>
          <li>Mantenerle informado sobre eventos, ofertas, productos y servicios que puedan resultar de su interés a través de distintos canales de comunicación siempre y cuando Ud. haya prestado su consentimiento.</li>
          <li>Gestión de la relación laboral, en el caso de nuestros empleados.</li>
          <li>Gestión de la relación comercial mantenida con nuestros proveedores</li>
          <li>Gestión de la selección de personal</li>
        </ul>
      </div>

      <p className={css.titleRefund}>
        ¿Cuál es la base legal para tratar sus datos?
      </p>
      
      <p>
      Con la cumplimentación del presente formulario y la aceptación de la política de privacidad, usted presta su consentimiento a UUPOCOWORKING S.L.L para el tratamiento de sus datos con el fin de remitirle información de carácter administrativa, técnica, organizativa, divulgativa y/o comercial sobre nuestros productos y servicios, respondiendo a su solicitud también por un interés legítimo.
      </p>
      <p>UUPOCOWORKING S.L.L, en el desarrollo de su actividad empresarial puede utilizar además otras bases legales para el tratamiento de los datos personales</p>
      <ul className={css.listRefund}>
        <li>Consentimiento del interesado para uno o varios fines.</li>
        <li>Necesario para la ejecución de un contrato o para la aplicación a petición de este de medidas precontractuales.</li>
        <li>Necesario para proteger intereses vitales del interesado o de otras personas.</li>
        <li>Necesario para el cumplimiento de una obligación legal aplicable al responsable.</li>
        <li>Necesario para el cumplimiento de una misión realizada en interés público o en el ejercicio de poderes públicos.</li>
        <li>Necesario para la satisfacción de intereses legítimos perseguidos por el responsable o por un tercero.</li>
      </ul>
      <p>Ud. será informado de la base legal utilizada en cada relación comercial.</p>
      <p className={css.titleRefund}>
        ¿Cómo recabamos su información?
      </p>
      <p>Recabamos su información personal a través de diferentes medios, pero siempre será informado en el momento de la recogida mediante cláusulas informativas sobre el responsable del tratamiento, la finalidad y la base legal del mismo, los destinatarios de los datos y el periodo de conservación de su información, así como la forma en que puede ejercer los derechos que le asisten en materia de protección de datos.</p>
      <p>En general, la información personal que tratamos se limita a datos identificativos (nombre y apellidos, fecha de nacimiento, domicilio, DNI, teléfono y correo electrónico), servicios contratados y datos de pago y facturación. </p>
      <p>En los casos de gestión y selección de personal recogemos los datos académicos y profesionales para poder atender a las obligaciones derivadas del mantenimiento de la relación laboral o en su caso, entrar a formar parte de nuestra plantilla.</p>
      <p>UUPOCOWORKING S.L.L puede utilizar redes sociales y esta es otra forma de llegar a usted. La información recogida a través de los mensajes y comunicaciones que publica puede contener información personal que se encuentra disponible online y accesible al público. Estas redes sociales cuentan con sus propias políticas de privacidad donde se explica cómo utilizan y comparten su información, por lo que UUPOCOWORKING S.L.L le recomienda que las consulte antes de hacer uso de estas para confirmar que está de acuerdo con la forma en que su información es recogida, tratada y compartida.</p>
      <p>A través de nuestra página web recabamos información personal relacionada con su navegación a través del uso de cookies. Para conocer de manera clara y precisa las cookies que utilizamos, cuáles son sus finalidades y cómo puede configurarlas o deshabilitarlas, consulte nuestra Política de Cookies.</p>

      <p className={css.titleRefund}>
        Responsabilidad del usuario
      </p>
      <p>Al facilitarnos sus datos a través de canales electrónicos, el usuario garantiza que es mayor de 14 años y que los datos facilitados a UUPOCOWORKING S.L.L son verdaderos, exactos, completos y actualizados. A estos efectos, el usuario confirma que responde de la veracidad de los datos comunicados y que mantendrá convenientemente actualizada dicha información de modo que responda a su situación real, haciéndose responsable de los datos falsos e inexactos que pudiera proporcionar, así como de los daños y perjuicios, directos o indirectos, que pudieran derivarse.</p>

      <p className={css.titleRefund}>
        ¿Durante cuánto tiempo conservamos su información?
      </p>
      <p>En UUPOCOWORKING S.L.L sólo conservamos su información por el periodo de tiempo necesario para cumplir con la finalidad para la que fue recogida, dar cumplimiento a las obligaciones legales que nos vienen impuestas y atender las posibles responsabilidades que pudieran derivar del cumplimiento de la finalidad por la que los datos fueron recabados.</p>
      <p>En el caso de que quiera entrar a formar parte de nuestra plantilla y opte a uno de nuestros puestos de trabajo, los datos proporcionados pasarán a formar parte de nuestra bolsa de empleo y se conservarán mientras dure el proceso selectivo y por un máximo de un año o hasta que Ud. ejerza su derecho de supresión.</p>
      <p>Si en algún momento hemos recogido sus datos para dirigirnos a usted como potencial usuario de nuestros servicios o dar respuesta a una solicitud de información realizada por su parte, dichos datos serán conservados por un máximo de un año desde su recogida, pasando a eliminarse transcurrido dicho plazo si no se ha formalizado una relación contractual o en el momento en que así nos lo solicite.</p>
      <p>En todo caso, y por regla general, mantendremos su información personal mientras exista una relación contractual que nos vincule o usted no ejerza su derecho de supresión y/o limitación del tratamiento, en cuyo caso, la información será bloqueada sin darle uso más allá de su conservación, mientras pueda ser necesaria para el ejercicio o defensa de reclamaciones o pudiera derivarse algún tipo de responsabilidad que tuviera que ser atendida.</p>

      <p className={css.titleRefund}>
        ¿A quién comunicamos sus datos?
      </p>
      <p>En general, en UUPOCOWORKING S.L.L no compartimos su información personal, salvo aquellas cesiones que debemos realizar en base a obligaciones legales impuestas.</p>
      <p>No obstante, para desarrollar y prestarle el servicio solicitado cedemos sus datos a otras empresas y/o startups colaboradoras.</p>
      <p>Puede comunicarnos su oposición a la cesión de sus datos, aunque en ese caso, no sería posible prestarle el servicio solicitado.</p>
      <p>Aunque no se trata de una cesión de datos, para prestarle el servicio solicitado puede ser que terceras empresas, que actúan como proveedores nuestros, accedan a su información para llevar a cabo el servicio que les hemos contratado. Estos encargados acceden a sus datos siguiendo nuestras instrucciones y sin que puedan utilizarlos para una finalidad diferente y manteniendo la más estricta confidencialidad.</p>
      <p>Asimismo, su información personal estará a disposición de las Administraciones Públicas, Jueces y Tribunales, para la atención de posibles responsabilidades nacidas del tratamiento.</p>

      <p className={css.titleRefund}>
        Transferencias internacionales de datos
      </p>
      <p>No existen transferencias internacionales de sus datos a países fuera del Espacio Económico Europeo (EEE).</p>
      <p>Con nuestros proveedores hemos acordado que, para la prestación del servicio contratado, hagan uso de servidores ubicados en el EEE y si, en un futuro, necesitásemos hacer uso de servidores localizados fuera del territorio de la UE, se adoptarán las medidas adecuadas, que serán incorporadas a esta Política de Privacidad, garantizando que dichos proveedores se hallan bajo el acuerdo de Privacy Shield o que existen otras garantías adecuadas.</p>

      <p className={css.titleRefund}>
        ¿Cuáles son sus derechos con relación al tratamiento de sus datos y cómo puede ejercerlos?
      </p>
      <p>La normativa en materia de protección de datos permite que pueda ejercer sus derechos de acceso, rectificación, supresión y portabilidad de datos y oposición y limitación a su tratamiento, así como a no ser objeto de decisiones basadas únicamente en el tratamiento automatizado de sus datos, cuando proceda.</p>
      <p>Estos derechos se caracterizan por lo siguiente:</p>
      <ul className={css.listRefund}>
        <li>Su ejercicio es gratuito, salvo que se trate de solicitudes manifiestamente infundadas o excesivas (p. ej., carácter repetitivo), en cuyo caso UUPOCOWORKING S.L.L podrá cobrar un canon proporcional a los costes administrativos soportados o negarse a actuar</li>
        <li>Puede ejercer los derechos directamente o por medio de tu representante legal o voluntario</li>
        <li>Debemos responder a su solicitud en el plazo de un mes, aunque, si se tiene en cuenta la complejidad y número de solicitudes, se puede prorrogar el plazo en otros dos meses más.</li>
        <li>Tenemos la obligación de informarle sobre los medios para ejercitar estos derechos, los cuales deben ser accesibles y sin poder denegarle el ejercicio del derecho por el solo motivo de optar por otro medio. Si la solicitud se presenta por medios electrónicos, la información se facilitará por estos medios cuando sea posible, salvo que nos solicite que sea de otro modo.</li>
        <li>Si UUPOCOWORKING S.L.L no da curso a la solicitud, le informará, a más tardar en un mes, de las razones de su no actuación y la posibilidad de reclamar ante una Autoridad de Control</li>
      </ul>
      <p>Para ejercer sus derechos UUPOCOWORKING S.L.L pone a su disposición los siguientes medios:</p>
      <ol typeof='1' className={css.listRefund2}>
        <li>Mediante solicitud escrita y firmada dirigida a UUPOCOWORKING S.L.L, Ref. Ejercicio de Derechos RGPD.</li>
        <li>Enviando formulario escaneado y firmado a la dirección de correo electrónico hola@uupo.es indicando en el asunto Ejercicio de Derechos RGPD.</li>
      </ol>
      <p>En ambos casos, deberá acreditar su identidad acompañando fotocopia o en su caso, copia escaneada, de su DNI o documento equivalente para poder verificar que sólo damos respuesta al interesado o su representante legal, debiendo aportar en este caso documento acreditativo de la representación.</p>
      <p>Asimismo, y especialmente si considera que no ha obtenido satisfacción plena en el ejercicio de sus derechos, le informamos que podrá presentar una reclamación ante la autoridad nacional de control dirigiéndose a estos efectos a la Agencia Española de Protección de Datos, C/ Jorge Juan, 6 – 28001 Madrid.</p>
      <p className={css.titleRefund}>
        ¿Cómo protegemos su información?
      </p>
      <p>En UUPOCOWORKING S.L.L nos comprometemos a proteger su información personal.</p>
      <p>Utilizamos medidas, controles y procedimientos de carácter físico, organizativo y tecnológico, razonablemente fiables y efectivos, orientados a preservar la integridad y la seguridad de sus datos y garantizar su privacidad.</p>
      <p>Además, todo el personal con acceso a los datos personales ha sido formado y tiene conocimiento de sus obligaciones con relación a los tratamientos de sus datos personales.</p>
      <p>En el caso de los contratos que suscribimos con nuestros proveedores incluimos cláusulas en las que se les exige mantener el deber de secreto respecto a los datos de carácter personal a los que hayan tenido acceso en virtud del encargo realizado, así como implantar las medidas de seguridad técnicas y organizativas necesarias para garantizar la confidencialidad, integridad, disponibilidad y resiliencia permanentes de los sistemas y servicios de tratamiento de los datos personales.</p>
      <p>Todas estas medidas de seguridad son revisadas de forma periódica para garantizar su adecuación y efectividad.</p>
      <p>Sin embargo, la seguridad absoluta no se puede garantizar y no existe ningún sistema de seguridad que sea impenetrable por lo que, en el caso de cualquier información objeto de tratamiento y bajo nuestro control se viese comprometida como consecuencia de una brecha de seguridad, tomaremos las medidas adecuadas para investigar el incidente, notificarlo a la Autoridad de Control y, en su caso, a aquellos usuarios que se hubieran podido ver afectados para que tomen las medidas adecuadas.</p>
    </div>
  );
};

PrivacyPolicy.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

PrivacyPolicy.propTypes = {
  rootClassName: string,
  className: string,
};

export default PrivacyPolicy;
