import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './TermProtection.module.css';

const TermProtectionEnglish = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  // prettier-ignore
  return (
    <div className={classes}>
      <p>
        <strong>Read these Damage Protection Terms for ÛUPOs carefully, as they contain important information about your rights, remedies, and legal obligations. By posting a Listing or using the UUPO.COM Platform as a ÛUPO, you agree to comply with and be bound by these Damage Protection Terms for ÛUPOs.</strong>
      </p>
      <p>
        <strong>Damage protection for ÛUPOs is a service provided by UUPOCOWORKING SLL for the monitoring, care, and maintenance of the rules and foundations that make this platform possible. Learn about the requirements we present here to contribute to the success of your experience on ÛUPO.COM.</strong>
      </p>

      <ol type="I" className={css.listRefund2}>
        <li>
          <strong>We verify your identity: </strong>
          The safety and privacy of people using ÛUPO.COM are very important to us. Therefore, we are always looking for ways to make our community as safe as possible for everyone.
          <br></br>
          We want everyone using the platform to have peace of mind knowing that ÛUPOs and ÛUPERS on ÛUPO.COM are who they say they are. Therefore, when you book a POP UP or become a ÛUPO, we will verify your personal data such as your full name, ID, address, phone number, and your professional data such as the legal name of your business, location, property registration note, or lease contract, business liability insurance, and a recent utility bill.
          <p>Why do we verify your identity?</p>
          <p>Verifying the identity of ÛUPOs and ÛUPERs allows us to:</p>
          <ul className={css.listRefund}>
            <li>Ensure they are who they say they are.</li>
            <li>Determine if a malicious agent has taken control of an account.</li>
            <li>Investigate security issues such as assaults, thefts, or fraudulent activities.</li>
          </ul>
          <p>Always interact through ÛUPO.COM</p>
          <p>For your safety, we recommend that you always conduct all transactions and communicate with other users through ÛUPO. Additionally, it is advisable to use the platform's messaging system to get to know the ÛUPERs and explain some details about your business.</p>
        </li>
        <li>
          <strong>We recommend:</strong>
          <p>Make sure you have the necessary coverage.</p>
          <p>Check with your insurance provider if it's possible to add additional protection to your business liability insurance for ÛUPERs (coworkers) or discuss it with the landlord.</p>
          <p>Service Protection Program against Damage for ÛUPO</p>
          <p>ÛUseguro is a protection service by UUPOCOWORKING SLL that provides observation, monitoring, and supervision coverage for certain damages and situations that generate civil liability by the ÛUPERs. However, this protection does not replace the business insurance that owners or tenants must contract, as applicable, nor does it constitute comprehensive civil liability coverage. Furthermore, there may be other requirements regarding insurance.</p>
          <p>We strongly recommend all ÛUPOs carefully read the terms of their insurance policy and make sure they understand them well. Not all insurance plans cover the material damages or losses that a ÛUPER might cause in your location.</p>
          <p>Review the business insurance policy you have as an owner or tenant, and consult your agent or insurer to check if you have adequate coverage that includes civil liability and material damages to the property.</p>
          <p className={css.textItalic}>Note that UUPOCOWORKING SLL does not exercise any control over the conduct of the ÛUPOs and disclaims all liability in this regard. If the ÛUPOs do not comply with their obligations, we may suspend their activity or delete their account from the ÛUPO.COM website.</p>
          <p>How to be a responsible ÛUPO:</p>
          <p>The activity of ÛUPOs is very rewarding, but it requires a certain level of commitment.</p>
          <p>We have drafted this article to help ÛUPOs on ÛUPO.COM familiarize themselves with their obligations and to offer them an overview of the various laws, regulations, and best practices that apply to their case. To be part of our community, you must respect our rules, such as the community standards that you can see in "Your safety is our priority," and make sure to comply with local legislation and other specific regulations according to your circumstances and area.</p>
          <p>This article is not exhaustive and should not be considered legal or tax advice, so we recommend you research these matters on your own. Additionally, since we do not update this content in real-time, you will need to consult all sources and ensure that the information has not changed recently.</p>
          <p>National Taxes</p>
          <p>Taxation is a complex issue. Your tax obligations may vary depending on your particular circumstances, so we recommend you get informed about it or consult a tax advisor for more specific information.</p>
          <p>The activity to be developed by the ÛUPO is coworking, based on the criterion that not only a physical space is being rented but all the necessary ancillary services in a location for the development of an activity by the ÛUPER.</p>
          <p>We recommend registering in the corresponding headings to develop and market a coworking service on ÛUPO.COM</p>
          <p>Keep in mind that there would not be a specific IAE heading for coworking services but several depending on the ancillary services provided.</p>
          <ul className={css.listRefund}>
            <li>Heading 861.2 "Rental of industrial premises and other rentals n.e.c." for the rental of physical space.</li>
            <li>Heading 856.1 "Rental of consumer goods" for furniture rental.</li>
            <li>Group 999 "Other services n.e.c." for providing various services (telephone service, internet, security, cleaning)</li>
          </ul>
          <p>Generally, the money you earn as a ÛUPO on ÛUPO.COM is considered income and as such will be subject to taxation according to the applicable regulations depending on the legal form and country of residence of the ÛUPO.</p>
          <p>You can check the amounts you have earned through ÛUPO.COM in your income summary.</p>
          <p><strong>During the celebration of the POP UP, you must issue an invoice to the ÛUPER stating the coworking service for the corresponding days, extra charges, and VAT.</strong></p>
          <p>Regulations and permits</p>
          <p>It is important to ensure that you can cowork in your property. Sometimes there are restrictions in the contracts that prevent you from receiving ÛUPERs. We recommend seeking legal advice or consulting your lease agreements to obtain more information about the regulations, restrictions, and obligations that apply in your case.</p>
          <p>Contracts and permits</p>
          <p>Sometimes, lease agreements establish restrictions when it comes to subletting a location. Therefore, it is important to carefully read any contract you have signed or contact the owner to clarify the new commercialization of a new coworking service within your establishment.</p>
          <p>It may be possible to add an annex to your lease agreement to clarify any possible doubts, as well as the responsibilities and obligations of all parties.</p>
          <p>Mortgage Restrictions</p>
          <p>If your property has a mortgage (or is subject to any other form of loan), contact the lender to ensure there are no restrictions that prevent coworking.</p>
          <p>Shared Facility or Mall</p>
          <p>If you share a commercial facility with other businesses, help them understand the peculiarities of celebrating POP Ups in your establishment. You can even sign an agreement to clarify the rights and obligations of each party. Shared facility or mall agreements can include the frequency with which you plan to cowork, how you will ensure they comply with the rules, among other issues.</p>
          <p>How to provide information about your POP UP and its price</p>
          <p>Describe it accurately and in detail. Do not omit information that might be of interest to the ÛUPER.</p>
          <p className={css.textItalic}><strong>Note: </strong>This page is for informational purposes only. The information we offer should not be used to seek legal advice. If you have any questions about how the regulations we mention below affect you, we recommend consulting a lawyer or an expert in the field.</p>
          <p>Safety</p>
          <p>At ÛUPO, the safety of ÛUPOs and ÛUPERs is one of our top priorities. To contribute to the peace of mind of the ÛUPERs, you can provide them with useful information for emergencies or warn them of any possible risk. It is a very simple gesture that means a lot to them!</p>
          <p>Emergency contact information</p>
          <p>We recommend that you have a list of the following phone numbers in your location:</p>
          <ul className={css.listRefund}>
            <li>Local emergency services numbers.</li>
            <li>The number of the nearest hospital.</li>
            <li>Your phone number.</li>
            <li>The number of an emergency contact person (in case the ÛUPERs cannot reach you).</li>
          </ul>
          <p>It is also important for ÛUPERs to know the best way to contact you if something happens. Remember that communicating with them through the ÛUPO messaging system is a safe alternative.</p>
          <p>First Aid Kit</p>
          <p>Prepare a first aid kit for your location and indicate to the ÛUPERs where it is. Additionally, regularly check that it has everything needed and replenish what has been used.</p>
          <p>Fire Prevention</p>
          <p>If you have appliances or machinery that run on gas, make sure they comply with applicable safety regulations and install a carbon monoxide detector. We also recommend having a fire extinguisher visible in the location and periodically checking it.</p>
          <p>Emergency Exits</p>
          <p>Ensure emergency evacuation routes are clearly marked. Leave a map with the route in an easily accessible place for ÛUPERs.</p>
          <p>Risk Prevention</p>
          <p>Here are some tips that can help you avoid risks:</p>
          <ul className={css.listRefund}>
            <li>Walk through your location and assess where ÛUPERs might trip or fall.</li>
            <li>Remove elements that may pose a danger or ensure they are well signposted.</li>
            <li>Remove all exposed cables.</li>
            <li>Ensure stairs are stable and have handrails.</li>
            <li>Remove or lock away objects that may be dangerous for ÛUPERs.</li>
          </ul>
          <p>Air Conditioning</p>
          <p>It is essential that appliances such as heaters or air conditioners work well for ÛUPERs to have a comfortable experience. Additionally, there are other ways to ensure they do not get too hot or cold:</p>
          <ul className={css.listRefund}>
            <li>Ensure your location is well ventilated.</li>
            <li>Indicate how to safely use heating and air conditioning.</li>
            <li>Check the thermostat works correctly and make sure ÛUPERs know where it is.</li>
            <li>Regularly maintain appliances.</li>
          </ul>
          <p>Capacity</p>
          <p>Set and inform the ÛUPER of the maximum number of customers who can enter your location safely. Your local administration may have rules in this regard.</p>
          <p>Civility</p>
          <p>Being a responsible ÛUPO involves, in part, helping your ÛUPERs understand how best to interact with the community. When you communicate local norms and customs to them, you contribute to creating a more pleasant experience for everyone.</p>
          <p>Building Rules</p>
          <p>If the building where your location is has common areas or shared services, explain the rules to the ÛUPERs.</p>
          <p>Your Business Rules</p>
          <p>You can include your business rules as an additional note in the Description section of your ÛUPO account on ÛUPO.COM. ÛUPERs usually appreciate being told from the start how you expect them to behave in your location.</p>
          <p>Neighbors</p>
          <p>It is advisable to inform your neighbors that you are planning to celebrate a POP UP. This way they will have the opportunity to raise any concerns and provide you with information you should take into account.</p>
          <p>Noise</p>
          <p>ÛUPERs reserve POP UPs through ÛUPO.COM for many reasons, such as promoting or strengthening their brand or drawing attention to an idea. To ensure they and the residents of your community enjoy a pleasant experience, remind them that noise can disturb your neighbors.</p>
          <p>If you are concerned that ÛUPERs may disturb the neighbors, you can implement several strategies to prevent excessive noise:</p>
          <ul className={css.listRefund}>
            <li>Establish quiet hours.</li>
            <li>Do not allow ÛUPERs to bring pets.</li>
            <li>Indicate that your location is not suitable for children or babies.</li>
            <li>Prohibit parties.</li>
          </ul>
          <p>Parking</p>
          <p>Inform ÛUPERs about the parking rules in your neighborhood and area. Here are some examples:</p>
          <ul className={css.listRefund}>
            <li>You can only park in a specific spot.</li>
            <li>On Tuesdays and Thursdays, you cannot park on the opposite sidewalk of this street because cleaning services are carried out.</li>
            <li>You can only park on the street between 7:00 PM and 7:00 AM.</li>
          </ul>
          <p>Pets</p>
          <p>If you allow ÛUPERs to bring their pets, they will appreciate being told the best places to walk them and where to dispose of the waste they generate. It is also a good idea to provide some emergency alternatives, such as the number of a nearby pet hotel, in case the animal becomes a nuisance.</p>
          <p>Privacy</p>
          <p>Always respect the privacy of your ÛUPER. Our rules on surveillance devices (1) clearly outline the conduct we expect from ÛUPOs. However, some areas have additional laws and regulations that you should be aware of.</p>
          <p><strong>(1) Use of cameras and recording devices: </strong></p>
          <p>To ensure the peace of mind of ÛUPOs and ÛUPERs, security measures such as security cameras and noise monitoring devices are allowed, provided they are clearly indicated in the listing description and do not violate anyone's privacy. The device rules apply to all cameras, recording devices, smart mechanisms, and surveillance systems.</p>
          <p>What do we allow?</p>
          <p>Declared devices that are only used to monitor public areas and common areas: devices that serve to monitor or oversee a public area (e.g., the front door or a vehicle entrance) or a common area are allowed, provided they are clearly identified and ÛUPERs have been informed of their existence before booking. Common areas do not include private rooms or bathrooms.</p>
          <p>The installation of such devices entails the implementation of appropriate legal measures to comply with the European General Data Protection Regulation 2016/679.</p>
          <p>What do we not allow?</p>
          <ul className={css.listRefund}>
            <li>Hidden or undeclared devices that monitor common areas: any device that monitors a common area must be installed visibly and appear in the listing description.</li>
            <li>Devices located in or monitoring private areas: devices should never monitor private areas (e.g., restrooms, bathrooms, or common areas used for sleeping, such as a living room with a sofa bed). There may be disconnected devices as long as they are turned off and ÛUPERs are aware of their existence.</li>
          </ul>
          <p>Reservation cancellations</p>
          <p>Send an email to: <a href="mailto:hola@uupo.es" className={css.enlaceEmail}>hola@uupo.es </a> with all your reservation details to process a cancellation. We will contact you as soon as possible to expedite the process.</p>
          <p>Although cancellations by ÛUPOs are very rare and some are due to reasons beyond their control, they can be a great inconvenience for ÛUPERs and undermine their trust in our community.</p>
          <p>Reservation modifications</p>
          <p>Send an email to: <a href="mailto:hola@uupo.es" className={css.enlaceEmail}>hola@uupo.es </a> with all your reservation details to process a modification. We will contact you as soon as possible to expedite the process.</p>
          <p>ÛUPO and ÛUPER are responsible for any reservation modifications they agree to make through the ÛUPO.COM Platform or that they instruct the UUPOCOWORKING SLL Customer Service to make on their behalf (hereinafter, "Reservation Modifications"), and agree to pay any additional amount, fee, or tax associated with any Reservation Modification.</p>
          <p>Listings</p>
          <p>If you are a ÛUPO and, when creating a new listing, we ask you to verify your identity, you will have 48 hours to complete the process. This period includes the time it takes to send us the information we request and for us to verify your identity.</p>
          <p>Once we have confirmed your identity, you can start accepting reservations.</p>
          <p>How we handle your data</p>
          <p>Only authorized ÛUPO.COM employees will have access to the information you send us. All data is securely stored and transmitted. We will only keep your personal data for as long as necessary to comply with applicable regulations and other legal requirements, such as:</p>
          <ul className={css.listRefund}>
            <li>Security and anti-fraud requirements</li>
            <li>ÛUPO.COM community standards</li>
            <li>Anti-money laundering regulations</li>
            <li>Tax and payment laws</li>
          </ul>
          <p>In accordance with applicable law, we will delete personal data when the data subject requests it.</p>
        </li>
        <li>
          <strong>We inform you:</strong>
          <p>ÛUPOs can benefit from the ÛUPO Damage Protection program, which is subject to the exclusions, limitations, and other terms and conditions set forth below and as periodically amended. These ÛUPO Damage Protection Terms apply in conjunction with the UUPOCOWORKING SLL Terms and Conditions and the UUPOPAYMENTS of UUPOCOWORKING SLL and constitute the rules and services that make ÛUPO.COM possible.</p>
          <p>If you acknowledge and accept these ÛUPO Damage Protection Terms on behalf of a company or other legal entity, you represent and warrant that you have the authority to bind that company or other legal entity to these ÛUPO Damage Protection Terms and, in such case, <strong>"you"</strong> and <strong>"your"</strong> will refer to and apply to that company or other legal entity.</p>
          <p>Except to the extent permitted by law, these ÛUPO Damage Protection Terms will not affect your statutory rights.</p>
          <p>ÛUPO Damage Protection is not an insurance policy. To the extent that you desire protection beyond ÛUPO Damage Protection, UUPOCOWORKING SLL strongly recommends that you obtain insurance that covers you and your property for losses caused by ÛUPERs or ÛUPER Guests for the event that your loss is not covered by the ÛUPO Damage Protection Terms.</p>
          <ol type="A" className={css.listRefund2}>
            <li>
              <strong>ÛUPO Damage Protection: </strong>
              You acknowledge and agree to do everything within your power to contact the Responsible ÛUPER (as defined below) as soon as possible after discovering any loss or material damage to your Covered Property. You must notify your claim to UUPOCOWORKING SLL and the Responsible ÛUPER and try to reach an agreement with the Responsible ÛUPER to remedy the loss or damage within fourteen (14) days after the Responsible ÛUPER's departure date. You can fulfill this obligation by submitting a claim through <a href="mailto:hola@uupo.es" className={css.enlaceEmail}>hola@uupo.es</a>.
              <br></br>
              You acknowledge and agree that the amount of any Covered Losses you are entitled to receive from the ÛUPER under the ÛUPO Damage Protection will be reduced by the amounts you have already received for the same Covered Losses from a source other than the ÛUPO Damage Protection, including but not limited to: (i) amounts received under an insurance policy, guarantee, or indemnity; (ii) a security deposit; or (iii) any payment made directly by the Responsible ÛUPER or a Guest (defined below) or another third party or their insurer or guarantor.
            </li>
            <li><strong>Definition of Key Terms</strong>
              The following terms, when capitalized, shall have the meaning ascribed to them below:
              <br></br>
              <strong>"Actual Cash Value":</strong> means the amount it would cost to repair or replace the Covered Property that has been damaged or destroyed as a result of a Covered Loss, calculated on the date the Covered Loss occurs, with property or materials of the same kind and quality, and with the appropriate deduction for obsolescence and depreciation of its physical value.
              <br></br>
              <strong>"ÛUPO Covered":</strong> means a Commercial Establishment located in the Territory that offers coworking services in the form of a POP UP and which you (i) legally own or control as ÛUPO during the stay period of the Responsible ÛUPER in said ÛUPO, and (ii) is listed by you on the ÛUPO.com Platform and has been booked by the Responsible ÛUPER in accordance with the Terms and Conditions.
              <br></br>
              <strong>"Covered Losses":</strong> means and is limited to:
              <br></br>
              (i) Physical loss or direct material damage to the Covered Property of a ÛUPO caused by the Responsible ÛUPER or a Guest during the days reserved for the celebration of a POP UP on ÛUPO.com.
              <br></br>
              (ii) Physical loss or material damage to the Covered Property of a ÛUPO caused by a pet that is owned or under the responsibility of the Responsible ÛUPER or a Guest during a stay on ÛUPO.com.
              <br></br>
              (iii) Reasonable, customary, and unexpected additional cleaning costs to clean the Covered Property of a ÛUPO incurred as a direct result of the presence of a pet that is owned or under the responsibility of the Responsible ÛUPER or a Guest during the celebration of a POP UP on ÛUPO.com.
              <br></br>
              (iv) Reasonable, customary, and unexpected additional cleaning costs to clean the Covered Property of a ÛUPO as a direct result of the acts of a Responsible ÛUPER or a Guest during a stay on ÛUPO.com. Only cleaning costs that exceed those paid to a ÛUPO or charged in connection with said reservation will be paid.
              <br></br>
              Covered Losses do not include losses or damages described later in the description of Excluded Losses.
              <br></br>
              <strong>"Covered Property":</strong>
              refers exclusively to the following properties located in a Covered ÛUPO or within a radius of 300 meters around it, to the extent you have an interest in such properties, unless such property constitutes an Excluded Property (as defined below).
              <br></br>
              <strong>"Excluded Losses":</strong>
              will have the meaning ascribed to it in Section III below.
              <br></br>
              <strong>"Excluded Property":</strong>
              means any of the following properties:
              <ol type="1" className={css.listRefund2}>
                <li>Currency, money, precious metals in bullion form, banknotes, or securities.</li>
                <li>Land, water, or any other substance found within or on the ground, although this exclusion does not apply to (i) land improvements such as landscaped areas, sidewalks, and paved surfaces, excluding the matter underneath such property, or (ii) water contained within any type of closed reservoir, piping system, or other processing equipment.</li>
                <li>Animals, including livestock and pets.</li>
                <li>Standing trees and planted crops.</li>
                <li>Boats (including, but not limited to, boats, yachts, jet skis, and similar watercraft), airplanes, spacecraft, and satellites. This exclusion of boats does not apply to any boat or airplane that constitutes a Covered Pop Up unless, at the time of the loss, it is in transit or has been moved more than 3 meters from its usual fixed location and is moving at more than 1.6 kilometers per hour.</li>
                <li>Vehicles (including, but not limited to, automobiles, scooters, vespas, and motorcycles). This exclusion does not apply to any vehicle that is a Covered ÛUPO. However, this exclusion does apply to vehicles that, at the time of the loss, are in transit or have been moved more than 3 meters from their usual fixed location and are moving at more than 1.6 kilometers per hour.</li>
                <li>Underground mines, mine shafts, or any property within such mine or shaft.</li>
                <li>Dams and levees.</li>
                <li>Property in transit, unless otherwise stated in these ÛUPO Damage Protection Terms.</li>
                <li>Transmission and distribution lines extending more than 300 meters from the Covered ÛUPO.</li>
                <li>Any damage to property not located in a Covered ÛUPO.</li>
                <li>Real estate owned by someone other than you and not under your responsibility.</li>
                <li>Weapons, including, but not limited to, firearms, airguns, deterrent or self-defense devices such as tasers or pepper spray, ammunition of any kind, and imitation firearms, except if such weapons are stored and protected, and their presence is communicated in accordance with the UUPOCOWORKING SLL Standards and Expectations or other rules, as amended from time to time.</li>
                <li>Security cameras and other recording devices, including, but not limited to, wifi cameras (e.g., Nest Cam or Dropcam), surveillance cameras, webcams installed on monitors, baby monitors, any type of surveillance system, decibel monitors, and devices or smartphones with video and audio recording capabilities, unless such devices comply with the UUPOCOWORKING SLL Standards and Expectations or other rules, as amended from time to time.</li>
              </ol>
              <strong>"Works of Art":</strong>
              refers to paintings; prints; printed photographs; paintings; tapestries; glass art or rare glass; stained glass; valuable carpets; statues; sculptures; antique furniture; antique jewelry; small art objects of little value; porcelain and similar items of great rarity, historical value, or artistic merit. "Works of Art" do not include automobiles, coins, stamps, other collectibles, collections, furs, jewelry, precious stones, precious metals, boats, airplanes, money, or securities.
              <br></br>
              <strong>"Guest":</strong>
              refers to a person whom a Responsible ÛUPER has invited to attend the Covered ÛUPO.
              <br></br>
              <strong>"Normal Wear and Tear":</strong>
              means deterioration of the condition of the property that occurs under normal use.
              <br></br>
              <strong>"Responsible ÛUPER":</strong>
              means the ÛUPER who booked the Covered ÛUPO for the period during which the Covered Losses occurred.
              <br></br>
              <strong>"Territory":</strong>
              refers to the countries where UUPOCOWORKING SLL allows the ÛUPO and the ÛUPO Damage Protection to be available. Any Territory where the ÛUPO Damage Protection is not available will be indicated on the ÛUPO Damage Protection landing page.
            </li>
            <li>
              <strong>Limitations and Exclusions:</strong>
              ÛUPO Damage Protection only covers Covered Losses and does not cover any of the following ("Excluded Losses"):
              <ol type="1" className={css.listRefund2}>
                <li>any loss caused by a ÛUPER or Guest after the reservation duration displayed in the relevant Listing.</li>
                <li>in the case of Works of Art, losses or damages suffered by Works of Art that cannot be replaced with items of similar characteristics and quality, as well as losses or damages that may arise from any repair, restoration, or retouching process.</li>
                <li>
                  any loss, damage, cost, or expense of any nature, caused by, related to, or resulting from, directly or indirectly, any of the following:
                  <ol type="1" className={css.listRefund2}>
                    <li>Excluded Property</li>
                    <li>Natural phenomena, including, without limitation, earthquakes and weather events, such as hurricanes and tornadoes</li>
                    <li>Excessive consumption of electricity, gas, fuel, or water, or other supplies provided at the Covered ÛUPO</li>
                    <li>Indirect or remote causes</li>
                    <li>Business interruption, market loss, and/or loss of use, except that ÛUPO Damage Protection does cover Reservation Income Loss</li>
                    <li>Loss, damage, or deterioration resulting from any delay</li>
                    <li>Disappearance, mysterious loss, or unexplained inventory shortage discovered during an inventory taking or any unexplained inventory difference</li>
                    <li>Application of any law or regulation (i) governing the construction, repair, replacement, use, or removal of any property, including debris removal, or (ii) requiring the demolition of any property, including the cost of debris removal</li>
                    <li>Animals, including injuries caused to animals, veterinary care, accommodation, medication, and other animal-related services, except for damage caused by pets as defined in subsections (ii) and (iii) of the definition of Covered Losses; or</li>
                    <li>Identity theft or fraudulent use of identity.</li>
                  </ol>
                </li>
                <li>Any loss, damage, cost, or expense of any nature caused, directly or indirectly, by any of the following events, regardless of whether any other cause or circumstance contributed to it:
                  <ol type="1" className={css.listRefund2}>
                    <li>Any hostile act or act of war, terrorism, insurrection, or rebellion</li>
                    <li>Use or threat of malicious use of toxic chemical or biological substances</li>
                    <li>Nuclear reaction, radiation, or radioactive contamination</li>
                    <li>Seizure or destruction in accordance with quarantine or customs regulations, or confiscation by order of any public or administrative authority</li>
                    <li>Smuggling or illegal transportation or trade</li>
                    <li>Any dishonest act, including, without limitation, any theft committed by you or anyone or entity hired by you to do anything in relation to the Covered Pop Up, unless such persons or entities are a Responsible ÛUPER or a Guest and the act in question is carried out without your knowledge; or</li>
                    <li>Power, fuel, water, gas, steam, refrigerant, sewage, telephone, or internet service failure due to external factors</li>
                  </ol>
                </li>
                <li>The following conditions:
                  <ol type="1" className={css.listRefund2}>
                    <li>Manufacturing defects, material, construction, or design defects of any cause</li>
                    <li>Deterioration, shrinkage, oxidation, corrosion, or erosion, intrinsic defects, or hidden defects</li>
                    <li>Normal Wear and Tear</li>
                    <li>Settlement, cracking, contraction, bulging, or expansion of foundations, floors, pavements, walls, ceilings, or roofs</li>
                    <li>Temperature or relative humidity changes; or</li>
                    <li>damage caused by insects, animals, or pests except for damage caused by pets as described in subsections (ii) and (iii) of the definition of Covered Losses; any loss, damage, claim, cost, expense, or other amounts directly or indirectly related to the existence of mold, fungi, spores, viruses, bacteria, or other microorganisms of any kind, class, or nature, including, without limitation, any substance whose presence poses a real or potential threat to human health. This exclusion will apply even if there is (i) any physical loss or material damage to the Covered Property; (ii) any danger or cause covered by this Protection, whether or not contributing simultaneously or in any sequence; (iii) any loss of use, occupation, or functionality; or (iv) the need to take any action, including, without limitation, repair, replacement, removal, cleaning, reduction, disposal, relocation, or taking appropriate measures to address any health incident or legal issue. This exclusion does not apply to reasonable, customary, and unexpected additional cleaning costs as described in subsections (iii) and (iv) of the definition of Covered Losses.</li>
                  </ol>
                </li>
                <li>Costs arising from the loss, loss of use, damage, corruption, inability to access, or inability to manipulate any and all Electronic Data. "Electronic Data" means information, data, or programs, stored as or in, created or used on, or transmitted to or from any Electronic Medium. "Electronic Medium" means computer programs, including computer systems and applications, hard drives or diskettes, CD-ROMs, tapes, drives, cells, data processing devices, or any other medium used with electronically controlled equipment.</li>
                <li>Any loss or damage that cannot be recovered from the Responsible ÛUPER and/or Guest under the UUPOCOWORKING Terms and Conditions.</li>
              </ol>
            </li>
            <li>
              <strong>Conditions of ÛUPO Damage Protection:</strong>
              To receive payments under these ÛUPO Damage Protection Terms, you must comply with each of the following conditions. If you do not comply with them, you will not be entitled to recover any Covered Loss. In all cases, it will be your responsibility to prove that you have met the following conditions.
              <br></br>
              You must have incurred Covered Losses.
              <br></br>
              You must inspect the corresponding Covered ÛUPO to determine if there is any physical loss or damage to any Covered Property, and you must notify your claim to UUPOCOWORKING SLL and the Responsible ÛUPER and try to resolve the loss or damage with the Responsible ÛUPER within fourteen (14) days from the Responsible ÛUPER's departure date. You can fulfill this obligation by submitting a claim through the ÛUPO.com Resolution Center.
              <br></br>
              You must not have misrepresented any fact or committed fraud or any other dishonest or deceptive act regarding the booking of the Covered Property or the preparation or submission of any past or present payment request under the ÛUPO Damage Protection Terms. Any misrepresentation, fraud, dishonest or deceptive act on your part, at any time, will result in the denial of all pending payment requests under the ÛUPO Damage Protection Terms and the immediate termination of these ÛUPO Damage Protection Terms to the extent they relate to you, without prejudice to Section VII below.
              <br></br>
              Whenever a Covered Property is damaged or destroyed due to a breach of law or theft, or a criminal act or crime, and you submit a ÛUPO Damage Protection Payment Request Form, you must file a police report or complaint listing such Covered Property and provide a copy of such report or complaint to UUPOCOWORKING SLL, and you must certify that such copy is a true and accurate copy.
              <br></br>
              You must provide UUPOCOWORKING SLL with proof of ownership or legal responsibility for the Covered Property, in the form of receipts, photographs, videos, documents, or other customary forms of proof (including, but not limited to, appraisal or valuation forms or notices addressed to you) certified by you as true and correct and reasonably acceptable to UUPOCOWORKING SLL.
              <br></br>
              Within thirty (30) days of incurring a Covered Loss, you must (i) complete and submit a ÛUPO Damage Protection Payment Request Form and (ii) provide us with signed proof and sworn statement of loss, unless we grant you an extension of such period in writing. The proof of loss must express your knowledge and understanding regarding the following:
              <ul className={css.listRefund}>
                <li>The time, cause, and origin of the Covered Loss, as well as evidence of such loss through receipts, photographs, videos, documents, and other verifiable forms of proof.</li>
                <li>The ownership, lease rights, or other interest you and other parties may have in the Covered Property for which the Covered Loss is claimed.</li>
                <li>The Actual Cash Value and replacement value of each item of Covered Property, as well as the amount of such loss or damage for each item of Covered Property.</li>
                <li>All liens, encumbrances, mortgages, guarantees, and other insurance contracts, whether in effect or not, covering the Covered Property subject to the Covered Loss.</li>
                <li>Any change in title, use, occupation, location, possession, or risks of the Covered Establishment since the Listing date.</li>
                <li>The identity and other known information about the Responsible ÛUPER, any Guest, and other parties present in or using the Covered ÛUPO where the Covered Property subject to the Covered Loss is located on the date the Covered Loss occurred, and the purpose for which said Covered ÛUPO was being used by the parties on such date and whether it was located on leased land at that time.</li>
                <li>The date you contacted the Responsible ÛUPER to request payment for the loss you are claiming, and the date the Responsible ÛUPER refused to make the payment or did not make such payment.</li>
              </ul>
              Along with the signed and sworn proof of loss described above, you must provide UUPOCOWORKING SLL with all information that UUPOCOWORKING reasonably requests to determine the Actual Cash Value of the Covered Property, including: the original purchase price of the Covered Property, the acquisition date of the Covered Property, the condition of the Covered Property, and the estimated cost of repair or replacement of the Covered Property.
              <br></br>
              You must (i) protect and preserve the damaged Covered Property from further loss or damage; and (ii) promptly separate the damaged Covered Property from the intact property; place it in the best possible order, and provide a complete inventory of the lost, destroyed, damaged, and intact property showing in detail the quantities, costs, Actual Cash Value, and the amount of the claimed loss.
              <br></br>
              You must, as frequently as may be reasonably required by UUPOCOWORKING SLL or its delegates, (i) display all remaining damaged Covered Property and sign the written inspection documents; (ii) present for examination all account books, business documents, receipts, invoices, and vouchers (whether originals or certified copies if the originals have been lost); and (iii) allow extracts and mechanical copies to be made of the aforementioned documents.
              <br></br>
              You must allow UUPOCOWORKING SLL or its delegate(s) to inspect the Covered Property at any reasonable time. However, the right to conduct inspections, the performance of inspections, and any analysis, advice, or inspection report will not constitute any warranty by UUPOCOWORKING SLL or UUPOCOWORKING SLL's insurer that the damaged Covered Property is safe or in good condition. We will have no liability to you or any other person for any inspection or lack of inspection.
              <br></br>
              You must cooperate with UUPOCOWORKING SLL, and such cooperation will include signing any documents and promptly responding to any reasonable requests for additional information or documentation that UUPOCOWORKING SLL or its delegates may require or request to process the corresponding ÛUPO Damage Protection Payment Request Form.
            </li>
            <li>
              <strong>Processing ÛUPO Payment Requests</strong>
              <br></br>
              UUPOCOWORKING SLL will complete the processing of any ÛUPO Damage Protection Payment Request Form you submit within a reasonable period from the date you have (a) completed and submitted a ÛUPO Damage Protection Payment Request Form, and (b) provided UUPOCOWORKING SLL with all the information and documentation you are required to provide to comply with the conditions set forth in the "ÛUPO Damage Protection Conditions" section above. In any case, we will make commercially reasonable efforts to process your ÛUPO Damage Protection Payment Request Form within three (3) months from the time we receive such documents and information. If you receive an Approved Payment Request (as defined below), as a condition for you to receive payment under the ÛUPO Damage Protection, you must sign and deliver to UUPOCOWORKING SLL the "Approved ÛUPO Damage Protection Payment Request Agreement," in which you agree to the following:
              <ol type="1" className={css.listRefund2}>
                <li>Assign to UUPOCOWORKING SLL or your insurer any right or action to repair damages to which you are entitled to recover the amounts you have been paid in relation to an Approved Payment Request (as defined below) by the Responsible ÛUPER or a Guest or any other third party financially responsible for the Covered Losses subject to the Approved Payment Request</li>
                <li>Reasonably cooperate with us, including, at our request, appearing as a witness in any court, arbitration, or similar proceeding if we intend to recover the amount you have been paid in connection with the Approved Payment Request by the Responsible ÛUPER or a Guest or any other third party</li>
                <li>Release and discharge UUPOCOWORKING SLL, your insurer, and all UUPOCOWORKING SLL's officers, directors, employees, contractors, and agents from any further liability or obligation concerning the facts and circumstances of the matters and incident recorded in the ÛUPO Damage Protection Payment Request Form</li>
                <li>If requested, treat as "confidential information" the amount of any payment made under the ÛUPO Damage Protection; and</li>
                <li>Refund us any amount that exceeds the Covered Loss in the Approved Payment Request due to a system or payment processing error.</li>
              </ol>
              The time it takes to process any ÛUPO Damage Protection Payment Request Form you submit will depend, among others, on the following factors: (i) the amount of the payment you request for the Covered Loss; (ii) the location of the Covered ÛUPO; (iii) the nature of the Covered Property and the nature of the Covered Losses; (iv) the completeness and type of information and documentation you provide to UUPOCOWORKING SLL regarding the Covered Losses; and (v) the number of ÛUPO Damage Protection Payment Request Forms currently being processed for other ÛUPOs.
              <br></br>
              <strong>Approved Payment Request</strong>
              <br></br>
              If you have submitted a ÛUPO Damage Protection Payment Request Form and such payment request is approved in whole or in part for a Covered Loss (such approved payment request being defined as an "Approved Payment Request"), you will receive the amount corresponding to the Covered Loss as calculated by UUPOCOWORKING SLL or its delegates. The process of calculating such Covered Losses in the section "Determining the Amount of Covered Loss" is described below. You will receive a notification from ÛUPOCOWORKING SLL and, as a condition of payment, you must then deliver to UUPOCOWORKING SLL a completed and signed Approved ÛUPO Damage Protection Payment Request Agreement. UUPOCOWORKING SLL may use third-party service providers to assist with processing ÛUPO Damage Protection Payment Request Forms, and UUPOCOWORKING SLL or its delegates may use third-party service providers to assist in investigating and adjusting payment requests related to the same.
              <br></br>
              For an Approved Payment Request that includes Covered Losses for Covered Property owned by a third party, we reserve the right, at our sole discretion, to pay all or part of the covered amount in such Approved Payment Request either to you or directly to the owner of such Covered Property. If payment under ÛUPO Damage Protection of part or all of the amount is made directly to the owner of such Covered Property, you agree that such payment will be treated for all purposes related to the Approved Payment Request as if it had been paid directly to you, and you also agree to be solely responsible for collecting from the owner of such Covered Property the portion of such payment to which you believe you are legally entitled. To avoid doubt, your indemnification obligations set forth below in the paragraph titled "Indemnification" will apply to claims arising from any payment made under the ÛUPO Damage Protection, including, without limitation, any payment made directly to the owner of any Covered Property.
              <br></br>
              <strong>Determining the Amount of Covered Loss</strong>
              <br></br>
              The amount of Covered Losses will be calculated as of the date of the loss, at the location of the loss, and will not exceed the interest you have in them, under the following conditions:
              <ol type="1" className={css.listRefund2}>
                <li>In the case of exposed films, recordings, manuscripts, drawings, and Electronic Media, the blank value plus the cost of copying the information from backups or originals of a previous generation. Research, engineering, or restoration or recreation costs of lost information or Electronic Data will not be paid.</li>
                <li>For Works of Art, the lesser of the following amounts (i) the reasonable and necessary cost to repair or restore such property to the physical state it was in on the date of the loss; (ii) the cost of replacing the item; and (iii) the current appraisal value. If the Work of Art is part of a pair or set, you will not receive any payment (1) for the cost of replacing any undamaged or remaining items that are part of such pair or set, (2) for an amount greater than the portion the lost or damaged item represents with respect to the insured value of the pair or set, or (3) for the cost of replacing or repairing any undamaged part of the Works of Art that are part of a pair, set, or game or are part of a common design or function when the loss or damage is limited to a clearly identifiable area or specific part.</li>
                <li>For all Covered Property (except that described in paragraphs 1 and 2 above), the amount of loss will be the lesser of the following amounts (i) the Actual Cash Value; (ii) the cost of repairing such damaged Covered Property; (iii) the cost of rebuilding or replacing such damaged Covered Property in the same location with new materials of similar size, type, and quality; (iv) the cost of rebuilding, repairing, or replacing in the same or another location, but without exceeding the size and operational capacity that existed on the date of the Covered Loss; or (v) the cost of replacing irreparable mechanical or electrical equipment, including computer equipment and Electronic Media, with equipment that is functionally similar to the damaged or destroyed equipment, even if such equipment has technological advantages and/or represents an improvement in its function and/or is part of a system improvement program.</li>
                <li>Any amount of any Covered Loss payable under ÛUPO Damage Protection will be reduced by the amount already paid to you or for your benefit by a Responsible ÛUPER, Guest, or another source (such as an insurer, guarantor, or other responsible party) for the same Covered Loss.</li>
                <li>Covered Losses will be paid in Euros unless, at the sole discretion of UUPOCOWORKING SLL, UUPOCOWORKING SLL chooses to pay losses in a different currency. If currency conversions are required, we will use a general exchange rate, known as the base exchange rate, for currency conversion using data from one or more third parties, such as OANDA (www.oanda.com).</li>
                <li>Any amount of any Covered Loss payable under ÛUPO Damage Protection will not exceed the amount that you, as a ÛUPO, could otherwise recover from the Guest or Responsible ÛUPER.</li>
              </ol>
              <strong>ÛUPO Damage Protection is not an insurance policy. To the extent that you desire protection beyond ÛUPO Damage Protection, UUPOCOWORKING SLL strongly recommends that you obtain insurance that covers you and your property for losses caused by ÛUPERs or ÛUPER Guests for the event that your loss is not covered by the ÛUPO Damage Protection Terms.</strong>
            </li>
            <li>
              <strong>Acknowledgments and agreements by the ÛUPO:</strong>
              You acknowledge and agree that:
              <ul className={css.listRefund}>
                <li>ÛUPO Damage Protection is a guarantee of the Responsible ÛUPER's primary obligation to pay you, as a ÛUPO, to repair or replace your Covered Property damaged or destroyed as a result of a Covered Loss, and is subject to such obligation.</li>
                <li>ÛUPO Damage Protection is subject to your exercising the rights and remedies you have against the Responsible ÛUPER, or against any other party that is financially responsible for the Covered Loss.</li>
                <li>Any amount that UUPOCOWORKING SLL pays to you under these ÛUPO Damage Protection Terms will not exceed the amount that you, as a ÛUPO, are entitled to recover from the Responsible ÛUPER.</li>
                <li>UUPOCOWORKING SLL offers ÛUPOs the benefits of the ÛUPO Damage Protection service described herein solely for the purpose of promoting the use of the ÛUPO.com Platform by retaining customers and enhancing customer confidence in using the ÛUPO.com Platform.</li>
                <li>These ÛUPO Damage Protection Terms are not intended to constitute an offer to insure, do not constitute insurance or an insurance contract, nor do they replace any insurance you may have or may obtain. Additionally, these ÛUPO Damage Protection Terms do not constitute a service contract of insurance as defined in an insurance policy according to the ISO clauses or according to the homeowner's or renter's policy.</li>
                <li>The benefits provided under these ÛUPO Damage Protection Terms are solely as set forth in the preceding paragraph titled "ÛUPO Damage Protection" and cannot be assigned or transferred, including, without limitation, any transfer or assignment by operation of law or in connection with your divorce or death.</li>
                <li>UUPOCOWORKING SLL and/or its insurer reserve the right to independently investigate (or hire an independent investigator), at their sole discretion and expense, the facts and circumstances of a payment request set forth in any ÛUPO Damage Protection Payment Request Form you submit to UUPOCOWORKING SLL, regardless of whether you have submitted all the information and documentation you are required to provide to UUPOCOWORKING SLL to comply with the conditions set forth in Section IV above titled "ÛUPO Damage Protection Conditions."</li>
              </ul>
              You acknowledge and agree that if you submit a claim under ÛUPO Damage Protection, you consent to UUPOCOWORKING SLL reading all communications between you and the allegedly Responsible ÛUPER through the ÛUPO.com Platform.
              <br></br>
              UUPOCOWORKING SLL reserves the right, at any time, to offset or deduct from amounts payable or paid by UUPOCOWORKING SLL to you under these ÛUPO Damage Protection Terms, any amounts it may have in its possession, or to be subsequently collected, from any other person or entity obligated to compensate you for your losses or damages.
              <br></br>
              Since these ÛUPO Damage Protection Terms constitute a guarantee agreement, the theory of exoneration applies. Therefore, if the Covered Property in question or the risk associated with such Covered Property substantially changes, UUPOCOWORKING SLL will have the right to exoneration concerning any potential guarantee obligation under these ÛUPO Damage Protection Terms.
              <br></br>
              You acknowledge and agree that UUPOCOWORKING SLL has the right, at its discretion, to deny full or partial payment under these ÛUPO Damage Protection Terms if you fail, at any time, to fulfill your obligations set forth in the Terms and Conditions, UUPOPAYMENTS, and our policies.
            </li>
            <li>
              <strong>Modification or Termination of ÛUPO Damage Protection Terms:</strong>
              To the extent permitted by applicable law in your jurisdiction, UUPOCOWORKING SLL reserves the right to modify or terminate these ÛUPO Damage Protection Terms at any time, at its sole discretion.
              <br></br>
              If UUPOCOWORKING SLL terminates these ÛUPO Damage Protection Terms, UUPOCOWORKING SLL will notify you by email at least thirty (30) days before such termination, and UUPOCOWORKING SLL will continue processing all ÛUPO Damage Protection Payment Request Forms you have submitted before the termination effective date, but your right to submit any new ÛUPO Damage Protection Payment Request Form will be immediately terminated.
              <br></br>
              If UUPOCOWORKING SLL modifies these ÛUPO Damage Protection Terms, we will post the modification on the ÛUPO.com Platform. UUPOCOWORKING SLL will continue processing all ÛUPO Damage Protection Payment Request Forms you have submitted before the effective date of the modification.
              <br></br>
              Additionally, and without limiting UUPOCOWORKING SLL's rights set forth above in the immediately preceding paragraph, UUPOCOWORKING SLL reserves the right to modify or terminate these ÛUPO Damage Protection Terms generally or in any jurisdiction, at any time and at its sole discretion, if: (i) these ÛUPO Damage Protection Terms are interpreted as an offer to insure or constitute insurance or an insurance contract or service agreement by any governmental authority or regulatory agency in any jurisdiction; (ii) UUPOCOWORKING SLL is required to obtain a license or permit of any kind to continue offering these ÛUPO Damage Protection Terms in any jurisdiction; or (iii) UUPOCOWORKING SLL determines, or a court or arbitrator holds, that the provisions of these ÛUPO Damage Protection Terms violate applicable law. If UUPOCOWORKING SLL modifies or terminates these ÛUPO Damage Protection Terms as provided above, UUPOCOWORKING SLL will process all ÛUPO Damage Protection Payment Request Forms you submit before or until the effective date of such modification or termination unless such processing is prohibited by law, regulation, ordinance, order, or decree of any governmental authority or otherwise.
              <br></br>
              The current version of the ÛUPO Damage Protection Terms will be available through the Site and Application. The relevant version is the one in effect on the date UUPOCOWORKING SLL receives the ÛUPO Damage Protection Payment Request Form.
            </li>
            <li>
              <strong>Subrogation:</strong>
              UUPOCOWORKING SLL and/or its insurer have the right to subrogate any payment or payment request made by a ÛUPO or made to a ÛUPO under these ÛUPO Damage Protection Terms. In such case, UUPOCOWORKING SLL and/or its insurer have the right to seek reimbursement and indemnification from the Responsible ÛUPER or any Guest, or from any other person or entity that may be liable for the payment of such request or such amounts.
              <br></br>
              If UUPOCOWORKING SLL and/or its insurer, at its discretion, chooses to exercise its subrogation rights, the ÛUPO will reasonably cooperate with UUPOCOWORKING SLL and/or its insurer, including, without limitation, by executing any documents and by providing any information UUPOCOWORKING SLL and/or its insurer may reasonably request to assist them in exercising their subrogation rights.
            </li>
            <li>
              <strong>Dispute Resolution:</strong>
              These ÛUPO Damage Protection Terms will be interpreted in accordance with Spanish law, and you and UUPOCOWORKING SLL agree to submit to the exclusive jurisdiction of the courts of Spain. Any dispute arising from or in connection with these ÛUPO Damage Protection Terms will be resolved through binding arbitration, which will be governed by the rules of the Spanish Arbitration Law.
              <br></br>
              The parties will select an arbitrator who is an expert in the field of the dispute and who is independent and impartial.
              <br></br>
              The arbitration award will be final and binding on the parties, and the parties agree to comply with and enforce the award in any jurisdiction. The prevailing party in the arbitration will be entitled to recover its reasonable costs and expenses, including attorney's fees, incurred in connection with the arbitration.
            </li>
            <li>
              <strong>General Provisions:</strong>
              <ul className={css.listRefund}>
                <li>These ÛUPO Damage Protection Terms constitute the entire agreement between you and UUPOCOWORKING SLL regarding the subject matter hereof and supersede any prior agreements or understandings, whether written or oral, between you and UUPOCOWORKING SLL regarding the subject matter hereof.</li>
                <li>If any provision of these ÛUPO Damage Protection Terms is found to be invalid or unenforceable, the remaining provisions will remain in full force and effect.</li>
                <li>Any failure by UUPOCOWORKING SLL to enforce any right or provision of these ÛUPO Damage Protection Terms will not constitute a waiver of future enforcement of that right or provision.</li>
                <li>These ÛUPO Damage Protection Terms, and any rights and licenses granted hereunder, may not be transferred or assigned by you, but may be assigned by UUPOCOWORKING SLL without restriction.</li>
                <li>UUPOCOWORKING SLL will not be liable for any failure to perform or delay in performing any obligation under these ÛUPO Damage Protection Terms if such failure or delay is due to circumstances beyond its reasonable control, including, without limitation, acts of God, natural disasters, war, terrorism, civil disturbance, labor disputes, or governmental actions.</li>
              </ul>
            </li>
            <li>
              <strong>Contact Information:</strong>
              <br></br>
              If you have any questions about these ÛUPO Damage Protection Terms, please contact us at: <a href="mailto:hola@uupo.es" className={css.enlaceEmail}>hola@uupo.es</a>
            </li>
          </ol>
        </li>
      </ol>
    </div>
  );
};

TermProtectionEnglish.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

TermProtectionEnglish.propTypes = {
  rootClassName: string,
  className: string,
};

export default TermProtectionEnglish;
