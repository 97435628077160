import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './CancellationPolicy.module.css';

const CancellationPolicyEnglish = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  // prettier-ignore
  return (
    <div className={classes}>
      <p className={css.lastUpdated}>Effective date: December 1, 2022</p>

      <p>
        Although cancellations by ÛUPOs are very rare and some are due to reasons beyond their control, they can cause significant disruptions for ÛUPERs and damage their trust in our community. For this reason, in certain circumstances, if the ÛUPO cancels a confirmed Pop Up reservation, or if they are deemed responsible for a cancellation under this Policy, ÛUPOCOWORKING SLL may apply fees or other consequences. The fees and other consequences provided in this Policy are intended to reflect the costs and impact these cancellations have on ÛUPERs, the ÛUPO community, and ÛUPOCOWORKING SLL in general. We will not apply fees or, in some cases, other consequences if the ÛUPO cancels due to a Force Majeure Event or for a valid reason beyond their control.
      </p>

      <p className={css.titleRefund}>
        Cancellation Fees
      </p>
      
      <p>
        The minimum cancellation fee is 100 euros, and the maximum is 1,500 euros. The amount will be calculated based on the reservation amount and the advance notice of the cancellation:
      </p>

			<ul className={css.listRefund}>
				<li>If canceled with 48 hours' notice or less, or after arrival, the fee will be 50% of the amount corresponding to the remaining days of the reservation.</li>
				<li>If canceled with more than 48 hours' notice, and when there are 30 days or less until arrival, the fee will be 25% of the reservation amount.</li>
				<li>If canceled with more than 30 days' notice until arrival, the fee will be 10% of the reservation amount.</li>
			</ul>

			<p>When calculating cancellation fees, the reservation amount includes the base price, cleaning fees, and sales support service, but not taxes or ÛUPER commissions. The minimum cancellation fee will be 100 euros, even if the calculated amount is lower. Similarly, the maximum amount will be 1,500 euros, even if the calculation results in a higher figure.</p>

			<p>In general, cancellation fees are withheld from the ÛUPO's subsequent payments, as provided in the Payment Terms. In addition to the fees and consequences established in this Policy, ÛUPOs who cancel or are responsible for a cancellation will not receive any payment for that reservation. If the ÛUPO has already received the money, the corresponding amount will be withheld from their future payments.</p>

      <p className={css.titleRefund}>
				Situations Where Cancellation Fees May Not Apply
      </p>
      
      <p>
				We will waive the fees established in this Policy in certain situations, for example, if the ÛUPO cancels due to a Force Majeure Event or certain valid reasons beyond their control.
      </p>

			<p>Even if a cancellation fee is not charged, other consequences may apply, such as marking the corresponding dates as unavailable on a Listing's calendar.</p>

			<p>ÛUPOs who believe they are in one of these situations must provide documentation or other types of evidence for us to consider waiving the cancellation fee or applying other consequences.</p>

      <p className={css.titleRefund}>
				Other Consequences
      </p>
      
			<p>In addition to a cancellation fee, other consequences may apply, such as marking the corresponding dates as unavailable on the calendar for a canceled reservation. This way, the ÛUPO will not be able to accept other reservations for the same Listing on those days.</p>

			<p>ÛUPOs who repeatedly cancel confirmed reservations without a valid reason may face other consequences, as explained in our Terms and Conditions of Service. For example, we may suspend or remove the listing or account of such ÛUPOs.</p>

			<p></p>

      <p className={css.titleRefund}>
				When is the ÛUPO Responsible for a Cancellation?
      </p>
      
      <p>
				When this Policy mentions that a ÛUPO is responsible for a cancellation, it refers to situations where the actual conditions differ substantially and significantly from how the Listing was described at the time of booking. For example, it includes cases of overlapping reservations; replacing the reserved Pop Up with another property, or significant inaccuracies in the Listing that materially affect the ÛUPER's commercial event, such as offering a feature of the Pop Up that is not available.
      </p>

			<p className={css.titleRefund}>
				Other Considerations to Keep in Mind
      </p>
      
      <p>
				If a ÛUPO cannot honor a reservation, they must cancel it, not ask the ÛUPER to do so.
      </p>
			<p>Making false statements or providing misleading information regarding this Policy constitutes a breach of our Terms and Conditions of Service and could result in the removal of your account or other consequences.</p>
			<p>This Policy applies to cancellations occurring from the effective date (inclusive). The rights of ÛUPOs or ÛUPERs to take legal action will not be affected. Any changes to this Policy will be made in accordance with our Terms and Conditions of Service.</p>
    </div>
  );

};

CancellationPolicyEnglish.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

CancellationPolicyEnglish.propTypes = {
  rootClassName: string,
  className: string,
};

export default CancellationPolicyEnglish;
