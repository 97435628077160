import React from "react";
import css from './Client.module.css';
import { FormattedMessage } from '../../../../util/reactIntl';
import Discover from "../../images/Discover.png";
import Book from "../../images/Book.png";
import Come from "../../images/Come.png";

const Client = props => {
    return (
        <>
            <div className={css.card}>
                <div className={css.imageContainer}>
                    <img className={css.image} src={Discover} />
                </div>
                <p className={css.title}>
                    <FormattedMessage id="SectionTwoUuperNew.Client.card1.title" />
                </p>
                <p className={css.text}>
                    <FormattedMessage id="SectionTwoUuperNew.Client.card1.text1" />
                    <strong>
                        <FormattedMessage id="SectionTwoUuperNew.Client.card1.text2" />
                    </strong>
                    <FormattedMessage id="SectionTwoUuperNew.Client.card1.text3" />
                    <strong>
                        <FormattedMessage id="SectionTwoUuperNew.Client.card1.text4" />
                    </strong>
                    <FormattedMessage id="SectionTwoUuperNew.Client.card1.text5" />
                    <strong>
                        <FormattedMessage id="SectionTwoUuperNew.Client.card1.text6" />
                    </strong>
                </p>

            </div>
            <div className={css.card}>
                <div className={css.imageContainer}>
                    <img className={css.image} src={Book} />
                </div>
                <p className={css.title}>
                    <FormattedMessage id="SectionTwoUuperNew.Client.card2.title" />
                </p>
                <p className={css.text}>
                    <strong>
                        <FormattedMessage id="SectionTwoUuperNew.Client.card2.text1" />
                    </strong>
                    <FormattedMessage id="SectionTwoUuperNew.Client.card2.text2" />
                    <strong>
                        <FormattedMessage id="SectionTwoUuperNew.Client.card2.text3" />
                    </strong>
                </p>
            </div>
            <div className={css.card}>
                <div className={css.imageContainer}>
                    <img className={css.image} src={Come} />
                </div>
                <p className={css.title}>
                    <FormattedMessage id="SectionTwoUuperNew.Client.card3.title" />
                </p>
                <p className={css.text}>
                    <strong>
                        <FormattedMessage id="SectionTwoUuperNew.Client.card3.text1" />
                    </strong>
                    <FormattedMessage id="SectionTwoUuperNew.Client.card3.text2" />
                    <strong>
                        <FormattedMessage id="SectionTwoUuperNew.Client.card3.text3" />
                    </strong>
                    <FormattedMessage id="SectionTwoUuperNew.Client.card3.text4" />
                    <strong>
                        <FormattedMessage id="SectionTwoUuperNew.Client.card3.text5" />
                    </strong>
                    <FormattedMessage id="SectionTwoUuperNew.Client.card3.text6" />
                    <strong>
                        <FormattedMessage id="SectionTwoUuperNew.Client.card3.text7" />
                    </strong>
                </p>
            </div>
        </>
    );
};

export default Client;