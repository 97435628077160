import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import config from '../../config.js';
import { FormattedMessage } from '../../util/reactIntl';
import { ensureOwnListing } from '../../util/data';
import { findOptionsForSelectFilter } from '../../util/search';

import { ListingLink } from '../../components';
import { EditListingFeaturesForm } from '../../forms';

import css from './EditListingFeaturesPanel.module.css';

const EditListingFeaturesPanel = props => {
  const {
    className,
    rootClassName,
    listing,
    onSubmit,
    onChange,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    errors,
    intl,
  } = props;

  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureOwnListing(listing);
  const { publicData } = currentListing.attributes;

  const panelTitle = currentListing.id ? (
    <FormattedMessage
      id="EditListingFeaturesPanel.createListingTitle"
      values={{ listingTitle: <ListingLink listing={listing} /> }}
    />
  ) : (
    <FormattedMessage id="EditListingFeaturesPanel.createListingTitle" />
  );

  const denominacionOptions = findOptionsForSelectFilter(
    'denominacion',
    config.custom.filters
  ).map((a) => {
    return {
      key: a.key,
      label: intl.formatMessage({ id: "Filters." + a.key })
    }
  });

  const orientacionOptions = findOptionsForSelectFilter(
    'orientacion',
    config.custom.filters
  ).map((a) => {
    return {
      key: a.key,
      label: intl.formatMessage({ id: "Filters." + a.key })
    }
  });



  const altura = publicData && publicData.altura ? publicData.altura : 'false';

  return (
    <div className={classes}>
      <h1 className={css.title}>{panelTitle}</h1>
      <p className={css.subtitle}><FormattedMessage id="EditListingFeaturesPanel.createListingSubtitle" /></p>
      <EditListingFeaturesForm
        className={css.form}
        initialValues={{ denominacion: publicData.denominacion, orientacion: publicData.orientacion, altura }}
        onSubmit={values => {
          const { denominacion, orientacion, altura = 'false', } = values;
          const updateValues = {
            title: ' ',
            description: ' ',
            publicData: {
              denominacion, orientacion, altura
            },
          };
          onSubmit(updateValues);
        }}
        onChange={onChange}
        saveActionMsg={submitButtonText}
        updated={panelUpdated}
        updateError={errors.updateListingError}
        updateInProgress={updateInProgress}
        denominacionOptions={denominacionOptions}
        orientacionOptions={orientacionOptions}
      />
    </div>
  );
};

const { func, object, string, bool } = PropTypes;

EditListingFeaturesPanel.defaultProps = {
  className: null,
  rootClassName: null,
  listing: null,
};

EditListingFeaturesPanel.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};

export default EditListingFeaturesPanel;
