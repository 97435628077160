import React, { Component, useEffect, useState } from 'react';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import img1 from '../../assets/landingUppo/UUseguro.png';
import img2 from '../../assets/apreton.svg';
import { NamedLink } from '../../components';
import css from './SectionTwoUupo.module.css';

const SectionTwoUupo = props => {

  const [mounted, setMounted] = useState(false);
  const { rootClassName, className, latestListings } = props;

  useEffect(() => {
    setMounted(true);
  }, []);

  const classes = classNames(rootClassName || css.root, className)

  return (
    <>
      <div className={`${css.uuperContent} ${classes}`}>
        <div className={css.containerUuper}>
          <div className={css.contentTextUuper}>
            <div className={css.textUuper}>
              {/* <p className={classNames(css.textOneUupo, { [css.textOneUupoFEDelay]: mounted })}>
                <FormattedMessage id="SectionTwoUupo.title" />
              </p> */}
              <h2 className={classNames(css.subtitleTwoUupo, { [css.subtitleTwoUupoFEDelay]: mounted })}>
                <FormattedMessage id="SectionTwoUupo.subtitle" values={{ lineBreak: <br /> }} />
              </h2>
              {/* <p className={classNames(css.textTwoUupo, { [css.textTwoUupoFEDelay]: mounted })}>
                <FormattedMessage id="SectionTwoUupo.text1" />
                <FormattedMessage id="SectionTwoUupo.text2" />
                <FormattedMessage id="SectionTwoUupo.text3" />
                <FormattedMessage id="SectionTwoUupo.text4" />
              </p> */}

              <p className={classNames(css.textTwoUupo, { [css.textTwoUupoFEDelay]: mounted })}>
                <FormattedMessage id="SectionTwoUupo.allText" />
              </p>
              <h3 style={{ margin: 0 }}>
                <NamedLink
                  name="FaqPage"
                  className={classNames(css.twoButton, { [css.twoButtonFEDelay]: mounted })}
                >
                  <FormattedMessage id="SectionTwoUupo.browseButton" />
                </NamedLink>
              </h3>
            </div>
          </div>
          <div className={css.contentImgUuper}></div>
        </div>
      </div>
    </>
  )
};

export default SectionTwoUupo;