import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './SeguroUupo.module.css';

const SeguroUupo = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  // prettier-ignore
  return (
    <div className={classes}>
      <p className={css.titleRefund}>ÛUseguro les ofrece TRES protecciones a todos los ÛUPERs en cada organización de un POP UP.</p>
      <p className={css.titleRefund}>Garantía de protección de la reserva</p>
      <p>En el improbable caso de que un ÛUPO tenga que cancelar tu reserva en los 30 días previos a la llegada, te encontraremos un POP UP similar o mejor, o te enviaremos un reembolso.</p>
      <p className={css.titleRefund}>Garantía de cambio de reserva</p>
      <p>Si no puedes registrar la llegada en tu POP UP  y el ÛUPO no logra resolver el problema, te encontraremos un POP UP similar o mejor para la duración de tu evento original, o te enviaremos un reembolso.</p>
      <p className={css.titleRefund}>Garantía de fidelidad del anuncio</p>
      <p>Si durante tu estancia te das cuenta de que tu POP UP no coincide con lo que aparecía en el anuncio (por ejemplo, se daña la iluminación y el ÛUPO no puede arreglarlo o si el espacio es menor de lo que  indica en el anuncio), tendrás tres días para avisarnos. Una vez que lo hagas, buscaremos un POP UP similar o mejor, o te enviaremos un reembolso. </p>
    </div>
  );
};

SeguroUupo.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

SeguroUupo.propTypes = {
  rootClassName: string,
  className: string,
};

export default SeguroUupo;
