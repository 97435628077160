import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './CookiesPolicy.module.css';

const CookiesPolicy = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  // prettier-ignore
  return (
    <div className={classes}>

      <p className={css.titleRefund}>
        Última actualización: 1 de diciembre de 2022
      </p>

      <p className={css.titleRefund}>
        Responsable: Este sitio web es propiedad de ÛUPOCOWORKING SLL.
      </p>

      <p className={css.titleRefund}>
        Delegado de Protección de datos: hola@uupo.es
      </p>

      <p>En la presente política de cookies, encontrarás la descripción y finalidad de cada una de las cookies utilizadas, así como su nombre, el servidor de envío, su caducidad y, si corresponde, la existencia de transferencia internacional de datos.</p>

      <p>La legitimación para el uso de las cookies estrictamente funcionales, viene dada por que el tratamiento es necesario para el cumplimiento de una obligación legal, conforme la ley 34/2002 (Ley de Servicios de la Sociedad de la Información y de Comercio Electrónico).</p>

      <p>La legitimación para el uso de las cookies con fines analíticos, de optimización, de personalización, de publicidad comportamental y valoración, está basado en el consentimiento que se le ha solicitado, conforme al Art. 6.1.a) del Reglamento (UE) 2016/679.</p>

      <p>En "ÛUPO", utilizamos cookies con el objetivo de prestar un mejor servicio y proporcionarte una mejor experiencia en tu navegación. Queremos informarte de manera clara y precisa sobre las cookies que utilizamos, detallando a continuación, qué es una cookie, para qué sirve, por qué es importante, qué tipos de cookies utilizamos, cuáles son su finalidad y cómo puedes configurarlas o deshabilitarlas si así lo deseas.</p>

      <p className={css.titleRefund}>
        Definición y Función genérica de las Cookies:
      </p>
      
      <p>
        Este sitio web utiliza cookies y/o tecnologías similares que almacenan y recuperan información cuando navegas. En general, estas tecnologías pueden servir para finalidades muy diversas, como, por ejemplo, reconocerte como usuario, obtener información sobre tus hábitos de navegación, o personalizar la forma en que se muestra el contenido.
      </p>

      <p className={css.titleRefund}>
        ¿Por qué son importantes?
      </p>

      <p>Desde un punto de vista técnico, permiten que los servicios online funcionen de forma más ágil y adaptada a las preferencias de los usuarios, como por ejemplo almacenar el idioma, la moneda del país o detectar el dispositivo de acceso.</p>
      <p>Establecen niveles de protección y seguridad que impiden o dificultan ciberataques contra el sitio web o sus usuarios.</p>
      <p>Sirven para optimizar la publicidad que mostramos a los usuarios, ofreciendo la que más se ajusta a sus intereses.</p>
     
      <p className={css.titleRefund}>
        ¿Cómo se clasifican los diferentes tipos de Cookies que se utilizan en esta página?
      </p>
      <p>Por su duración:</p>
      <ul className={css.listRefund}>
        <li><strong>Cookies de sesión: </strong>expiran cuando el Usuario abandona la página o cierra el navegador, es decir, están activas mientras dura la visita al servicio y por tanto caducan al abandonarlo o a las pocas horas de acceder.</li>
        <li><strong>Cookies persistentes: </strong>expiran cuando se cumple el objetivo para el que sirven o bien cuando se borran manualmente, tienen fecha de borrado, y se utilizan normalmente en proceso de compra online, personalizaciones o en el registro, para no tener que introducir nuestra contraseña constantemente.</li>
      </ul>
      <p>Por su titularidad:</p>
      <ul className={css.listRefund}>
        <li><strong>Cookies propias: </strong>son aquellas que se envían al equipo terminal del usuario desde un equipo o dominio gestionado por el propio editor y desde el que se presta el servicio solicitado por el usuario.</li>
        <li><strong>Cookies de terceros: </strong>son aquellas que se envían al equipo terminal del usuario desde un equipo o dominio que no es gestionado por el editor, sino por otra entidad que trata los datos obtenidos través de las cookies.</li>
      </ul>
      <p>Por su finalidad: consulta las finalidades previstas, a continuación:</p>
      <ul className={css.listRefund}>
        <li><strong>De análisis: </strong>son aquellas que tratadas por nosotros o por terceros, nos permiten cuantificar el número de usuarios y así realizar la medición y análisis estadístico de la utilización que hacen los usuarios del servicio ofertado. Para ello se analiza tu navegación en nuestra página web con el fin de mejorar la oferta de productos o servicios que te ofrecemos, por ejemplo, Google Analytics.</li>
        <li><strong>Publicitarias comportamentales: </strong>son aquellas que, tratadas por nosotros o por terceros, nos permiten analizar tus hábitos de navegación en Internet para que podamos mostrarte publicidad relacionada con tu perfil de navegación.</li>
      </ul>
      <p>Por tipo:</p>
      <ul className={css.listRefund}>
        <li><strong>Necesarias: </strong>te permiten desplazarte por el sitio web y utilizar sus funciones básicas. Normalmente se instalan exclusivamente en respuesta a acciones que realices que se traduzcan en una solicitud de servicios, tales como acceso a un área segura de nuestro sitio web, entrada o autenticación de usuario o personalización de la interfaz de usuario.</li>
        <li><strong>No necesarias: </strong>el resto de cookies.</li>
      </ul>
      <p>En esta web se utilizan las siguientes cookies:</p>
      <table className={css.tableCookies} style={{width: "100%", fontSize: "14px"}}>
        <tr>
          <td className={css.titleTable}>Cookie</td>
          <td className={css.titleTable}>Tipo</td>
          <td className={css.titleTable}>Duración</td>
          <td className={css.titleTable}>Descripción</td>
        </tr>
        <tr>
          <td>euCookiesAccepted</td>
          <td>Necesaria</td>
          <td>10 años</td>
          <td>Guarda si el visitante ha hecho click en el botón continuar del banner de aviso de uso de cookies</td>
        </tr>
        <tr>
          <td>st-token de servicio-token</td>
          <td>Necesaria</td>
          <td>1 mes</td>
          <td>Guarda el token de acceso al servidor de Sharetribe, necesario para la transferencia de información de la aplicación web</td>
        </tr>
        <tr>
          <td>st-token de servicio-oauth2State</td>
          <td>Necesaria</td>
          <td>30 minutos</td>
          <td>Cookie de estado utilizada para la autorización de autenticación</td>
        </tr>
        <tr>
          <td>st-token de servicio-pkceCodeVerifier</td>
          <td>Necesaria</td>
          <td>30 minutos</td>
          <td>Cookie de verificación utilizada para la autorización de autenticación</td>
        </tr>
        <tr>
          <td>st-authinfo</td>
          <td>Necesaria</td>
          <td>15 minutos</td>
          <td>Utilizada para permitir al visitante crear un nuevo usuario con un IdP (proveedor de identidad)</td>
        </tr>
        <tr>
          <td>st-autherror</td>
          <td>Necesaria</td>
          <td>15 minutos</td>
          <td>Utilizada para mostrar información al visitante sobre un error de autenticación con un IdP (proveedor de identidad)</td>
        </tr>
      </table>
      <p className={css.titleRefund}>
        Configuración de cookies:
      </p>
      <p>Si lo que quieres es eliminar o bloquear la descarga de cookies, a continuación, te indicamos los links de los principales navegadores y dispositivos para que dispongas de toda la información para consultar cómo gestionar las cookies en tu navegador.</p>
      <p>Para más información sobre el Navegador Opera™ haz click <a href='https://help.opera.com/en/latest/web-preferences/#cookies' target="_blank" rel="noopener noreferrer">aquí</a></p>
      <p>Para más información sobre el Navegador Google Chrome™ haz click <a href='https://support.google.com/chrome/answer/95647' target="_blank" rel="noopener noreferrer">aquí</a></p>
      <p>Para más información sobre el Navegador Google Chrome™ en Android haz click <a href='https://support.google.com/chrome/answer/95647' target="_blank" rel="noopener noreferrer">aquí</a></p>
      <p>Para más información sobre el Navegador Internet Explorer™ haz click <a href='https://support.microsoft.com/es-es/topic/eliminar-y-administrar-cookies-168dab11-0753-043d-7c16-ede5947fc64d' target="_blank" rel="noopener noreferrer">aquí</a></p>
      <p>Para más información sobre el Navegador Edge™ haz click <a href='https://support.microsoft.com/es-es/windows/microsoft-edge-datos-de-exploraci%C3%B3n-y-privacidad-bb8174ba-9d73-dcf2-9b4a-c582b4e640dd' target="_blank" rel="noopener noreferrer">aquí</a></p>
      <p>Para más información sobre el Navegador Mozilla Firefox™ haz click <a href='https://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-sitios-web-rastrear-preferencias' target="_blank" rel="noopener noreferrer">aquí</a></p>
      <p>Para más información sobre el Navegador Safari™ en macOS haz click <a href='https://support.apple.com/es-es/guide/safari/sfri11471/mac' target="_blank" rel="noopener noreferrer">aquí</a></p>
      <p>Para más información sobre el Navegador Safari™ en IOS haz click <a href='https://support.apple.com/es-es/HT201265' target="_blank" rel="noopener noreferrer">aquí</a></p>
      <p className={css.titleRefund}>
        Aceptación de cookies:
      </p>
      <p>Al acceder a este sitio web por primera vez, verás una ventana dónde se informa de la utilización de las cookies y donde puedes consultar esta "Política de cookies". Puedes cambiar la configuración de cookies en cualquier momento, configurando el navegador para aceptar, o no, las cookies que recibes o para que el navegador te avise cuando un servidor quiera guardar una cookie. Te informamos de que, en el caso de bloquear o no aceptar la instalación de cookies, es posible que ciertos servicios no estén disponibles sin la utilización de éstas o que no pueda acceder a determinados servicios ni tampoco aprovechar por completo todo lo que nuestra web ofrece.</p>
      <p>"ÛUPO" te agradece que consientas la aceptación de cookies, esto nos ayuda a obtener datos más precisos que nos permiten mejorar el contenido y el diseño de nuestras páginas webs para adaptarlas a tus preferencias.</p>

    </div>
  );
};

CookiesPolicy.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

CookiesPolicy.propTypes = {
  rootClassName: string,
  className: string,
};

export default CookiesPolicy;
