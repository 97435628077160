import React from "react";
import css from './Seller.module.css';
import { FormattedMessage } from '../../../../util/reactIntl';
import List from "../../images/List.png";
import Accept from "../../images/Accept.png";
import Earn from "../../images/Earn.png";

const Seller = props => {
    return (
        <>
            <div className={css.card}>
                <div className={css.imageContainer}>
                    <img className={css.image} src={List} />
                </div>
                <p className={css.title}>
                    <FormattedMessage id="SectionTwoUuperNew.Seller.card1.title" />
                </p>
                <p className={css.text}>
                    <FormattedMessage id="SectionTwoUuperNew.Seller.card1.text1" />
                    <strong>
                        <FormattedMessage id="SectionTwoUuperNew.Seller.card1.text2" />
                    </strong>
                    <FormattedMessage id="SectionTwoUuperNew.Seller.card1.text3" />
                    <strong>
                        <FormattedMessage id="SectionTwoUuperNew.Seller.card1.text4" />
                    </strong>
                    <FormattedMessage id="SectionTwoUuperNew.Seller.card1.text5" />
                    <strong>
                        <FormattedMessage id="SectionTwoUuperNew.Seller.card1.text6" />
                    </strong>
                    <FormattedMessage id="SectionTwoUuperNew.Seller.card1.text7" />
                </p>

            </div>
            <div className={css.card}>
                <div className={css.imageContainer}>
                    <img className={css.image} src={Accept} />
                </div>
                <p className={css.title}>
                    <FormattedMessage id="SectionTwoUuperNew.Seller.card2.title" />
                </p>
                <p className={css.text}>
                    <strong>
                        <FormattedMessage id="SectionTwoUuperNew.Seller.card2.text1" />
                    </strong>
                    <FormattedMessage id="SectionTwoUuperNew.Seller.card2.text2" />
                    <strong>
                        <FormattedMessage id="SectionTwoUuperNew.Seller.card2.text3" />
                    </strong>
                    <FormattedMessage id="SectionTwoUuperNew.Seller.card2.text4" />
                    <strong>
                        <FormattedMessage id="SectionTwoUuperNew.Seller.card2.text5" />
                    </strong>
                </p>
            </div>
            <div className={css.card}>
                <div className={css.imageContainer}>
                    <img className={css.image} src={Earn} />
                </div>
                <p className={css.title}>
                    <FormattedMessage id="SectionTwoUuperNew.Seller.card3.title" />
                </p>
                <p className={css.text}>
                    <FormattedMessage id="SectionTwoUuperNew.Seller.card3.text1" />
                    <strong>
                        <FormattedMessage id="SectionTwoUuperNew.Seller.card3.text2" />
                    </strong>
                    <FormattedMessage id="SectionTwoUuperNew.Seller.card3.text3" />
                </p>
            </div>
        </>
    );
};

export default Seller;