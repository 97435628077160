import React, { Component, useEffect, useState } from 'react';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { NamedLink } from '../../components';
import css from './SectionTwo.module.css';

const SectionTwo = props => {
  const [mounted, setMounted] = useState(false);
  const { rootClassName, className, latestListings } = props;

  useEffect(() => {
    setMounted(true);
  }, []);

  const classes = classNames(rootClassName || css.root, className)

  return (
    <>
      <div className={classNames(classes, css.containerPrincipal)}>
        <div className={css.containerColumns}>
          <div className={css.firstColumn}>
            {/* <p className={css.title}>
              <FormattedMessage id="SectionTwo.title" />
            </p> */}
            <p className={css.subTitle}>
              <FormattedMessage id="SectionTwo.subTitle" />
            </p>
            <p className={css.text}>
              <FormattedMessage id="SectionTwo.text1" />
            </p>
            <p className={css.text}>
              <FormattedMessage id="SectionTwo.text2" />
            </p>
            <div className={css.line} />
            <NamedLink
              name="SeguroUupoPage"
              className={classNames(css.twoButton, { [css.twoButtonFEDelay]: mounted })}
            >
              <FormattedMessage id="SectionTwo.browseButton" />
            </NamedLink>
          </div>
          <div className={css.secondColumn}></div>
        </div>
      </div>
    </>
  )
};

export default SectionTwo;