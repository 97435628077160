import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './ProtectionData.module.css';

const ProtectionDataEnglish = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  // prettier-ignore
  return (
    <div className={classes}>
   
      <p>
        <strong>Data Protection Information</strong>
      </p>

      <p>
        <strong>(EU Regulation 2016/679 and Organic Law 3/2018)</strong>
      </p>

      <p className={css.titleRefund}>Who is responsible for processing your data?</p>
      <p>UUPOCOWORKING S.L.L. CIF: B72446073</p>
      <p>Email: <a href="mailto:hola@uupo.es" className={css.enlaceEmail}>hola@uupo.es</a></p>
      <p>More information about us in <a className={css.enlaceEmail} href="/terminos-condiciones">Legal Notice</a></p>
      <p className={css.titleRefund}>For what purpose do we process your personal data?</p>
      <p>For the administrative management of your registration as part of the contact agenda.</p>
      <p>For the effective contractual provision of the coworking service that you know through our Terms and Conditions, in its validation and completion phase, and in all administrative and fiscal procedures of the process.</p>
      <p className={css.titleRefund}>How long do we keep your data?</p>
      <p>Your data will be kept for the necessary years to comply with legal obligations.</p>
      <p className={css.titleRefund}>What is the legal basis for processing your data?</p>
      <p>The processing is necessary for the contractual execution of the service in which you are a party as an interested party or for the application at your request of pre-contractual measures.</p>
      <p>The processing is necessary for the satisfaction of the legitimate interests of UUPOCOWORKING S.L.L as long as your interests, rights, and fundamental freedoms do not prevail.</p>
      <p className={css.titleRefund}>To which recipients will your data be communicated?</p>
      <p>Your data will not be communicated to third parties</p>
      <p>Except legal imperative and to Public Administrations for the correct fulfillment of administrative and legal obligations that derive.</p>
      <p>Except to persons, companies, and financial entities that provide services to UUPOCOWORKING S.L.L for the efficient development of the contracted services.</p>
      <p className={css.titleRefund}>Forecast of transfer to third countries.</p>
      <p>If the development of our services requires international data transfer, it will comply with the legality established in Chapter V of the GDPR and Title VI of the LOPDGD. For countries without an adequacy decision, or adequate safeguards, Article 49 of the EU GDPR 2016/679 will apply, with the legal basis in this case being the execution of the contract in all its terms, the legal obligations that derive and the explicit consent if deemed necessary.</p>
      <p className={css.titleRefund}>What are your rights when you provide us with your data?</p>
      <p>You can exercise the rights of access, rectification, deletion, opposition, portability, or limitation by sending a written request to our postal or electronic address, proving your identity, providing the forms for these purposes.</p>
      <p>You also have the right to file a complaint with the AEPD in case you consider that your data protection rights are violated.</p>
      <p className={css.titleRefund}>PROCESSING OF PHOTOGRAPHS AND AUDIOVISUAL MATERIAL</p>
      <p>Photographs and audiovisual material uploaded to the ÛUPO platform must refer exclusively to commercial spaces that may be of interest to ÛUPOs and ÛUPERs.</p>
      <p><strong>UUPOCOWORKING S.L.L will not validate records that contain photos or any other audiovisual material that involves the identification of individuals, being exempt from liability for any breach of this rule by users</strong></p>
    </div>
);

};

ProtectionDataEnglish.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

ProtectionDataEnglish.propTypes = {
  rootClassName: string,
  className: string,
};

export default ProtectionDataEnglish;
