import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './CausesPolicy.module.css';

const CausesPolicy = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  // prettier-ignore
  return (
    <div className={classes}>
      <p className={css.lastUpdated}>¡No te preocupes! Si tienes que cancelar tu reserva por una emergencia o una causa de fuerza mayor, podemos ayudarte:</p>
      <ol className={css.listRefund}>
        <li>Lee nuestra Política de Causas de Fuerza Mayor  para comprobar si puedes acogerte a ella.</li>
        <li>Asegúrate de contar con toda la documentación necesaria.</li>
        <li>Al cancelar tu reserva de un Pop Up, selecciona Se trata de una causa de fuerza mayor.</li>
        <li>Ponte en contacto con nosotros para presentar una reclamación y te explicaremos todos los pasos que tienes que seguir; entre otras cosas, tendrás que enviarnos la documentación que te solicitemos y esperar a que nuestro equipo estudie tu caso.</li>
      </ol>
      <p>
        <strong>Importante:</strong> Las reclamaciones se deben presentar en un plazo de 14 días desde que se cancela la reserva.
      </p>

      <p>Fecha de entrada en vigor: 1 de diciembre, 2022</p>
      <p className={css.titleRefund}>
        Información general
      </p>
      
      <p>
        En esta Política de Causas de Fuerza Mayor se explica cómo gestionamos las cancelaciones cuando surgen imprevistos ajenos a tu control después de la reserva que te impiden o prohíben completarla. 
      </p>
      <p>Si se permite cancelar en virtud de esta política, tendrá prioridad sobre la política de cancelación de la reserva. Los profesionales afectados por alguna de las situaciones incluidas en esta política pueden cancelar su reserva y recibir un reembolso. Los ÛUPOs que se vean afectados por alguna de las situaciones que cubre esta política pueden cancelar sin penalizaciones, pero es posible que las fechas de la reserva cancelada queden marcadas como no disponibles en sus calendarios dependiendo de las circunstancias.</p>
      
      <p className={css.titleRefund}>
        Situaciones cubiertas
      </p>
      
      <p>
        En esta política, se utiliza el término «situación» para referirse a las circunstancias que surjan tras la reserva, que no podían preverse, y que impiden o prohíben legalmente completarla.
      </p>
      
      <p className={css.titleRefund}>
        Cambios en los requisitos del Gobierno con respecto a viajes.
      </p>
      
      <p>
        Cambios inesperados en los requisitos para solicitar un visado o pasaporte que imponga una autoridad gubernamental y que impidan viajar al destino. No están contempladas la pérdida o caducidad de los documentos necesarios para viajar u otras circunstancias personales relacionadas con la autorización de un profesional para desplazarse.
      </p>

      <p className={css.titleRefund}>
        Emergencias y epidemias declaradas.
      </p>
      
      <p>
        Emergencias nacionales o locales, epidemias, pandemias y emergencias de salud pública que declare oficialmente el Gobierno. No se incluyen las enfermedades que son endémicas o que ya están asociadas con una zona determinada, como la malaria en Tailandia o el dengue en Hawái.
      </p>

      <p className={css.titleRefund}>
        Restricciones gubernamentales a los desplazamientos.
      </p>
      
      <p>
        Restricciones de desplazamiento que imponga una autoridad gubernamental y que impidan o prohíban desplazarse a la ubicación del pop up, exponer en él o desplazarse desde allí. No se incluyen las recomendaciones para profesionales no vinculantes y otros consejos similares que pueda dar el Gobierno.
      </p>

      <p className={css.titleRefund}>
        Acciones militares y otros conflictos.
      </p>
      
      <p>
        Actos de guerra, conflictos, invasiones, guerras civiles, terrorismo, explosiones, bombardeos, rebeliones, disturbios, insurrección, desorden público e inestabilidad civil.
      </p>
      
      <p className={css.titleRefund}>
        Catástrofes naturales.
      </p>
      
      <p>
        Catástrofes naturales, actos fortuitos, interrupciones a gran escala de los suministros públicos básicos, erupciones volcánicas, tsunamis y otros fenómenos meteorológicos extremos y anormales. No se incluyen los fenómenos meteorológicos ni las condiciones naturales que son habituales en una zona y que, por lo tanto, pueden ser previsibles como, por ejemplo, los vientos de gran magnitud durante la temporada de huracanes en Florida.
      </p>

      <p className={css.titleRefund}>
        Situaciones que no están cubiertas
      </p>

      <p className={css.titleRefund}>
        Todas las demás.
      </p>
      <p>Esta política solo permite que se cancelen reservas debido a las situaciones descritas anteriormente. Queda excluido todo lo demás. Algunos ejemplos de situaciones que esta política no cubre son: dolencias, enfermedades o lesiones inesperadas; obligaciones gubernamentales como ser convocado para formar parte de un jurado, comparecencias ante los tribunales o deberes militares; recomendaciones para viajeros u otros consejos del Gobierno (que no restrinjan o prohíban los desplazamientos); cancelación o reprogramación de un evento para el que se realizó la reserva; e interrupciones del transporte que no estén relacionadas con una situación cubierta, como cortes de carreteras y cancelaciones de vuelos, trenes, autobuses y ferris. Si cancelas una reserva en estos casos, el importe del reembolso dependerá de la política de cancelación aplicable en cada caso.</p>

      <p className={css.titleRefund}>
        Qué tengo que hacer a continuación
      </p>
      <p>Si te comunicamos que esta política se aplica a tu reserva o publicamos información que lo confirme, sigue las instrucciones de cancelación que te proporcionemos. En principio, podrás cancelar la reserva correspondiente desde la página de reservas y acogerte a esta política. Si crees que tu caso se ajusta a las condiciones establecidas en esta política, pero no has recibido ninguna notificación ni hemos publicado información al respecto, ponte en contacto con nosotros  para cancelar tu reserva. En todos los casos, te pediremos que nos proporciones documentación que demuestre cómo te ha afectado la situación o cómo ha influido en tu reserva.</p>
      <p>Si tienes alguna pregunta, ponte en contacto con nosotros.</p>


    </div>
  );
};

CausesPolicy.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

CausesPolicy.propTypes = {
  rootClassName: string,
  className: string,
};

export default CausesPolicy;
