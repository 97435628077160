import React from 'react';
import { arrayOf, bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import classNames from 'classnames';

// These relative imports need to point to correct directories
import {
  intlShape,
  injectIntl,
  FormattedMessage,
} from '../../util/reactIntl';
import { findOptionsForSelectFilter } from '../../util/search';
import { propTypes } from '../../util/types';
import { required } from '../../util/validators';
import config from '../../config';
import { Form, Button, FieldCheckboxGroup, FieldSelect } from '../../components';

// Create this file using EditListingFeaturesForm.module.css
// as a template.
import css from './EditListingServiciosForm.module.css';
import { localizeFilters } from '../../util/filtersLocalizationHelpers';

export const EditListingServiciosFormComponent = props => (
  <FinalForm
    {...props}
    mutators={{ ...arrayMutators }}
    render={formRenderProps => {
      const {
        className,
        names,
        disabled,
        handleSubmit,
        invalid,
        pristine,
        saveActionMsg,
        updated,
        updateError,
        updateInProgress,
        filterConfig,
        intl
      } = formRenderProps;

      const { name1, name2 } = names

      const errorMessage = updateError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingServiciosForm.updateFailed" />
        </p>
      ) : null;

      const classes = classNames(css.root, className);
      const submitReady = updated && pristine;
      const submitInProgress = updateInProgress;
      const submitDisabled = invalid || disabled || submitInProgress;

      const options1 = localizeFilters(findOptionsForSelectFilter('amenities_uupo', filterConfig), intl);
      const options2 = localizeFilters(findOptionsForSelectFilter('amenities_pop', filterConfig), intl);

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          {errorMessage}
          <h1 className={css.title}>
            <FormattedMessage id="EditListingServiciosForm.whatServices" />
          </h1>
          <p className={css.textPlace}>
            <FormattedMessage id="EditListingServiciosForm.amenitiesUupoyPlaceholder" />
          </p>
          <FieldCheckboxGroup id={name1} name={name1} options={options1} />
          <br />
          <h1 className={css.title}>
            <FormattedMessage id="EditListingServiciosForm.andYourPopup" />
          </h1>
          <p className={css.textPlace} >
            <FormattedMessage id="EditListingServiciosForm.share" />
          </p>
          <FieldCheckboxGroup id={name2} name={name2} options={options2} />
          <Button
            className={css.submitButton}
            type="submit"
            inProgress={submitInProgress}
            disabled={submitDisabled}
            ready={submitReady}
          >
            {saveActionMsg}
          </Button>
        </Form>
      );
    }}
  />
);

EditListingServiciosFormComponent.defaultProps = {
  selectedPlace: null,
  updateError: null,
  filterConfig: config.custom.filters,
};

EditListingServiciosFormComponent.propTypes = {
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  updated: bool.isRequired,
  updateError: propTypes.error,
  updateInProgress: bool.isRequired,
  filterConfig: propTypes.filterConfig,
};

export default compose(injectIntl)(EditListingServiciosFormComponent);