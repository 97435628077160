import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './ServicesFree.module.css';

const ServicesFree = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  // prettier-ignore
  return (
    <div className={classes}>
      <p>Para contribuir a que la plataforma ÛUPO.com funcione adecuadamente y cubrir el coste de servicios como el servicio Atención al Cliente (disponible las 24 horas del día), ÛUPOCOWORKING S.L.L. aplicará una tarifa cuando se confirma una reserva.</p>
      <p>Hay una estructura general de tarifas para las reservas de Pop Ups:</p>
      <p className={css.subtitleRefund}>Tarifa compartida</p>
      <p>Esta opción divide la tarifa entre el ÛUPO y el ÛUPER.</p>
      <p className={css.subtitleRefund}>Tarifa del ÛUPO</p>
      <p>Los ÛUPOs pagan una tarifa del 3%</p>
      <p>Calculamos esta tarifa de servicio a partir del subtotal de la reserva (precio por día + tarifa de limpieza + tarifa adicional, si procede, sin incluir las comisiones e impuestos de UUPOCOWORKING SLL) y la restamos automáticamente del cobro que recibe el ÛUPO.</p>
      <p className={css.subtitleRefund}>Tarifa del ÛUPER</p>
      <p>Esta tarifa varía en función de diversos factores y se muestra en la página destinada a la realización del pago, antes de solicitar la reserva, para que sepas cuál será el importe correspondiente. La mayoría de las tarifas de servicio que deben pagar los ÛUPERs son inferiores al 14.53% del subtotal de la reserva (precio por noche + tarifa de limpieza + tarifa adicional, si procede, sin incluir las comisiones de UUPOCOWORKING SLL, ni los impuestos).</p>
      <p className={css.subtitleRefund}>IVA</p>
      <p>Cuando corresponda, se repercutirá el IVA a la tarifa de servicio.</p>
      <p>Nos reservamos el derecho de modificar nuestras tarifas de servicio en cualquier momento. Te notificaremos cualquier cambio en las tarifas antes de que entren en vigor. Los cambios en las tarifas no afectarán a las reservas realizadas antes de su fecha de entrada en vigor.</p>
      <p className={css.textItalic}>NOTA ACLARATORIA RESPECTO A IMPUESTOS. Impuesto indirecto en operaciones en Canarias, Ceuta y Melilla. Las prestaciones de servicios entre ÛUPOs y ÛUPERs cuando el inmueble en el que se desarrolla el Coworking esté ubicado en alguno de estos territorios, se acogerán a las reglas especiales de localización del hecho imponible a la hora de determinar la aplicación del impuesto indirecto correspondiente.</p>
      <p className={css.textItalic}>Las operaciones a realizar entre ÛUPO y ÛUPER serán consideradas como prestaciones de servicios de Coworking, ya que además del arrendamiento del inmueble, el ÛUPO pondrá a disposición del ÛUPER una serie de servicios accesorios necesarios para que este último pueda desarrollar la actividad económica correspondiente. En este contexto el ÛUPER no se verá obligado a practicar la retención de IRPF en concepto de arrendamiento de inmueble.</p>
    </div>
  );
};

ServicesFree.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

ServicesFree.propTypes = {
  rootClassName: string,
  className: string,
};

export default ServicesFree;
