import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { FormattedMessage } from '../../util/reactIntl';
import { LISTING_STATE_DRAFT } from '../../util/types';
import { ensureOwnListing } from '../../util/data';
import { ListingLink } from '../../components';
import { EditListingPoliciesForm } from '../../forms';
import { findOptionsForSelectFilter } from '../../util/search';

import css from './EditListingPoliciesPanel.module.css';
import { localizeFilters } from '../../util/filtersLocalizationHelpers';

const FEATURES_NAME = 'pregunta';
const EditListingPoliciesPanel = props => {
  const {
    className,
    rootClassName,
    listing,
    onSubmit,
    onChange,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    errors,
    intl,
  } = props;

  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureOwnListing(listing);
  const { publicData } = currentListing.attributes;

  const isPublished = currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;
  const panelTitle = isPublished ? (
    <FormattedMessage
      id="EditListingPoliciesPanel.createListingTitle"
      values={{ listingTitle: <ListingLink listing={listing} /> }}
    />
  ) : (
    <FormattedMessage id="EditListingPoliciesPanel.createListingTitle" />
  );
  const a = [{
    id: 'info_adicional',
    label: 'Info Adicional y Docuemntación',
    type: '',
    group: '',
    queryParamNames: ['pub_info_adicional'],
    config: {
      options: [
        { key: 'info_adicional1', label: 'Como empresa' },
        { key: 'info_adicional2', label: 'Como autónomo' },
        { key: 'info_adicional3', label: 'Como particular' },
      ],
    },
  }]

  const infoAdicionalOptions = localizeFilters(findOptionsForSelectFilter('info_adicional', a), intl);

  return (
    <div className={classes}>
      <h1 className={css.title}>{panelTitle}</h1>
      <EditListingPoliciesForm
        className={css.form}
        name={FEATURES_NAME}
        publicData={publicData}
        initialValues={{ info_adicional: publicData.info_adicional, pregunta: publicData.pregunta }}
        onSubmit={values => {
          const { info_adicional, pregunta } = values;
          const updateValues = {
            publicData: {
              info_adicional,
              pregunta
            },
          };
          onSubmit(updateValues);
        }}
        onChange={onChange}
        saveActionMsg={submitButtonText}
        updated={panelUpdated}
        updateError={errors.updateListingError}
        updateInProgress={updateInProgress}

        infoAdicionalOptions={infoAdicionalOptions}
      />
    </div>
  );
};

const { func, object, string, bool } = PropTypes;

EditListingPoliciesPanel.defaultProps = {
  className: null,
  rootClassName: null,
  listing: null,
};

EditListingPoliciesPanel.propTypes = {
  className: string,
  rootClassName: string,
  listing: object,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};

export default EditListingPoliciesPanel;
