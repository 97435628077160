import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './CommunicationSafety.module.css';

const CommunicationSafetyEnglish = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  // prettier-ignore
  return (
    <div className={classes}>
      <p className={css.titleRefund}>Why We Review Messages on ÛUPO</p>

      <p>
        At ÛUPO, the safety of our users, both ÛUPERs and ÛUPOs, is our top priority. That's why we pay special attention to how the members of our community communicate.
      </p>

      <p>
        We may review, examine, or analyze the messages you send or receive through the ÛUPO platform. We do this for several reasons, including:
      </p>

      <ul className={css.listRefund}>
        <li>Ensuring the proper functioning of our services.</li>
        <li>Detecting potential risks to our users.</li>
        <li>Ensuring compliance with applicable regulations.</li>
        <li>Investigating possible violations.</li>
        <li>Enforcing our Terms of Service and policies, including our Inclusion Policy.</li>
        <li>Providing customer support.</li>
        <li>Conducting research and analysis.</li>
        <li>Improving or expanding our products and services.</li>
      </ul>

      <p className={css.titleRefund}>
        Keep Your Conversations on ÛUPO
      </p>

      <p>Communicating or making payments outside our platform may involve certain risks. One of the main reasons we review messages is to protect community members from potential online fraud. For this reason, we recommend that you only communicate through the ÛUPO message thread until you have a conﬁrmed booking.</p>

      <p>By communicating and booking through ÛUPO, you'll beneﬁt from the security of our safe payment platform, access to customer support, and our ÛUPOs and payment protection.</p>

      <p>In some cases, our system may block messages that contain information including contact details, references to other platforms, or external links, to ensure the safety of everyone.</p>

      <p><strong>Your safety is important to us</strong>, but we also respect your privacy. We will never review or analyze your messages for advertising purposes, nor will we share your communications with third parties.</p>
    </div>
  );

};

CommunicationSafetyEnglish.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

CommunicationSafetyEnglish.propTypes = {
  rootClassName: string,
  className: string,
};

export default CommunicationSafetyEnglish;
