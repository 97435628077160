import React from 'react';
import { arrayOf, bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import classNames from 'classnames';

import {
  intlShape,
  injectIntl,
  FormattedMessage,
} from '../../util/reactIntl';
import { findOptionsForSelectFilter } from '../../util/search';
import { propTypes } from '../../util/types';
import { required } from '../../util/validators';
import config from '../../config';
import { Form, Button, FieldCheckboxGroup, FieldSelect } from '../../components';

import css from './EditListingPoliciesForm.module.css';
import { localizeFilters } from '../../util/filtersLocalizationHelpers';

export const EditListingPoliciesFormComponent = props => (
  <FinalForm
    {...props}
    mutators={{ ...arrayMutators }}
    render={formRenderProps => {
      const {
        className,
        name,
        disabled,
        handleSubmit,
        intl,
        invalid,
        pristine,
        saveActionMsg,
        updated,
        updateError,
        updateInProgress,
        infoAdicionalOptions,
      } = formRenderProps;

      const infoAdicionalPlaceholder = intl.formatMessage({
        id: 'EditListingCategoriaForm.infoAdicionalPlaceholder',
      });

      const errorMessage = updateError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingCategoriaForm.updateFailed" />
        </p>
      ) : null;

      const infoAdicionalRequired = required(
        intl.formatMessage({
          id: 'EditListingCategoriaForm.infoAdicionalRequired',
        })
      );

      const classes = classNames(css.root, className);
      const submitReady = updated && pristine;
      const submitInProgress = updateInProgress;
      const submitDisabled = invalid || disabled || submitInProgress;
      const a = [{
        id: 'pregunta',
        label: '¿Tu ÛUPO tiene alguno de estos?',
        type: '',
        group: '',
        queryParamNames: ['pub_amenities'],
        config: {
          options: [
            {
              key: 'camaras_de_seguridad',
              label: 'Cámaras de seguridad',
            },
            {
              key: 'sustancias_peligrosas',
              label: 'Sustancias peligrosas',
            },
            {
              key: 'armas',
              label: 'Armas',
            },
            {
              key: 'animales_peligrosos',
              label: 'Animales peligrosos',
            },
          ]
        },
      }]
      const options = localizeFilters(findOptionsForSelectFilter('pregunta', a), intl);

      return (
        <Form
          className={classes}
          onSubmit={handleSubmit}
        >
          {errorMessage}
          <h3 className={css.title}>
            <FormattedMessage id="EditListingPoliciesForm.infoAdditionalLabel" />
          </h3>
          <FieldSelect
            className={css.info_adicional}
            name="info_adicional"
            id="info_adicional"
            validate={infoAdicionalRequired}
          >
            <option value="">{infoAdicionalPlaceholder}</option>
            {infoAdicionalOptions.map(c => (
              <option key={c.key} value={c.key}>
                {c.label}
              </option>
            ))}
          </FieldSelect>
          <br />
          <h3 className={css.title}>
            <FormattedMessage id="EditListingPoliciesForm.contentLabel" />
          </h3>
          <FieldCheckboxGroup id={name} name={name} options={options} />
          <Button
            className={css.submitButton}
            type="submit"
            inProgress={submitInProgress}
            disabled={submitDisabled}
            ready={submitReady}
          >
            {saveActionMsg}
          </Button>
        </Form>
      );
    }}
  />
);

EditListingPoliciesFormComponent.defaultProps = {
  selectedPlace: null,
  updateError: null,
};

EditListingPoliciesFormComponent.propTypes = {
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  updated: bool.isRequired,
  updateError: propTypes.error,
  updateInProgress: bool.isRequired,
  infoAdicionalOptions: arrayOf(
    shape({
      key: string.isRequired,
      label: string.isRequired,
    })
  ).isRequired,
};

export default compose(injectIntl)(EditListingPoliciesFormComponent);
