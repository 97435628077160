import React, { Component, useEffect, useState } from 'react';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { NamedLink } from '../../components';
import { lazyLoadWithDimensions } from '../../util/contextHelpers';
import { createSlug } from '../../util/urlHelpers';
import css from './SectionOne.module.css';

class LocationImage extends Component {
  render() {
    const { alt, ...rest } = this.props;
    return <img alt={alt} {...rest} />;
  }
}

const LazyImage = lazyLoadWithDimensions(LocationImage);

const SectionOne = props => {

  const [mounted, setMounted] = useState(false);
  const { rootClassName, className, latestListings, msg = '123' } = props;

  useEffect(() => {
    setMounted(true);
  }, []);

  const classes = classNames(rootClassName || css.root, className)
  return (
    <>
      <div className={classes}>
        <div className={css.contailerTitle}>
          <div className={css.triangulo}></div>
          <div className={classNames(css.title, { [css.titleFEDelay]: mounted })}>
            <FormattedMessage id="SectionLocations.title" />
          </div>
          <p className={classNames(css.subtitle, { [css.subtitleFEDelay]: mounted })}>
            <FormattedMessage id="SectionLocations.subtitle1" /> <br />
            <FormattedMessage id="SectionLocations.subtitle2" />
            <span className={css.line}></span>
          </p>
        </div>
        <div className={classNames(css.containerCard, { [css.bgContainerCard]: latestListings.length < 4 })}>
          <div className={css.locations}>
            {latestListings.map(l => {
              const slug = createSlug(l.attributes.title);
              const id = l.id.uuid;
              return (
                <NamedLink name="ListingPage" params={{ id, slug }} className={css.location} key={l.id.uuid}>
                  <div className={css.imageWrapper}>
                    <div className={css.aspectWrapper}>
                      <LazyImage src={l.images[0].attributes.variants['landscape-crop2x'].url} alt={l.attributes.title} className={css.locationImage} />
                    </div>
                  </div>
                </NamedLink>
              )
            })
            }
          </div>
          <NamedLink
            name="SearchPage" to={{ search: '' }}
            className={classNames(css.oneButton, { [css.oneButtonFEDelay]: mounted })}
          >
            <FormattedMessage id={msg} />
          </NamedLink>
        </div>
      </div>
    </>
  )
};

export default SectionOne;
