import React from "react";
import { compose } from "redux";
import { withViewport } from "../../util/contextHelpers";
import { FormattedMessage, injectIntl, useIntl } from '../../util/reactIntl';
import NamedLink from "../NamedLink/NamedLink";

import css from './SectionOneNew.module.css';

const MAX_MOBILE_SCREEN_WIDTH = 768;

const SectionOneNewComponent = props => {
    const isMobileLayout = props.viewport.width < MAX_MOBILE_SCREEN_WIDTH;
    const intl = useIntl();

    const isEnglish = intl.locale == "en-UK"

    return (
        <main className={css.root}>
            <h2 className={css.mainTtitle}>
                <FormattedMessage id="SectionOneNew.mainTitle" />
                <span className={css.line} style={isEnglish && !isMobileLayout ? { marginRight: -5 } : null} ></span>
            </h2>
            <div className={css.container}>
                <div className={css.card}>
                    <h2 className={css.title}>
                        <FormattedMessage id="SectionOneNew.card1.title" />
                    </h2>
                    <p className={css.text}>
                        <FormattedMessage id="SectionOneNew.card1.text" />
                    </p>
                    <div>
                        {/* <p className={css.text}>
                            <FormattedMessage id="SectionOneNew.card1.help" />
                        </p> */}
                        {/* <a href="https://calendly.com/uupo/publicatuescaparatepopup" className={css.link}> */}
                        <a href={intl.formatMessage({ id: 'SectionOneNew.card1.link' })} className={css.link}>
                            <FormattedMessage id="SectionOneNew.card1.help" />
                            {/* <FormattedMessage id="SectionOneNew.card1.link" /> */}
                        </a>
                    </div>
                </div>
                <div className={css.card}>
                    <h2 className={css.title}>
                        <FormattedMessage id="SectionOneNew.card2.title" />
                    </h2>
                    <p className={css.text}>
                        <FormattedMessage id="SectionOneNew.card2.text" />
                    </p>
                </div>
                <div className={css.card}>
                    <h2 className={css.title}>
                        <FormattedMessage id="SectionOneNew.card3.title" />
                    </h2>
                    <p className={css.text}>
                        <FormattedMessage id="SectionOneNew.card3.text" />
                    </p>
                </div>
            </div>
            <div className={css.butonContainer}>
                <h3 style={{ margin: 0 }}>
                    <NamedLink
                        name="SignupPage"
                        className={css.button}
                    >
                        <FormattedMessage id="SectionOneNew.button" />
                    </NamedLink>
                </h3>
            </div>
        </main>
    );
};

const SectionOneNew = compose(
    withViewport,
    injectIntl
)(SectionOneNewComponent);

export default SectionOneNew;