import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './ServicesFree.module.css';

const ServicesFreeEnglish = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  // prettier-ignore
  return (
    <div className={classes}>
      <p>To help the ÛUPO.com platform run smoothly and cover the cost of services such as Customer Service (available 24/7), ÛUPOCOWORKING S.L.L. will apply a fee when a reservation is confirmed.</p>
      <p>There is a general fee structure for Pop Up reservations:</p>
      <p className={css.subtitleRefund}>Shared fee</p>
      <p>This option splits the fee between the ÛUPO and the ÛUPER.</p>
      <p className={css.subtitleRefund}>ÛUPO fee</p>
      <p>ÛUPOs pay a 3% fee</p>
      <p>We calculate this service fee based on the reservation subtotal (daily price + cleaning fee + additional fee, if applicable, excluding UUPOCOWORKING S.L.L commissions and taxes) and automatically deduct it from the ÛUPO's payment.</p>
      <p className={css.subtitleRefund}>ÛUPER fee</p>
      <p>This fee varies based on various factors and is displayed on the payment page before requesting the reservation so you know the corresponding amount. Most service fees that ÛUPERs must pay are less than 14.53% of the reservation subtotal (daily price + cleaning fee + additional fee, if applicable, excluding UUPOCOWORKING S.L.L commissions and taxes).</p>
      <p className={css.subtitleRefund}>VAT</p>
      <p>When applicable, VAT will be added to the service fee.</p>
      <p>We reserve the right to modify our service fees at any time. We will notify you of any fee changes before they take effect. Fee changes will not affect reservations made before their effective date.</p>
      <p className={css.textItalic}>CLARIFICATION NOTE REGARDING TAXES. Indirect tax on operations in the Canary Islands, Ceuta, and Melilla. Service provisions between ÛUPOs and ÛUPERs when the property where coworking takes place is located in one of these territories will be subject to special rules for determining the application of the corresponding indirect tax.</p>
      <p className={css.textItalic}>Operations to be carried out between ÛUPO and ÛUPER will be considered coworking service provisions since, in addition to renting the property, the ÛUPO will provide the ÛUPER with a series of ancillary services necessary for the latter to carry out their economic activity. In this context, the ÛUPER will not be obliged to practice withholding of IRPF for property rental.</p>
    </div>
  );
};

ServicesFreeEnglish.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

ServicesFreeEnglish.propTypes = {
  rootClassName: string,
  className: string,
};

export default ServicesFreeEnglish;
