import React from 'react';
import { array, bool, func, object, string } from 'prop-types';
import classNames from 'classnames';
//import { FormattedMessage } from '../../util/reactIntl';
import { ensureOwnListing } from '../../util/data';
import { LISTING_STATE_DRAFT } from '../../util/types';
import { ListingLink } from '../../components';
import { EditListingDescriptionForm } from '../../forms';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';

import css from './EditListingDescriptionPanel.module.css';

const EditListingDescriptionPanel = props => {
  const {
    className,
    rootClassName,
    errors,
    disabled,
    ready,
    images,
    listing,
    onImageUpload,
    onUpdateImageOrder,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    onChange,
    onSubmit,
    onRemoveImage,
    intl
  } = props;

  const rootClass = rootClassName || css.root;
  const classes = classNames(rootClass, className);
  const currentListing = ensureOwnListing(listing);

  const isPublished = currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;

  const locale = intl.locale; // Extract the current locale

  const panelTitle = isPublished ? (
    <FormattedMessage
      id="EditListingDescriptionPanel.createListingTitle"
      values={{ listingTitle: <ListingLink listing={listing} /> }}
    />
  ) : (
    <FormattedMessage id="EditListingDescriptionPanel.createListingTitle" />
  );

  return (
    <div className={classes}>
      <h1 className={css.title}>{panelTitle}</h1>
      {/* <p className={css.subtitle}>
        <FormattedMessage id="EditListingDescriptionPanel.addImagesTip1" />
      </p> */}
      {/* <ul>
        <li className={css.subtitle}><FormattedMessage id="EditListingDescriptionPanel.addImagesTip2" /></li>
        <li className={css.subtitle}><FormattedMessage id="EditListingDescriptionPanel.addImagesTip3" /></li>
        <li className={css.subtitle}><FormattedMessage id="EditListingDescriptionPanel.addImagesTip4" /></li>
        <li className={css.subtitle}><FormattedMessage id="EditListingDescriptionPanel.addImagesTip5" /></li>
      </ul> */}
      {/* <p><FormattedMessage id="PublishPopUp.thankYou" /></p> */}
      <p>
        <FormattedMessage id="PublishPopUp.reviewInfo" />
      </p>
      <p><FormattedMessage id="PublishPopUp.thankYouAgain" /></p>

      <EditListingDescriptionForm
        className={css.form}
        disabled={disabled}
        ready={ready}
        fetchErrors={errors}
        initialValues={{ images }}
        images={images}
        onImageUpload={onImageUpload}
        onSubmit={values => {

          const updateValues = {
            images: values.docs
          }

          onSubmit(updateValues);
        }}
        onChange={onChange}
        onUpdateImageOrder={onUpdateImageOrder}
        onRemoveImage={onRemoveImage}
        saveActionMsg={submitButtonText}
        updated={panelUpdated}
        updateInProgress={updateInProgress}
      />
    </div>
  );
};

EditListingDescriptionPanel.defaultProps = {
  className: null,
  rootClassName: null,
  errors: null,
  listing: null,
};

EditListingDescriptionPanel.propTypes = {
  className: string,
  rootClassName: string,
  errors: object,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  images: array,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  onImageUpload: func.isRequired,
  onUpdateImageOrder: func.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  onRemoveImage: func.isRequired,
};

export default EditListingDescriptionPanel;
