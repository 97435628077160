import React, { memo, useRef } from 'react';
import { Field } from 'react-final-form';
import ValidationError from '../ValidationError/ValidationError';
import css from './FieldCodeInput.module.css';

const CodeInput = memo(({ input: { onChange, onFocus, onBlur, value }, label, customErrorText, meta, className, ...rest }) => {

    const { valid, invalid, touched, error } = meta;
    const errorText = customErrorText || error;
    const hasError = !!customErrorText || !!(touched && invalid && error);

    const fieldMeta = { touched: hasError, error: errorText };

    const inputsRef = useRef([]);

    const focusNextInput = (e, index) => {
        if (e.target.value.length > 1) {
            e.target.value = e.target.value.slice(0, 1);
        };

        if (index < 5) {
            inputsRef.current[index + 1].focus();
        }

        let val = '';
        for (let i = 0; i <= index; i++) {
            val += inputsRef.current[i].value;
        }

        onChange(val);
    }

    const focusPrevInput = (e, index) => {
        if (index > 0) {
            inputsRef.current[index - 1].focus();
        }
    }

    const handleOnKeyDown = (e, index) => {
        if (e.key === 'Backspace') {
            if (e.target.value.length > 0) {

            } else {
                focusPrevInput(e, index);
            };
        } else if (e.key === 'Delete') {
            console.log("Delete")
            e.target.value = undefined;
        }

        let val = '';
        for (let i = 0; i <= index; i++) {
            val += inputsRef.current[i].value;
        }
        onChange(val);
    }

    const handlePaste = (e) => {
        const pastedData = e.clipboardData.getData('text');
        if (pastedData.length === 6 && !isNaN(pastedData)) {
            const splitValidationCode = pastedData.split('');

            inputsRef.current[5].focus();
            for (let i = 0; i <= 5; i++) {
                inputsRef.current[i].value = splitValidationCode[i];
            }

        }
    };

    return (
        <div className={className}>
            <label className={css.label}>{label}</label>
            <div tabindex="0" className={css.inputContainer} onPaste={handlePaste} onFocus={(event) => onFocus(event)} onBlur={(event) => onBlur(event)} >
                {[...Array(6)].map((_, i) => (
                    <input key={i}
                        autoFocus={i === 0}
                        className={css.input}
                        type="number"
                        min={0}
                        max={9}
                        placeholder='0'
                        ref={(el) => inputsRef.current[i] = el}
                        onChange={(e) => focusNextInput(e, i)}
                        onFocus={(e) => {
                            if (e.target.value) e.target.value = ''
                        }}
                        onKeyDown={(e) => handleOnKeyDown(e, i)}
                    />
                ))}
            </div>
            <ValidationError fieldMeta={fieldMeta} />
        </div>
    );
});

const FieldCodeInput = props => {
    const { name, id, label } = props;
    return <Field name={name} label={label} component={CodeInput} {...props} />;
};

export default FieldCodeInput