import React, { Component, useEffect, useState } from 'react';
import { string } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { NamedLink } from '../../components';

import css from './SectionHero.module.css';
import arrow from '../../assets/arrow.png';

const SectionHero = props => {
  const [mounted, setMounted] = useState(false);
  const { rootClassName, className, isAuthenticated } = props;

  
  useEffect(() => {
    setMounted(true);
  }, []);

  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={`${css.heroContent} ${classes}`}>
      <div className={css.contentText}>
        <div className={css.containerArrow}>
          <img className={css.arrowLeft} src={arrow} alt="arrow" />
        </div>
        <div className={css.containerText}>
          <h1 className={classNames(css.heroMainTitle, { [css.heroMainTitleFEDelay]: mounted })}>
            <FormattedMessage id="SectionHero.title" /> <br />
            {/* <FormattedMessage id="SectionHero.title2" /> */}
          </h1>
          <h2 className={classNames(css.heroSubTitle, { [css.heroSubTitleFEDelay]: mounted })}>
            <FormattedMessage id="SectionHero.subTitle1" />
          </h2>
          {isAuthenticated ? (
            <NamedLink
              name="NewListingPage"
              className={classNames(css.heroButton, { [css.heroButtonFEDelay]: mounted })}
            >
              <FormattedMessage id="SectionHero.browseButton" />
            </NamedLink>
          ) : (
            <NamedLink
              name="SignupPage"
              className={classNames(css.heroButton, { [css.heroButtonFEDelay]: mounted })}
            >
              <FormattedMessage id="SectionHero.browseButton" />
            </NamedLink>
          )}
          {/* <NamedLink
              name="LandingUuper"
              className={classNames(css.heroButton2, { [css.heroButton2FEDelay]: mounted })}
            >
              <FormattedMessage id="SectionHero.browseButton2" />
            </NamedLink> */}
          {/* <NamedLink
            name="SignupPage"
            className={classNames(css.heroButton, { [css.heroButtonFEDelay]: mounted })}
          >
            <FormattedMessage id="SectionHero.browseButton" />
          </NamedLink> */}
          
        </div>
      </div>
    </div>
  );
};

SectionHero.defaultProps = { rootClassName: null, className: null, isAuthenticated: false };

SectionHero.propTypes = {
  rootClassName: string,
  className: string,
  isAuthenticated: Boolean,
};

export default SectionHero;
