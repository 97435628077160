import React from "react";
import css from './SectionThreeNew.module.css';
import { FormattedMessage } from '../../util/reactIntl';

import AddresCard from './images/address-card.svg';
import Headset from './images/headset.svg';
import SackDollar from './images/sack-dollar.svg';


const SectionThreeNew = props => {
    return (
        <main className={css.root}>
            <p className={css.mainTtitle}>
                <FormattedMessage id="SectionThreeNew.mainTitle" />
            </p>
            <div className={css.container}>
                <div className={css.card}>
                    <img src={AddresCard} className={css.image} />
                    <h3 className={css.title}>
                        <FormattedMessage id="SectionThreeNew.card1.title" />
                    </h3>
                    <p className={css.text}>
                        <FormattedMessage id="SectionThreeNew.card1.text" />
                    </p>
                </div>
                <div className={css.card}>
                    <img src={Headset} className={css.image} />
                    <h3 className={css.title}>
                        <FormattedMessage id="SectionThreeNew.card2.title" />
                    </h3>
                    <p className={css.text}>
                        <FormattedMessage id="SectionThreeNew.card2.text" />
                    </p>
                </div>
                <div className={css.card}>
                    <img src={SackDollar} className={css.image} />
                    <h3 className={css.title}>
                        <FormattedMessage id="SectionThreeNew.card3.title" />
                    </h3>
                    <p className={css.text}>
                        <FormattedMessage id="SectionThreeNew.card3.text" />
                    </p>
                </div>
            </div>
        </main>
    );
};

export default SectionThreeNew;