/**
 * Renders non-reversal line items that are not listed in the
 * `LINE_ITEMS` array in util/types.js
 *
 * The line items are rendered so that the line item code is formatted to human
 * readable form and the line total is printed as price.
 *
 * If you require another kind of presentation for your line items, add them to
 * the `LINE_ITEMS` array in util/types.js and create a specific line item
 * component for them that can be used in the `BookingBreakdown` component.
 */
import React, { useState } from 'react';
import { FormattedDate, FormattedMessage, intlShape } from '../../util/reactIntl';
import { formatMoney } from '../../util/currency';
import { humanizeLineItemCode, localizedLineItemCode, onlyLineItemCode } from '../../util/data';
import { LINE_ITEMS, propTypes } from '../../util/types';
const { types } = require('sharetribe-flex-sdk');
const { Money } = types;

import img2 from '../../assets/helpVerde.png';
import css from './BookingBreakdown.module.css';

const LineItemUnknownItemsMaybe = props => {
  const { transaction, isProvider, unitType, intl } = props;

  const [showHelp, setShowHelp] = useState(false);
  // resolve unknown non-reversal line items
  const allItems = transaction.attributes.lineItems.filter(
    item => LINE_ITEMS.indexOf(item.code) === -1 && !item.reversal
  );

  const items = isProvider
    ? allItems.filter(item => item.includeFor.includes('provider'))
    : allItems.filter(item => item.includeFor.includes('customer'));
  let subtotal = 0
  const unitPurchase = transaction.attributes.lineItems.find(
    item => item.code === unitType && !item.reversal
  );

  if (items.length > 0) {
    const a = items.reduce((partialSum, item) => {
      return partialSum + item.lineTotal.amount
    }, unitPurchase.lineTotal.amount);

    subtotal = formatMoney(intl, new Money(a - items[items.length - 1].lineTotal.amount, 'EUR'))
  }
  const position = isProvider ? 3 : 2;
  return items.length > 0 ? (
    <React.Fragment>
      {items.map((item, i) => {
        const quantity = item.quantity;

        const label =
          quantity && quantity > 1
            ? `${localizedLineItemCode(item.code, intl)} x ${quantity}`
            : localizedLineItemCode(item.code, intl);

        const formattedTotal = formatMoney(intl, item.lineTotal);

        return (
          <div key={`${i}-item.code`}>
            <hr className={css.totalDivider} />

            {(i == 2) ? (
              <>
                <div className={css.lineItem}>
                  <div>
                    <span className={css.itemLabel}>{label}</span>
                    <span
                      onMouseEnter={() => setShowHelp(true)}
                      onMouseLeave={() => setShowHelp(false)}
                      style={{ marginLeft: ".4rem", position: "relative" }}
                    >
                      <img src={img2} style={{ marginBottom: "1rem" }} />
                    </span>
                    {showHelp && (
                      <div style={{ transition: "opacity 0.1s ease", fontSize: "12px", position: "absolute", background: "#f5f5f5", color: "#4b4b4b", padding: "8px", maxWidth: "716px" }}>
                        <div style={{ width: "0", height: "0", borderRight: "10px solid transparent", borderTop: "10px solid transparent", borderLeft: "10px solid transparent", borderBottom: "10px solid #F5F5F5", position: "absolute", top: "0", transform: "translateY(-90%)", left: "21%", }}></div>
                        <span>
                          <FormattedMessage id="LineItem.tarifa-de-servicio.help" />
                        </span>
                      </div>
                    )}
                  </div>
                  <span className={css.itemValue}>{formattedTotal}</span>
                </div>
              </>
            ) : (
              <>
                <div className={css.lineItem}>
                  <span className={css.itemLabel}>{label}</span>
                  <span className={css.itemValue}>{formattedTotal}</span>
                </div>
              </>
            )}

            {(i == position) && (
              <>
                <hr className={css.totalDivider} />
                <div className={css.lineItem}>
                  <span className={css.itemLabel}>{(i == 2) ? ('Subtotal') : ('Total')}</span>
                  <span className={css.itemValue}>{subtotal}</span>
                </div>
              </>
            )}
          </div>
        );
      })}
    </React.Fragment>
  ) : null;
};

LineItemUnknownItemsMaybe.propTypes = {
  transaction: propTypes.transaction.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemUnknownItemsMaybe;
