import React, { Component, useEffect, useState } from 'react';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import img1 from '../../assets/landingUppo/img1.webp';
import img2 from '../../assets/landingUppo/img2.webp';
import img3 from '../../assets/landingUppo/img3.webp';
import css from './SectionOneUupo.module.css';
import NamedLink from '../NamedLink/NamedLink';

const SectionOneUupo = props => {

  const [mounted, setMounted] = useState(false);
  const { rootClassName, className, latestListings } = props;

  useEffect(() => {
    setMounted(true);
  }, []);

  const classes = classNames(rootClassName || css.root, className)

  return (
    <>
      <div className={classes}>
        <div className={css.contailerTitle}>
          <div className={css.triangulo}></div>
          <h2 className={classNames(css.subtitle, { [css.subtitleFEDelay]: mounted })}>
            <FormattedMessage id="SectionOneUupo.title" />
            <span className={css.line}></span>
          </h2>
        </div>
        <div className={css.containerCard}>
          <div className={css.locations}>
            <img src={img1} className={css.location} />
            <img src={img2} className={css.location} />
            <img src={img3} className={css.location} />
          </div>
        </div>

        <div className={css.butonContainer}>
          <h2 style={{ margin: 0 }}>
            <NamedLink
              name="SearchPage"
              className={css.button}
            >
              <FormattedMessage id="SectionOneUupo.button" />
            </NamedLink>
          </h2>
        </div>
      </div>
    </>
  )
};

export default SectionOneUupo;