import React, { useState } from 'react';
import { arrayOf, bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import img1 from '../../assets/help.png';
import img2 from '../../assets/helpVerde.png';
import classNames from 'classnames';

// These relative imports need to point to correct directories
import {
  intlShape,
  injectIntl,
  FormattedMessage,
} from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import { required } from '../../util/validators';
import { Form, Button, FieldSelect, FieldRadioButton } from '../../components';
import css from './EditListingFeaturesForm.module.css';

const EditListingFeaturesFormComponent = props => (
  <FinalForm
    {...props}
    render={formRenderProps => {
      const {
        className,
        disabled,
        handleSubmit,
        intl,
        invalid,
        pristine,
        saveActionMsg,
        updated,
        updateError,
        updateInProgress,
        denominacionOptions,
        orientacionOptions,
        initialValues,
      } = formRenderProps;

      let denominacionInitial = ''
      if (initialValues.denominacion !== undefined) {
        const a = denominacionOptions.find((d) => {
          return d.key === initialValues.denominacion
        })
        denominacionInitial = a.help
      }

      const [DenominacionHelp, setDenominacionHelp] = useState(denominacionInitial);

      let orientacionInitial = ''
      if (initialValues.orientacion !== undefined) {
        const a = orientacionOptions.find((d) => {
          return d.key === initialValues.orientacion
        })
        orientacionInitial = a.help
      }

      const [OrientacionHelp, setOrientacionHelp] = useState(orientacionInitial);
      const [showHelp1, setShowHelp1] = useState(false);
      const [showHelp2, setShowHelp2] = useState(false);

      const showAsRequired = pristine && required;

      const denominacionPlaceholder = intl.formatMessage({
        id: 'EditListingFeaturesForm.denominacionPlaceholder',
      });
      const orientacionPlaceholder = intl.formatMessage({
        id: 'EditListingFeaturesForm.orientacionPlaceholder',
      });

      const denominacionRequired = required(
        intl.formatMessage({
          id: 'EditListingFeaturesForm.denominacionIsRequired',
        })
      );

      const orientacionRequired = required(
        intl.formatMessage({
          id: 'EditListingFeaturesForm.orientacionIsRequired',
        })
      );

      const errorMessage = updateError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingFeaturesForm.updateFailed" />
        </p>
      ) : null;

      const classes = classNames(css.root, className);
      const submitReady = updated && pristine;
      const submitInProgress = updateInProgress;
      const submitDisabled = invalid || disabled || submitInProgress;

      function handleChangeHelp(event) {
        if (event.target.value == '') {
          setDenominacionHelp('')
        } else {
          const a = denominacionOptions.find((d) => {
            return d.key === event.target.value
          })
          setDenominacionHelp(a.help)
        }
      }

      function handleChangeHelp2(event) {
        if (event.target.value == '') {
          setOrientacionHelp('')
        } else {
          const a = orientacionOptions.find((d) => {
            return d.key === event.target.value
          })
          setOrientacionHelp(a.help)
        }
      }

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          {errorMessage}

          <h3 className={css.title}>
            <FormattedMessage id="EditListingFeaturesForm.designation" />
          </h3>
          <div>
            <FieldSelect
              className={css.denominacion}
              name="denominacion"
              id="denominacion"
              validate={denominacionRequired}
              handleinputchange={handleChangeHelp}
            >
              <option value="">{denominacionPlaceholder}</option>
              {denominacionOptions.map(c => (
                <option key={c.key} value={c.key}>
                  {c.label}
                </option>
              ))}
            </FieldSelect>

            {(DenominacionHelp) && <div className={css.textHelp}>
              <img src={img1} className={css.imageIcon} />
              <span>
                {DenominacionHelp}
              </span>
            </div>}

          </div>
          <br />
          <h3 className={css.title}>
            <FormattedMessage id="Filters.orientacion" />
          </h3>
          <FieldSelect
            className={css.orientacion}
            name="orientacion"
            id="orientacion"
            validate={orientacionRequired}
            handleinputchange={handleChangeHelp2}
          >
            <option value="">{orientacionPlaceholder}</option>
            {orientacionOptions.map(c => (
              <option key={c.key} value={c.key}>
                {c.label}
              </option>
            ))}
          </FieldSelect>
          {(OrientacionHelp) && <div className={css.textHelp}>
            <img src={img1} className={css.imageIcon} />
            <span>
              {OrientacionHelp}
            </span>
          </div>}
          <br />
          <div className={css.sectionContainer}>
            <h3 className={css.title}>
              <FormattedMessage id="Filters.altura" />
            </h3>
            <div className={css.radioButtonRow}>
              <div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <FieldRadioButton
                    id="elevado"
                    name="altura"
                    label={intl.formatMessage({ id: "Filters.altura_true" })}
                    value="true"
                    showAsRequired={showAsRequired}
                  />
                  <span
                    onMouseEnter={() => setShowHelp1(true)}
                    onMouseLeave={() => setShowHelp1(false)}
                    style={{ marginLeft: ".4rem", position: "relative" }}
                  >
                    <img src={img2} className={css.image} />
                  </span>
                </div>

                {showHelp1 && (
                  <div style={{ transition: "opacity 0.9s ease", fontSize: "12px", position: "absolute", background: "#f5f5f5", color: "#4b4b4b", padding: "8px", maxWidth: "716px" }}>
                    <div style={{ width: "0", height: "0", borderRight: "10px solid transparent", borderTop: "10px solid transparent", borderLeft: "10px solid transparent", borderBottom: "10px solid #F5F5F5", position: "absolute", top: "0", transform: "translateY(-90%)", left: "13%", }}></div>
                    <span>
                      <FormattedMessage id="Filters.altura_true.info" />
                    </span>
                  </div>
                )}
              </div>

              <div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <FieldRadioButton
                    id="NoElevado"
                    name="altura"
                    label={intl.formatMessage({ id: "Filters.altura_false" })}
                    value="false"
                    showAsRequired={showAsRequired}
                  />
                  <span
                    onMouseEnter={() => setShowHelp2(true)}
                    onMouseLeave={() => setShowHelp2(false)}
                    style={{ marginLeft: ".4rem", position: "relative" }}
                  >
                    <img src={img2} className={css.image} />
                  </span>
                </div>
                {showHelp2 && (
                  <div style={{ transition: "opacity 0.1s ease", fontSize: "12px", position: "absolute", background: "#f5f5f5", color: "#4b4b4b", padding: "8px", maxWidth: "716px" }}>
                    <div style={{ width: "0", height: "0", borderRight: "10px solid transparent", borderTop: "10px solid transparent", borderLeft: "10px solid transparent", borderBottom: "10px solid #F5F5F5", position: "absolute", top: "0", transform: "translateY(-90%)", left: "21%", }}></div>
                    <span>
                      <FormattedMessage id="Filters.altura_false.info" />
                    </span>
                  </div>
                )}
              </div>
            </div>
          </div>
          <Button
            className={css.submitButton}
            type="submit"
            inProgress={submitInProgress}
            disabled={submitDisabled}
            ready={submitReady}
          >
            {saveActionMsg}
          </Button>
        </Form>
      );
    }}
  />
);

EditListingFeaturesFormComponent.defaultProps = {
  selectedPlace: null,
  updateError: null,
};

EditListingFeaturesFormComponent.propTypes = {
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  updated: bool.isRequired,
  updateError: propTypes.error,
  updateInProgress: bool.isRequired,
  denominacionOptions: arrayOf(
    shape({
      key: string.isRequired,
      label: string.isRequired,
    })
  ).isRequired,
  orientacionOptions: arrayOf(
    shape({
      key: string.isRequired,
      label: string.isRequired,
    })
  ).isRequired,
};

export default compose(injectIntl)(EditListingFeaturesFormComponent);
