import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './TermsOfService.module.css';

const TermsOfServiceEnglish = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  // prettier-ignore
  return (
    <div className={classes}>
      <p className={css.lastUpdated}>UUPOCOWORKING S.L.L. is the owner of the ÛUPO platform, CIF: B72446073, registered in the Mercantile Registry of Asturias (Spain) on 14/09/2022. Registration 1st, Volume 4511, Folio 59, Sheet 59861.</p>

      <p>
        To contact us or make a claim, we offer various contact methods found in our Customer Service section, email: <a href="mailto:hola@uupo.es" className={css.enlaceEmail}>hola@uupo.es</a>
      </p>

      <p>
        The language of the terms and conditions is Spanish without prejudice to its later translation into English, French, or any other language incorporated by the platform's promoters.
      </p>

      <p className={css.titleRefund}>
        Terms
      </p>
      
      <p>
        In this section, we will refer to the terms that will appear on ÛUPO.com and that will define the offer of UUPOCOWORKING SLL, regarding the particularities of users and the designation of the figures and professional relationships that make it possible.
      </p>

      <ul className={css.listRefund}>
        <li><strong>UUPOCOWORKING S.L.L. </strong>refers to the company's legal name.</li>
        <li><strong>ÛUPO.COM </strong>is the name of the platform.</li>
        <li><strong>ÛUPO </strong>is the merchant, member of the ÛUPO.com Platform who owns an OPERATING location and has posted one or more Temporary Commercial Spaces, WHICH ARE PART OF THE SAME LOCATION, on the ÛUPO.com Platform.</li>
        <li><strong>ÛUPER </strong>is the member of the ÛUPO.com Platform who has booked a Temporary Commercial Space on the ÛUPO.com Platform.</li>
        <li><strong>POP UP: </strong>A pop-up store is a temporary commercial space generally used to present a new product line, test a new market, or raise awareness about a product or cause.</li>
        <li><strong>COWORKING: </strong>Shared space where both individual professionals and freelancers, entrepreneurs or employees, as well as SMEs or startups, decide to carry out their activity for a limited time.</li>
      </ul>

      <p className={css.titleRefund}>
        Conditions
      </p>
      
      <p className={css.subtitleRefund}>
        What service does the UUPO platform offer?
      </p>
      
      <p>
        The ÛUPO.com Platform offers an online space that allows users (hereinafter, the “Members”) to post, offer, search, and book coworking services. ÛUPO is the Member who posts and offers coworking services, and ÛUPER is the Member who searches, books, or uses coworking services. The ÛUPO offers Pop Ups and a variety of services (collectively referred to as “ÛUPO coworking services”).
      </p>
      <p>
        Each user must create an account to access and enjoy the multiple features of the ÛUPO.com Platform; they must also provide truthful information in their account and keep it updated.
      </p>
      <p>
        As a provider, the ÛUPO.com Platform does not own, control, offer, or manage any Listing, ÛUPO Services, or any service.
      </p>
      <p>
        UUPOCOWORKING SLL is not a party to the contracts signed directly between the ÛUPO and the ÛUPER and does not constitute a real estate agent, insurer, or organizer combined under Directive (EU) 2015/2302.
      </p>
      <p>
        UUPOCOWORKING SLL does not act as an agent in any capacity for any Member, except as provided in the Payment Service Terms (hereinafter, “Payment Terms”).
      </p>
      <p>
        UUPOCOWORKING SLL applies other terms and policies that complement these Terms and Conditions, such as the Privacy Policy, which defines how UUPOCOWORKING SLL collects and uses personal data, and the Payment Terms, which govern any payment service provided to Members by ÛUPO.com payment entities (collectively, “ÛUPAGOS”).
      </p>
      <p>
        The ÛUPO is responsible for understanding and complying with all laws, rules, regulations, and third-party contracts applicable to their ÛUPO Services.
      </p>
      <p>
        The ÛUPER will find on the ÛUPO.com Platform the most interesting Pop Up for their commercial strategy. By doing so, they will contact the ÛUPO through a private communication channel to detail the conditions of the commercial event they will share. Once an agreement is reached, the ÛUPO will confirm the reservation. From that moment, the ÛUPER can proceed with the payment of the reservation, thereby formalizing the common agreement.
      </p>
      <p>
        We will analyze some important aspects regarding the characteristics of the service:
      </p>
      <p>
        For more information about a Listing, you should consult the description and photos, the ÛUPO's profile, and previous ÛUPER reviews. If you have any questions, send a message to the ÛUPO at any time.
      </p>
      <ol type="I" className={css.listRefund2}>
        <li>Search and Booking. Pop Ups can be searched by applying search parameters such as location, ÛUPO's name, availability, price, or size. Filters can also be used to narrow search results. Search results are based on matching available search parameters as filters. Factors such as price, availability, services offered to the client, among other metadata configured in the filters, are taken into account.</li>
        <li>Booking. By booking a Listing, you agree to pay all corresponding charges, including the Listing price, applicable commissions, such as UUPOCOWORKING SLL's commission, taxes, and any other item listed on the payment page (collectively, “Total Price”).</li>
        <li>Furthermore, you agree that UUPOCOWORKING SLL, through ÛUPAGOS, may charge the Payment Method (defined in the Payment Terms) used to book the listing to collect sums corresponding to a Damage Claim (defined in these Terms and Conditions).</li>
        <li>Upon booking confirmation, a ÛUPO Services contract (a “Reservation”) is formalized directly between you, ÛUPER, and the ÛUPO.</li>
        <li>Terms. You will be governed by all the terms of the Reservation and will have the obligation to comply with them, including the cancellation policy and any other rules, standards, policies, or requirements identified in the Listing or during the payment process that apply to the Reservation.</li>
        <li>It is your responsibility to read and understand these rules, standards, policies, and requirements before booking a Listing.</li>
        <li>Coworking Reservations on ÛUPO.com. A coworking reservation constitutes a limited license to access the Pop Up, use it, and exhibit in it. The ÛUPO reserves the right to access the coworking Pop Up during the ÛUPER's exhibition, provided that: 1) it is reasonably necessary, 2) it is permitted by the contract between you, ÛUPER, and the ÛUPO, and 3) it is allowed by applicable law. If you remain in the Pop Up after the departure time, the ÛUPO may force you to leave by legal means, including imposing reasonable penalties. You may not exceed the maximum number of meters of exhibition space allowed.</li>
        <li>Member Accounts. You must create an account to access and enjoy the multiple features of the ÛUPO.com Platform. Registration is only allowed for legal entities, associations, and individuals who are at least 18 years old. You declare and guarantee that you are not a person or entity prohibited from using the ÛUPO.com Platform by the law of your place of residence or any other applicable jurisdiction. You must provide truthful, up-to-date, and complete information during registration and keep this information always up-to-date. You cannot register more than one account or transfer your account to another person. You are solely responsible for maintaining the confidentiality and security of your ÛUPO.com account access data and must not disclose this information to any third party. If you suspect that your access data has been lost, stolen, or that your account has been compromised, you must notify UUPOCOWORKING SLL immediately. You will be solely responsible for all activity carried out with your ÛUPO.com account unless such activities were not authorized by you and you have not acted negligently (for example, by not reporting unauthorized use or loss of your access data). If applicable law permits, UUPOCOWORKING SLL may, at its sole discretion, i) request you to provide identification data or other information; ii) conduct checks to verify your identity or background; iii) cross-check your information with third-party databases or other sources and request reports from service providers, and iv) obtain reports from public criminal records or sex offender registries or their local equivalents.</li>
        <li>
          Become a ÛUPO. When becoming a ÛUPO, we may request data such as your full name, date of birth, or official identification document, property or rental details, mandatory business insurance, and recent utility bill to verify your account. By law, providing this information is mandatory as part of the "know your customer" (KYC) process. If any data is incorrect, you may not receive reservation payments.
          <ul className={css.listRefund}>
            <li><strong>KYC process requirements.</strong> The KYC process is a regulatory requirement aimed at protecting our community from fraud, as well as preventing money laundering and terrorism financing. Almost all large companies licensed to issue payments to individuals must comply with this process. This means we are obliged to know who receives the money.</li>
            <li><strong>Why is it important?</strong> If any data is incorrect or incomplete, we may interrupt your payments until we verify your ÛUPO.com account, and we could even flag it as potentially fraudulent.</li>
            <li><strong>How we use your data:</strong> We guarantee that your privacy and security are our top priority. When we need to request personal data, we take all necessary measures to protect it and only verify it to help protect your account and prevent financial crimes. You will find more information in our <a href='/privacy-policy'>Privacy Policy.</a></li>
            <li>
              <strong>What information must be provided?</strong> 
              The exact data to be provided may vary depending on regional regulations. Generally, they include the following:
              <ul className={css.listRefund}>
                <li><strong>Your full name:</strong> must be the one that appears on an official identification document or other official document.</li>
                <li>Business legal name</li>
                <li>Tax identification number</li>
                <li><strong>ÛUPO address:</strong> the address of the ÛUPO.</li>
                <li>Deed or rental agreement. Ownership</li>
                <li>Mandatory business insurance</li>
                <li>Recent utility bills</li>
              </ul>
              Disclaimer. UUPOCOWORKING SLL does not endorse or guarantee the existence, provision, behavior, safety, quality, legality, or suitability of any ÛUPER, ÛUPO, ÛUPO Service, Listing, or third party, nor does it guarantee that member identity or criminal background checks (if applicable) will reveal all past misconduct or prevent future misconduct. Any reference to a "verified" member (or similar term) only indicates that the member (or UUPOCOWORKING SLL) has completed the corresponding verification or identification process. UUPOCOWORKING SLL is not responsible for internet service interruptions or telecommunications infrastructure outside its control that may prevent the availability of the ÛUPO.com Platform. UUPOCOWORKING SLL may temporarily restrict, with due regard to the member's legitimate interests (e.g., providing prior notice), the availability of the ÛUPO.com Platform or specific areas or functions of it when necessary due to capacity limits, server security or integrity, or to carry out maintenance measures that ensure or improve the functioning of the ÛUPO.com Platform.
            </li>
            <li>
              What are the economic conditions of the service?
              <ol type="I" className={css.listRefund2}>
                <li>Commissions. UUPOCOWORKING SLL may charge commissions (and applicable taxes) to ÛUPOs and ÛUPERs for using the ÛUPO.com Platform. Applicable commissions are communicated to ÛUPOs before posting a listing and to ÛUPERS before booking. For more information on when service fees apply and how they are calculated, see this UUPOCOWORKING SLL page. Unless otherwise stated on the ÛUPO.com Platform, service fees are non-refundable.</li>
                <li>Taxes borne by the ÛUPO. As a ÛUPO, you are responsible for determining and complying with your legal obligations regarding declaring, collecting, remitting, or including in the price the applicable VAT or other indirect taxes, coworking service taxes, income taxes, or other levies (hereinafter, "Taxes").</li>
                <li>Tax information. In certain jurisdictions, tax laws may require UUPOCOWORKING SLL to collect or report tax information about you or to withhold taxes from your payments, or both.</li>
                <li>If you do not provide the documentation that UUPOCOWORKING SLL deems necessary to comply with tax withholding obligations regarding your payments, UUPOCOWORKING SLL may withhold payments up to the legally required amount until the necessary documentation is provided.</li>
                <li>
                  <span>You agree that UUPOCOWORKING SLL may issue invoices or similar documentation regarding VAT, goods and services taxes, and consumption taxes or other taxes applicable to ÛUPO Services to facilitate the filing of tax returns by ÛUPERs and their organizations.</span>
                  <br></br>
                  <br></br>
                  <span className={css.textItalic}>CLARIFICATION NOTE REGARDING TAXES. Indirect tax on operations in the Canary Islands, Ceuta, and Melilla. Service provisions between UUPOs and UUPERs when the property where coworking takes place is located in one of these territories will be subject to special rules for determining the application of the corresponding indirect tax.</span>
                  <br></br>
                  <span className={css.textItalic}>Operations to be carried out between UUPO and UUPER will be considered coworking service provisions since, in addition to renting the property, the UUPO will provide the UUPER with a series of ancillary services necessary for the latter to carry out their economic activity. In this context, the UPPER will not be obliged to practice withholding of IRPF for property rental.</span>
                </li>
              </ol>
            </li>
          </ul>
        </li>
      </ol>
      <p className={css.titleRefund}>
        Fees
      </p>
      <p>
        (see also <a href='/service-fee'>Service Fees</a> on the ÛUPO.com Platform)
      </p>
      <p>To help the ÛUPO.com platform run smoothly and cover the cost of services such as Customer Service, ÛUPOCOWORKING S.L.L. will apply a fee when a reservation is confirmed.</p>
      <p>There is a general fee structure for Pop Up reservations:</p>
      <p className={css.subtitleRefund}>Shared fee</p>
      <p>This option splits the fee between the ÛUPO and the ÛUPER.</p>
      <p className={css.subtitleRefund}>ÛUPO fee</p>
      <p>ÛUPOs pay a 3% fee</p>
      <p>We calculate this service fee based on the reservation subtotal (daily price + cleaning fee + additional fee, if applicable, excluding UUPOCOWORKING SLL commissions and taxes) and automatically deduct it from the ÛUPO's payment.</p>
      <p className={css.subtitleRefund}>ÛUPER fee</p>
      <p>This fee varies based on various factors and is displayed on the payment page before requesting the reservation so you know the corresponding amount. Most service fees that ÛUPERs must pay are less than 14.53% of the reservation subtotal (daily price + cleaning fee + additional fee, if applicable, excluding UUPOCOWORKING SLL commissions and taxes).</p>
      <p className={css.subtitleRefund}>VAT</p>
      <p>When applicable, VAT will be added to the service fee.</p>
      <p>We reserve the right to modify our service fees at any time. We will notify you of any fee changes before they take effect. Fee changes will not affect reservations made before their effective date.</p>
      <p className={css.textItalic}>CLARIFICATION NOTE REGARDING TAXES. Indirect tax on operations in the Canary Islands, Ceuta, and Melilla. Service provisions between ÛUPOs and ÛUPERs when the property where coworking takes place is located in one of these territories will be subject to special rules for determining the application of the corresponding indirect tax.</p>
      <p className={css.textItalic}>Operations to be carried out between ÛUPO and ÛUPER will be considered coworking service provisions since, in addition to renting the property, the ÛUPO will provide the ÛUPER with a series of ancillary services necessary for the latter to carry out their economic activity. In this context, the ÛUPER will not be obliged to practice withholding of IRPF for property rental.</p>

      <p className={css.titleRefund}>
        Payment Terms
      </p>
      <p>
        ÛUPAGOS.
      </p>
      <p>These Payment Service Terms for European Users (the “Payment Terms”) constitute a legally binding contract between ÛUPAGOS and you, governing the Payment Services (defined below) performed through or in connection with the ÛUPO.com Platform. When these Payment Terms use “ÛUPAGOS”, “we”, “us”, or “our”, they refer to: ÛUPAGOS Spain, if your country of residence is in the EEA.</p>
      <p>ÛUPAGOS provides payment services to Members who post, offer, and book Pop Ups and ÛUPO coworking services; and other services, whether existing or to be available in the future, provided through the ÛUPO.com Platform. These payment services may include (if available) those listed below (collectively referred to as the “Payment Services”):</p>
      <ul className={css.listRefund}>
        <li>The collection of payments from ÛUPERs (“Payment”) by charging the payment method associated with their ÛUPO.com account, such as a credit or debit card, a bank account, or a PayPal account (the “Payment Method”).</li>  
        <li>The disbursement of payments to ÛUPOs (“Collection”) to the financial instrument associated with their ÛUPO.com account, such as a PayPal account, a bank account, a direct deposit, a prepaid card, or a debit card (the “Collection Method”).</li>
      </ul>
      <p>To use the Payment Services, you must be 18 years or older, have a valid ÛUPO.com account, and ensure your personal and payment information is accurate and complete.</p>
      <p className={css.subtitleRefund}>What are the terms of using the ÛUPO platform?</p>
      <p>The Terms and Conditions separately govern your use of the ÛUPO.com Platform. All terms not defined in these Payment Terms will have the definition set forth in the Terms and Conditions.</p>
      <p>Index</p>
      <ol className={css.listRefund2}>
        <li>Use of the Payment Services by you</li>
        <li>Terms applicable to ÛUPERs</li>
        <li>Terms applicable to ÛUPOs</li>
        <li>General Terms</li>
        <li>Other conditions of the ÛUPO.com platform
          <ol className={css.listRefund3}>
            <li>5.1 Validity and Termination</li>
            <li>5.2 Resolution of complaints and Damage Claims</li>
          </ol>
        </li>
        <li>General provisions</li>
        <li>Cancellations, setbacks, and Refunds. For ÛUPER</li>
        <li>Cancellations, setbacks, and Refunds. For ÛUPO</li>
        <li>Ethical Rules of the ÛUPO.COM Platform</li>
        <li>Contractual position of UUPOCOWORKING S.L.L</li>
        <li>Applicable law and jurisdiction</li>
        <li>Modification of these terms and conditions</li>
      </ol>

      <p className={css.subtitleRefund}>
        1. Use of the Payment Services by you.
      </p>
      <p>1.1. UUPOCOWORKING Payment Services. By using the Payment Services, you agree to comply with these Payment Terms. ÛUPAGOS may temporarily limit or suspend your access to or use of the Payment Services, or their features, to carry out maintenance measures that ensure the proper functioning of the Payment Services. ÛUPAGOS may improve, enhance, and modify the Payment Services and introduce new Payment Services as it deems appropriate. ÛUPAGOS will notify Members of any changes to the Payment Services unless these changes do not substantially increase the contractual obligations of the Members or decrease the rights granted by these Payment Terms to the Members.</p>
      <p>1.2 Verification. You authorize ÛUPAGOS to, directly or through third parties, make inquiries we consider necessary to verify your identity and the information you have provided. This may include: i) matching your data with third-party databases or other information sources; ii) requesting information from service providers; iii) asking you to provide some form of official identification document and indicate your date of birth, address, and other data, or iv) requesting that you take appropriate measures to confirm that you are the owner of your email address, your Payment Method(s), or your Collection Method(s). ÛUPAGOS reserves the right to deny, suspend, or limit access to the Payment Services if we are unable to obtain or verify any of this data.</p>
      <p>1.3 Additional terms. Your access to or use of certain Payment Services may be subject to or conditioned on your acceptance of additional terms and conditions. If there is a conflict between these Payment Terms and the terms and conditions applicable to a specific Payment Service, the latter will prevail regarding your access to or use of that Payment Service unless otherwise specified.</p>

      <p className={css.subtitleRefund}>
        2. Terms applicable to ÛUPERs.
      </p>
      <p>2.1 Adding a Payment Method. When adding a Payment Method to your ÛUPO.com account, you will be asked to provide certain billing information, such as the name, billing address, and details about the financial instrument used, either to ÛUPAGOS or third-party payment processors. You authorize ÛUPAGOS and its payment service providers to collect and store your Payment Method information.</p>
      <p>2.2 Verification of Payment Method. When adding or using a new Payment Method, ÛUPAGOS may verify this Payment Method: i) by authorizing one or two nominal amounts on your Payment Method through a payment service provider and asking you to confirm these amounts, or ii) by asking you to upload a bank statement. We may refund these amounts from your Payment Method, and we reserve the right to do so. When you add a Payment Method at the time of payment, we will automatically save this Payment Method and add it to your ÛUPO.com account for future transactions. You can remove a Payment Method from your ÛUPO.com account as long as it is not associated with an ongoing or yet-to-start reservation.</p>
      <p>2.3 Payment authorization. You authorize ÛUPAGOS to charge your Payment Method (and to charge more than one payment method), either directly or indirectly, all amounts you owe (including applicable taxes) in connection with your ÛUPO.com account, including amounts for Damage Claims, in accordance with the Terms and Conditions and Section 6.</p>
      <p>2.4 Automatic updating of Payment Method. If the information of your Payment Method (e.g., account number, bank identification number, or expiration date) changes due to the re-issuance of the method or for other reasons, we may obtain this information from our financial service partners or your bank and automatically update the Payment Method listed in our files.</p>
      <p>2.5 Payment scheduling. Generally, ÛUPAGOS will charge the Total Price owed after the ÛUPO accepts your booking request.</p>
      <p>2.6 Currency. ÛUPAGOS will process each transaction in the currency you select on the ÛUPO.com platform. The currencies available for payment may be limited for regulatory or operational reasons based on factors such as the Payment Method you selected, your country of residence, or the ÛUPAGOS entity or entities with which you have contracted. These limitations will be communicated to you through the ÛUPO.com Platform, indicating that you must select a different currency or Payment Method. If the ÛUPAGOS entity with which you contract is in a different country than your Payment Method or if the selected currency does not match the billing currency of your Payment Method, the payment may be processed outside your country of residence. Consequently, certain fees may apply, and the amount shown on your card statement may differ from the amount displayed at the time of payment. For example, if you make a reservation with a card issued in the United States and select the euro as the currency, your payment will be processed outside the United States, and banks and credit card issuers may impose international transaction fees and currency conversion fees. Additionally, if the currency you select for payment does not match the billing currency of your Payment Method, your bank or credit card issuer may convert the payment amount to the billing currency associated with your Payment Method, applying the exchange rate and fees determined by your bank at its sole discretion. ÛUPAGOS is not responsible for these fees and disclaims all liability in this regard. Contact your bank or credit card issuer if you have any questions about these fees or the applicable exchange rate.</p>
      <p>2.7 Reservation request status. If your booking request is rejected, either because the ÛUPO does not accept it or because you cancel it before the ÛUPO can accept it, or if UUPOCOWORKING cancels the reservation, you will be refunded the amounts that ÛUPAGOS charged you and that you are entitled to receive under the provisions of Section 5.5, and any preauthorizations applied to your Payment Method (when applicable) will be released as provided in Section 5.5.2.</p>
      <p>2.8 Payment restrictions. ÛUPAGOS reserves the right to reject or limit payments that we consider: i) may violate ÛUPAGOS's risk management policies or procedures; ii) may violate these Payment Terms or the Terms and Conditions; iii) are unauthorized or fraudulent or illegal, or iv) expose you, ÛUPO.com, ÛUPAGOS, or third parties to risks that ÛUPAGOS deems unacceptable.</p>
      <p>2.9 Payment Service Providers. Payment Methods may involve the use of third-party payment service providers. These payment service providers may charge you additional fees when processing payments related to the Payment Services, and ÛUPAGOS is not responsible for these fees and disclaims all liability in this regard. Additionally, your Payment Method may be subject to additional terms of use. Read them carefully before using your Payment Method.</p>
      <p>2.10 Your Payment Method, your responsibility. ÛUPAGOS is not responsible for losses you incur if the Payment Method information you provide is incorrect.</p>

      <p className={css.subtitleRefund}>
        3. Terms applicable to ÛUPOs.
      </p>
      <p>3.1 Payment Collection. ÛUPAGOS collects the Total Price of a reservation at the time the ÛUPO accepts the ÛUPER's booking request.</p>
      <p>3.2 Valid Collection Method. To receive a Payment Collection, you must have a valid Collection Method linked to your ÛUPO.com account. When adding a Collection Method to your ÛUPO.com account, you will be asked to provide certain information, such as the name, billing address, and details about the financial instrument used, either to ÛUPAGOS or third-party payment processors.</p>
      <p>Depending on the chosen Collection Method, additional information may be required, such as postal address, account holder's name, account type, bank identification number, account number, email address, collection currency, identification number, and information about the account associated with a particular payment processor. Providing the requested information is mandatory for a Collection Method to be considered valid. The information ÛUPAGOS requires about a Collection Method may vary, and ÛUPAGOS may request additional information at any time. Failure to provide the requested information may result in ÛUPAGOS withholding, suspending, or temporarily canceling any Collection until the information is provided and, where applicable, validated. You authorize ÛUPAGOS to collect and store your Collection Method data. ÛUPAGOS may also share your data with government authorities as required by applicable law.</p>
      <p>3.3 Scheduling Collections.</p>
      <p>3.3.1 Provided that payments made by the ÛUPER have been correctly received, ÛUPAGOS will generally issue Collections to your selected Collection Method: i) for Pop Ups, 24 hours after the scheduled arrival time of the ÛUPER (or 24 hours after 15:00 local time —or 15:00 UTC, if the local time is unknown, if the arrival time is flexible or not specified).</p>
      <p>3.3.2 ÛUPAGOS may offer you a different payment collection or issuance schedule, which may be subject to additional terms and conditions. The time it takes for you to receive Collections once ÛUPAGOS has sent them may depend on the Collection Method you selected.</p>
      <p>3.4 Payment Collection. The Collection you receive for a reservation will be the Total Price less applicable commissions, such as the UUPOCOWORKING service fee and applicable taxes. In case of cancellation of a confirmed reservation, ÛUPAGOS will remit the amount you are entitled to receive (if any) according to these Terms and Conditions and the applicable cancellation policy.</p>
      <p>3.5 Collection Restrictions. ÛUPAGOS may withhold, suspend, or temporarily cancel any Collection to prevent illegal or fraudulent activities, assess risks, for security reasons, or to complete an investigation, or if we cannot verify your identity for any reason, or to obtain or verify the requested information. Additionally, ÛUPAGOS may withhold, suspend, or delay issuing or processing any Collection owed to you under the Terms and Conditions as a result of a large volume of cancellations or modifications of reservations resulting from a Force Majeure Event (as defined below).</p>
      <p>3.6 Currency Conversion. ÛUPAGOS will process your Collections in the currency you select on the ÛUPO.com Platform. The available currencies may be limited for operational or regulatory reasons based on factors such as the Collection Method you selected, your country of residence, or the ÛUPOCOWORKING entity or entities with which you contracted. These limitations will be communicated to you through the ÛUPO.com Platform, indicating that you must select a different currency or Collection Method. Note that payment service providers may impose transaction fees, currency conversion fees, or other fees based on the currency or Collection Method you select, and ÛUPAGOS is not responsible for these fees and disclaims all liability in this regard.</p>
      <p>3.7 Collection Limits. For regulatory or operational reasons, ÛUPAGOS may limit the amount of a Collection. If you are owed an amount greater than this limit, ÛUPAGOS may issue a series of Collections (possibly over several days) to provide you with the total amount of your Collection.</p>
      <p>3.8 Payment Service Providers. Collection Methods may involve the use of third-party payment service providers. These service providers may charge you additional fees when processing Collections related to the Payment Services (including fees deductible from the Collection amount), and ÛUPAGOS is not responsible for these fees and disclaims all liability in this regard. Additionally, your Collection Method may be subject to additional terms of use. Read them carefully before using your Collection Method.</p>
      <p>3.9 Your Collection Method, your responsibility. ÛUPAGOS is not responsible for losses you incur if the Collection Method information you provide is incorrect.</p>

      <p className={css.subtitleRefund}>
        4. General Terms.
      </p>
      <p>4.1 Commissions. ÛUPAGOS may charge commissions for using certain Payment Services; any applicable commission will be shown to you in the Terms and Conditions or through the ÛUPO.com Platform.</p>
      <p>4.2 Payment authorizations. You authorize ÛUPAGOS to charge you the amounts you owe under these ÛUPAGOS or the Terms and Conditions by charging the Payment Method associated with the corresponding reservation, or any other registered Payment Method you authorize in your ÛUPO.com account (unless you have previously withdrawn authorization to charge that Payment Method), or ii) by withholding the amount from your future Collections. You specifically authorize ÛUPAGOS to charge you:</p>
      <ul className={css.listRefund}>
        <li>Any amount you owe UUPOCOWORKING or ÛUPAGOS (e.g., as a result of your reservations, Reservation Modifications, cancellations, or other actions as ÛUPER, ÛUPO, or ÛUPO.com user), including reimbursement of costs prepaid by UUPOCOWORKING or ÛUPAGOS on your behalf. The funds collected by ÛUPAGOS will offset the amount you owe UUPOCOWORKING or ÛUPAGOS and extinguish your obligation to UUPOCOWORKING or ÛUPAGOS.</li>
        <li>Any amount owed by a ÛUPER to a ÛUPO that UUPOCOWORKING collects as the ÛUPO's payment collection agent, as set forth in Section 4 above.</li>
        <li>Taxes, when applicable, as provided in the Terms and Conditions.</li>
        <li>Any amount you pay through the Resolution Center related to your ÛUPO.com account.</li>
        <li>Any amount owed for a Damage Claim to UUPOCOWORKING or ÛUPAGOS under the Terms and Conditions and Section 6.</li>
        <li>Penalties for overstaying payable under the Terms and Conditions, including costs and expenses incurred in collecting such penalties.</li>
        <li>Any service fee or cancellation fee imposed under the Terms and Conditions (e.g., if you, as ÛUPO, cancel a confirmed reservation).</li>
        <li>Any amount that has already been paid to you as ÛUPO despite a ÛUPER canceling a confirmed reservation or UUPOCOWORKING determining that it is necessary to cancel a reservation under the Terms and Conditions, the <a href='/refund-policy'>ÛUPER Refund Policy</a>, the <a href='/force-majeure-policy'>Force Majeure Policy</a>, or another applicable cancellation policy. You agree that if you have already received the payment, ÛUPAGOS will be entitled to charge you the amount of such refund to the ÛUPER, including by withholding such refund amount from any future Collection owed to you.</li>
      </ul>
      <p>If, at any time, you owe any sum after formalizing a Reservation, as occurs with Reservation Modifications, payments through the Resolution Center, or Damage Claims ("Additional Amounts"), by these terms, you authorize and instruct ÛUPAGOS to charge such Additional Amounts to your Payment Method associated with the corresponding Reservation, without any further action on your part. ÛUPAGOS will notify you in writing before charging the Additional Amounts.</p>
      <p>If your Payment Method associated with the corresponding reservation has expired, is invalid, or cannot be charged for any reason (e.g., insufficient balance), the responsibility for the amounts owed remains with you, and ÛUPAGOS may recharge your Payment Method related to the Reservation or use any other registered Payment Method in your ÛUPO account, if available, to charge such Additional Amounts. Any amount not collected for any reason will be subject to any applicable recourse, including but not limited to referring it to a collection agency or initiating any actions or claims against you as appropriate.</p>
      <p>If UUPOCOWORKING has reason to believe that you, as ÛUPO, have engaged in fraudulent activity, such as an overpayment scam, reservation-related fraud, or other types of fraud, and ÛUPAGOS issued the Collection for such stay, ÛUPAGOS may recover that amount by offsetting future Collections owed to you or deducting it from them.</p>
      <p>In addition to the amount owed, if there are overdue amounts or chargebacks associated with your Payment Method, you may be charged fees associated with ÛUPAGOS collecting such overdue amounts and chargebacks. These fees or charges may include collection fees, processing fees, or other third-party fees.</p>
      <p>4.3 Collections.</p>
      <p>4.3.1 If ÛUPAGOS cannot collect an amount you owe under the Terms and Conditions and these ÛUPAGOS, ÛUPAGOS may take certain procedures to collect this amount from you.</p>
      <p>4.3.2 ÛUPAGOS will consider any amount owed pending: a) in the case of authorized charges, one hundred and twenty (120) days after ÛUPAGOS first attempted to charge your Payment Method or the associated services were provided (whichever occurs later), and b) in the case of withholdings from future ÛUPO Collections, two hundred and seventy (270) days after the adjustment was made in the ÛUPO.com account of the ÛUPO or the associated services were provided (whichever occurs later).</p>
      <p>4.3.3 ÛUPAGOS will consider overdue unpaid amounts as delinquent when three hundred and sixty-five (365) days have passed: a) in the case of authorized charges, since ÛUPAGOS first attempted to charge your Payment Method or the associated services were provided (whichever occurs later), and b) in the case of withholdings from future ÛUPO Collections, since the adjustment was made in the ÛUPO.com account of the ÛUPO or the associated services were provided (whichever occurs later).</p>
      <p>4.3.4 You agree that all communication regarding outstanding amounts will be conducted via email or phone, as indicated to UUPOCOWORKING or ÛUPAGOS. Such communications may be conducted by UUPOCOWORKING, ÛUPAGOS, or anyone on their behalf, including but not limited to a third-party collection agent.</p>
      <p>4.4 Errors in payment processing.</p>
      <p>4.4.1 ÛUPAGOS will take necessary steps to correct payment processing errors that we become aware of. These steps may include applying a credit or debit (as appropriate) to the same Collection or Payment Method you originally used or selected, ensuring you end up receiving or paying the correct amount. This may be done by ÛUPAGOS or a third party, such as your bank. We may also take steps to recover funds sent to you in error (including, but not limited to, cases of duplicate payments made to you due to a processing error) by deducting, offsetting, or debiting the amount of such funds from any future Collection owed to you.</p>
      <p>4.4.2 To the extent you receive funds in error, you agree to return them to ÛUPAGOS immediately.</p>
      <p>4.5 Refunds.</p>
      <p>4.5.1 Any refunds or amounts payable to a Member under the Terms and Conditions, the ÛUPER Refund Policy, and the Force Majeure Policy will be initiated and remitted by ÛUPAGOS under these Payment Terms.</p>
      <p>4.5.2 As provided in Section 5.5.2, ÛUPAGOS will initiate the refund process immediately. In some cases, the refund process may include the option to receive a credit instead of a cash refund; if this option is made available to you, the timing of your refund will be communicated through the ÛUPO.com Platform. However, the time it takes to receive any cash refund or release any preauthorization of your Payment Method will vary depending on the Payment Method and the applicable payment system rules (e.g., Visa, Mastercard, etc.). In the case of a Force Majeure Event affecting the processing and settlement of refunds, ÛUPAGOS will initiate and process the refund as soon as possible.</p>
      
      <p className={css.subtitleRefund}>
        5. Other conditions of the ÛUPO platform.
      </p>
      <p>5.1. Validity and Termination. The agreement between UUPOCOWORKING SLL and you embodied in these Terms will remain in effect until terminated by you or UUPOCOWORKING SLL under these Terms and Conditions. You may terminate the contract at any time by sending an email to UUPOCOWORKING SLL or deleting your account. UUPOCOWORKING SLL may terminate the contract for any reason by notifying you 30 days in advance by email or any other contact method you provided for your account.</p>
      <p>UUPOCOWORKING SLL may also terminate the contract immediately and without notice and stop providing access to the ÛUPO.com Platform if i) you seriously breach these Terms and Conditions, our Terms and Conditions; ii) you violate applicable law; iii) such action is necessary to protect the personal safety or property of UUPOCOWORKING SLL, its Members, or third parties (e.g., in the case of fraudulent behavior by a Member), or iv) your account has been inactive for more than two years.</p>
      <p><strong>ÛUPO may also terminate the contract for violations committed by Members.</strong></p>
      <p>If i) you breach these Terms and Conditions of UUPOCOWORKING SLL; ii) you violate applicable laws and regulations or infringe on the rights of third parties; iii) you receive repeated negative reviews, or ÛUPO.com becomes aware of or receives complaints about your services or behavior; vi) you repeatedly cancel confirmed reservations or do not respond to booking requests without justified cause, or vii) it is necessary to protect the personal safety or property of UUPOCOWORKING SLL, its Members, or third parties, UUPOCOWORKING SLL may:</p>
      <ul className={css.listRefund}>
        <li>suspend or limit your access or use of the ÛUPO.com Platform or your account</li>
        <li>suspend or delete Listings, Reviews, or other Content</li>
        <li>cancel any pending or confirmed reservation, or</li>
        <li>suspend or revoke any special category associated with your account</li>
      </ul>
      <p>In the case of minor violations or where appropriate, UUPOCOWORKING SLL will notify you of the possible measures to be taken and give you the opportunity to remedy the problem unless such notification i) prevents or hinders the detection or prevention of fraud or other illegal activities; ii) affects the legitimate interests of other Members or third parties, or iii) violates applicable law.</p>
      <p><strong>Legal Obligations.</strong> UUPOCOWORKING SLL may take any measures it deems reasonably necessary to comply with applicable law, or with judicial or law enforcement, administrative, or government authority requirements</p>
      <ol type='I' className={css.listRefund2}>
        <li><strong>Effects of termination.</strong> If you are a ÛUPO and delete your ÛUPO.com account, all confirmed reservations will be automatically canceled, and your ÛUPERs will receive a full refund. If you delete your ÛUPO.com account as ÛUPER, all confirmed reservations will be automatically canceled, and refunds will depend on the cancellation policy conditions of each Listing. Once this contract is terminated, you will not be entitled to the restoration of your ÛUPO.com account or your Content. In the event of limitation of access or use of the ÛUPO.com Platform, suspension of your ÛUPO.com account, or termination of the contract by UUPOCOWORKING SLL, you will not be able to create a new UUPOCOWORKING SLL account, access the ÛUPO.com Platform, or use it through another Member's UUPOCOWORKING SLL account.</li>
        <li><strong>Resources.</strong> If UUPOCOWORKING SLL takes any of the measures described in Section h, you may appeal the decision by contacting our Customer Service.</li>
      </ol>
      <p>5.2 Resolution of complaints and Damage Claims.</p>
      <p>If a Member validly proves that you, your guests, or your pets have culpably damaged the movable or immovable property of the complaining Member, or the movable or immovable property under the Member's responsibility, including indirect damages, ("Damage Claim"), the complaining Member may notify UUPOCOWORKING SLL or request compensation through the Resolution Center. In that case, you will receive a notification about the Damage Claim and have the opportunity to respond.</p>
      <p>If you agree to pay, or if the Damage Claim is referred to UUPOCOWORKING SLL and UUPOCOWORKING SLL determines, considering the applicable legal burden of proof, that the Damage Claim is valid and you are responsible for the Damage Claim, UUPOCOWORKING SLL may charge you through ÛUPAGOS the amount of the Damage Claim. UUPOCOWORKING SLL may also take any action at its disposal under applicable law, including referring outstanding amounts to a collection agency or initiating causes or claims against you as appropriate. You agree to cooperate in good faith, provide any information requested by UUPOCOWORKING SLL, execute documents, and take any other reasonable measures related to Damage Claims, Member complaints, insurance claims, or other claims related to your provision or use of ÛUPO Services. UUPOCOWORKING SLL's decisions can be appealed by contacting UUPOCOWORKING SLL's customer service. UUPOCOWORKING SLL's decisions regarding a Damage Claim will not affect your contractual or legal rights. You will retain your right to take legal action.</p>

      <p className={css.subtitleRefund}>
        6. General provisions.
      </p>
      <ol type='I' className={css.listRefund2}>
        <li><strong>Other terms incorporated by reference.</strong> The use of the ÛUPO.com Platform, and are incorporated by reference and form part of the agreement you enter into with UUPOCOWORKING SLL, the <a href='/damage-protection-terms'>Damage Protection for ÛUPOs</a>, the Content Policy, the Anti-Discrimination Policy, the <a href='/force-majeure-policy'>Force Majeure Policy</a>, policies, rules, and other complementary policies and conditions related to these Terms.</li>
        <li><strong>Interpretation of these Terms.</strong> Unless supplemented by additional terms, conditions, policies, guidelines, rules, and product information disclosures, these Terms and Conditions constitute the entire agreement between UUPOCOWORKING SLL and you regarding your access or use of the ÛUPO.com Platform and supersede all prior and contemporaneous agreements, both oral and written, between UUPOCOWORKING SLL and you. These Terms and Conditions do not confer or intend to confer any rights or remedies to anyone other than UUPOCOWORKING SLL and you. If any provision of these Terms and Conditions is held to be invalid or unenforceable, that provision will be nullified and will not affect the validity and enforceability of the remaining provisions.</li>
        <li>
          <strong>Waiver.</strong> The failure of UUPOCOWORKING SLL to enforce any right or provision of these Terms and Conditions will not constitute a waiver of that right or provision unless acknowledged and agreed to by us in writing. Except as expressly provided in these Terms and Conditions, the exercise by either party of any remedy available to it under these Terms and Conditions will be without prejudice to its other remedies available under these Terms and Conditions or by law.
          <ol type='I' className={css.listRefund2}>
            <li><strong>Notices.</strong> Unless otherwise specified, any notice or other communications to Members required or permitted under this agreement will be provided electronically and transmitted by UUPOCOWORKING SLL via email, notification on the ÛUPO.com Platform, message (including SMS), or any other communication method provided by you.</li>
            <li><strong>Inquiries.</strong> For any inquiries about these Terms and Conditions, you can contact UUPOCOWORKING SLL by email.</li>
            <li><strong>Assignment.</strong> You may not assign, transfer, or delegate this agreement or your rights and obligations under it without the prior written consent of UUPOCOWORKING SLL. UUPOCOWORKING SLL may assign, transfer, or delegate this agreement and any rights and obligations under it at its sole discretion by providing you with 30 days' notice. This does not affect your right to terminate this agreement at any time under Section 13.2.</li>
          </ol>
        </li>
      </ol>

      <p className={css.subtitleRefund}>
        7. Cancellations, setbacks, and Refunds. For ÛUPER.
      </p>
      <p>Sometimes unexpected events occur, and ÛUPERs have to cancel.</p>ç
      <p>Cancellation Policy for ÛUPERs:</p>
      <ul className={css.listRefund}>
        <li>ÛUPERs can cancel up to 7 days before arrival for a full refund. In these cases, the ÛUPO will not charge anything.</li>
        <li>
          If they do so after that period, the ÛUPO will receive:
          <ul className={css.listRefund}>
            <li>If the cancellation occurs between the sixth day and the day before arrival, 50% of the total reserved days.</li>
            <li>If the cancellation occurs on the day of arrival or after the check-in date, the amount will be the corresponding amount for the completed days, plus an additional day, plus 50% of all remaining days.</li>
          </ul>
        </li>
      </ul>
      <ol type='I' className={css.listRefund2}>
        <li>In general, when a ÛUPER cancels a reservation, the amount the ÛUPO receives will correspond to the cancellation policy outlined above. However, in certain situations, other policies prevail, and these determine what amount is appropriate to refund. If a cause beyond your control forces you to cancel a Reservation, you may be entitled to a partial or full refund under UUPOCOWORKING SLL's <a href='/force-majeure-policy'>Force Majeure Policy</a>. If the ÛUPO cancels the Reservation or if you experience a Travel Disruption (as defined in the <a href='/refund-policy'>ÛUPER Refund Policy</a>), you may be entitled to assistance in making a new Reservation or to a full or partial refund according to the <a href='/refund-policy'>ÛUPER Refund Policy.</a> For more details on what these policies cover and what refund applies in each situation, consult each Legal Term Additional or the various policies. You can appeal this decision by contacting ÛUPO.com's customer service.</li>
        <li>Reservation modifications. ÛUPO and ÛUPER are responsible for any reservation modifications they agree to make through the ÛUPO.com Platform or that they instruct UUPOCOWORKING SLL's Customer Service to make on their behalf (hereinafter, "Reservation Modifications"), and they agree to pay any amount, commission, or additional tax associated with any Reservation Modification.</li>
        <li>Responsibilities of the ÛUPER. You are responsible for your actions and omissions, as well as those of any individuals you invite or provide access to the Pop Up, including: i) ensuring the Commercial Space (and associated personal property) is left in the same condition as when you arrived, ii) paying all amounts due for Damage Claims necessary to cover damages caused by you, your guests, or your pets to a Pop Up, and iii) acting with integrity, treating others with respect, and complying with applicable laws at all times. If you book for an additional minor ÛUPER or bring a minor to a ÛUPO's Pop Up, you must be legally authorized to act on the minor's behalf and will be solely responsible for their supervision.</li>
      </ol>

      <p className={css.subtitleRefund}>
        8. Cancellations, setbacks, and Refunds. For ÛUPO.
      </p>
      <p className={css.subtitleRefund}>What happens if a ÛUPO cancels the reservation before arrival?</p>
      <p>If a ÛUPO cancels a reservation before check-in, the ÛUPER will automatically receive a full refund. Additionally, if the ÛUPO cancels within 30 days or less before check-in and the ÛUPER contacts us, we will help the ÛUPER find a similar or better Pop Up.</p>
      <p>In general, when a ÛUPER cancels a reservation, the amount you receive will correspond to the cancellation policy outlined above. As a ÛUPO, you should not cancel a ÛUPER's reservation without justified cause under UUPOCOWORKING SLL's <a href='/force-majeure-policy'>Force Majeure Policy</a> or applicable law. </p>
      <p>If you cancel a reservation without justified cause, UUPOCOWORKING SLL may impose a cancellation penalty or take other measures. If a ÛUPER experiences an issue covered by the ÛUPER Refund Policy or a Force Majeure Event occurs, the amount you receive will be reduced by the amount refunded or provided to the ÛUPER and any other reasonable costs incurred by UUPOCOWORKING SLL as a result of the cancellation. If a ÛUPER receives a refund after you have been paid, or if the refund amount and other costs incurred by UUPOCOWORKING SLL exceed the amount you should receive, UUPOCOWORKING SLL (through ÛUPAGOS) may claim this amount from you, if necessary, by offsetting it with future Collections.</p>
      <p>You agree that the <a href='/refund-policy'>ÛUPER Refund Policy</a>, the <a href='/force-majeure-policy'>Force Majeure Policy</a>, and these Terms and Conditions will take precedence over the cancellation policy you have established when they allow a Reservation to be canceled or payments to be refunded to ÛUPERs. When UUPOCOWORKING SLL reasonably anticipates refunding a payment to a ÛUPER under one of these policies, it may withhold any payment owed to you under these Terms and Conditions or ask you to refund that amount to UUPOCOWORKING SLL, including through the Resolution Center.</p>
      <p className={css.subtitleRefund}>What happens if a ÛUPER cancels the reservation before arrival?</p>
      <p>If a ÛUPER cancels a reservation before check-in, you will automatically receive a full refund. Additionally, if the ÛUPER cancels within 30 days or less before check-in and the ÛUPER contacts us, we will help the ÛUPER find a similar or better Pop Up.</p>
      <p className={css.subtitleRefund}>Force Majeure Event</p>
      <p>In the case of Force Majeure Events (as defined in the Force Majeure Policy), ÛUPO and ÛUPER may cancel reservations according to the Force Majeure Policy, and the <a href='/force-majeure-policy'>Force Majeure Policy</a> will take precedence over any cancellation policy you have established. In these cases, the amount you receive will be determined based on the provisions of the Force Majeure Policy.</p>
      
      <p className={css.subtitleRefund}>
        9. Ethical Rules of the ÛUPO.com Platform.
      </p>
      <p>The ÛUPO.com platform is based on ethical principles, and we expect the same from our ÛUPOs and ÛUPERs. We will not tolerate abusive or discriminatory behavior, and we reserve the right to remove any content that violates our community standards.</p>

      <p className={css.subtitleRefund}>
        10. Contractual position of UUPOCOWORKING S.L.L.
      </p>
      <p>As the provider of the ÛUPO.com platform, UUPOCOWORKING S.L.L. is responsible for ensuring the proper functioning and security of the platform, as well as for providing support to our users. However, UUPOCOWORKING S.L.L. is not responsible for the actions of ÛUPOs or ÛUPERs, nor for the content they post on the platform.</p>

      <p className={css.subtitleRefund}>
        11. Applicable law and jurisdiction.
      </p>
      <p>These Terms and Conditions are governed by Spanish law. Any disputes arising from or related to these Terms and Conditions will be subject to the exclusive jurisdiction of the courts of Spain.</p>

      <p className={css.subtitleRefund}>
        12. Modification of these terms and conditions.
      </p>
      <p>UUPOCOWORKING S.L.L. reserves the right to modify these Terms and Conditions at any time. We will notify you of any changes by posting the new Terms and Conditions on the ÛUPO.com platform and by sending you an email. Your continued use of the ÛUPO.com platform after the changes take effect constitutes your acceptance of the new Terms and Conditions.</p>

    </div>
  );
};

TermsOfServiceEnglish.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

TermsOfServiceEnglish.propTypes = {
  rootClassName: string,
  className: string,
};

export default TermsOfServiceEnglish;
