import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './SeguroUupo.module.css';

const SeguroUupoEnglish = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  // prettier-ignore
  return (
    <div className={classes}>
      <p className={css.titleRefund}>ÛUseguro offers THREE protections to all ÛUPERs for each POP UP organization.</p>
      <p className={css.titleRefund}>Reservation Protection Guarantee</p>
      <p>In the unlikely event that a ÛUPO has to cancel your reservation within 30 days prior to arrival, we will find you a similar or better POP UP, or we will send you a refund.</p>
      <p className={css.titleRefund}>Reservation Change Guarantee</p>
      <p>If you cannot check-in to your POP UP and the ÛUPO cannot resolve the issue, we will find you a similar or better POP UP for the duration of your original event, or we will send you a refund.</p>
      <p className={css.titleRefund}>Listing Accuracy Guarantee</p>
      <p>If during your stay you find that your POP UP does not match what was listed in the advertisement (e.g., the lighting is damaged and the ÛUPO cannot fix it, or if the space is smaller than indicated in the listing), you have three days to notify us. Once you do, we will find you a similar or better POP UP, or we will send you a refund.</p>
    </div>
  );

};

SeguroUupoEnglish.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

SeguroUupoEnglish.propTypes = {
  rootClassName: string,
  className: string,
};

export default SeguroUupoEnglish;
