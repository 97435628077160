import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './CausesPolicy.module.css';

const CausesPolicyEnglish = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  // prettier-ignore
  return (
    <div className={classes}>
      <p className={css.lastUpdated}>Don't worry! If you have to cancel your reservation due to an emergency or a force majeure event, we can help:</p>
      <ol className={css.listRefund}>
        <li>Read our Force Majeure Policy to see if you are eligible.</li>
        <li>Make sure you have all the necessary documentation.</li>
        <li>When canceling your Pop Up reservation, select “This is a force majeure case.”</li>
        <li>Contact us to file a claim, and we will explain all the steps you need to follow. Among other things, you will need to send us the requested documentation and wait for our team to review your case.</li>
      </ol>
      <p>
        <strong>Important:</strong> Claims must be submitted within 14 days of canceling the reservation.
      </p>

      <p>Effective date: December 1, 2022</p>
      <p className={css.titleRefund}>
        General Information
      </p>
      
      <p>
        This Force Majeure Policy explains how we manage cancellations when unforeseen events beyond your control arise after booking that prevent or prohibit its completion.
      </p>
      <p>If cancellation is allowed under this policy, it will take precedence over the reservation's cancellation policy. Professionals affected by any of the situations included in this policy can cancel their reservation and receive a refund. ÛUPOs affected by any of the situations covered by this policy can cancel without penalties, but the dates of the canceled reservation may be marked as unavailable in their calendars depending on the circumstances.</p>
      
      <p className={css.titleRefund}>
        Covered Situations
      </p>
      
      <p>
        In this policy, the term “situation” is used to refer to circumstances that arise after booking, that were not foreseeable, and that prevent or legally prohibit its completion.
      </p>
      
      <p className={css.titleRefund}>
        Changes in Government Travel Requirements
      </p>
      
      <p>
        Unexpected changes in visa or passport requirements imposed by a governmental authority that prevent travel to the destination. This does not include the loss or expiration of necessary travel documents or other personal circumstances related to a professional’s travel authorization.
      </p>

      <p className={css.titleRefund}>
        Declared Emergencies and Epidemics
      </p>
      
      <p>
        National or local emergencies, epidemics, pandemics, and public health emergencies officially declared by the government. This does not include diseases that are endemic or already associated with a particular area, such as malaria in Thailand or dengue in Hawaii.
      </p>

      <p className={css.titleRefund}>
        Government Travel Restrictions
      </p>
      
      <p>
        Travel restrictions imposed by a governmental authority that prevent or prohibit travel to the pop-up location, exhibiting there, or travel from there. This does not include non-binding travel advisories and other similar government advice.
      </p>

      <p className={css.titleRefund}>
        Military Actions and Other Conflicts
      </p>
      
      <p>
        Acts of war, conflicts, invasions, civil wars, terrorism, explosions, bombings, rebellions, riots, insurrections, civil unrest, and civil instability.
      </p>
      
      <p className={css.titleRefund}>
        Natural Disasters
      </p>
      
      <p>
        Natural disasters, acts of God, large-scale interruptions of essential public services, volcanic eruptions, tsunamis, and other extreme and abnormal weather events. This does not include weather phenomena or natural conditions that are common in an area and therefore foreseeable, such as strong winds during the hurricane season in Florida.
      </p>

      <p className={css.titleRefund}>
        Situations Not Covered
      </p>

      <p className={css.titleRefund}>
        All Others
      </p>
      <p>This policy only allows cancellations due to the situations described above. All other situations are excluded. Some examples of situations not covered by this policy include: unexpected illnesses, diseases, or injuries; governmental obligations such as being called for jury duty, court appearances, or military duties; travel advisories or other government advice (that do not restrict or prohibit travel); cancellation or rescheduling of an event for which the reservation was made; and transportation interruptions unrelated to a covered situation, such as road closures and flight, train, bus, and ferry cancellations. If you cancel a reservation in these cases, the refund amount will depend on the applicable cancellation policy.</p>

      <p className={css.titleRefund}>
        What to Do Next
      </p>
      <p>If we inform you that this policy applies to your reservation or publish information confirming it, follow the cancellation instructions we provide. In principle, you can cancel the corresponding reservation from the reservations page and invoke this policy. If you believe your case fits the conditions established in this policy but have not received any notification or we have not published information about it, contact us to cancel your reservation. In all cases, you will need to provide documentation showing how the situation affected you or how it influenced your reservation.</p>
      <p>If you have any questions, please contact us.</p>
    </div>
  );

};

CausesPolicyEnglish.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

CausesPolicyEnglish.propTypes = {
  rootClassName: string,
  className: string,
};

export default CausesPolicyEnglish;
