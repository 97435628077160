import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './RefundPolicy.module.css';

const RefundPolicy = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  // prettier-ignore
  return (
    <div className={classes}>
      <p className={css.lastUpdated}>Fecha de entrada en vigor: 1 de diciembre 2022</p>

      <p>
        Esta Política de Reembolso y Asistencia para Cambio de reserva explica cómo ayudaremos a cambiar una reserva y cómo gestionamos los reembolsos cuando un ÛUPO cancela una reserva u otro tipo de Contratiempo de Organización disrumpe una celebración de un Pop Up.
      </p>

      <p className={css.titleRefund}>
        ¿Qué pasa si un ÛUPO cancela la reservación antes de la llegada?
      </p>
      
      <p>
        Si un ÛUPO cancela una reservación antes de la entrada, su ÛUPER recibirá automáticamente un reembolso completo. Además, si el ÛUPO cancela cuando falten 30 días o menos para la entrada y el ÛUPER se comunica con nosotros , ayudaremos al ÛUPER a encontrar un establecimiento similar o mejor.
      </p>

      <p className={css.titleRefund}>
        ¿Qué ocurre si otro Contratiempo de Organización disrumpe la celebración de mi Pop Up?
      </p>
      
      <p>
        El resto de los Contratiempos de Organización se nos deben comunicar, a más tardar, en un plazo de 72 horas desde que se tenga conocimiento de ellos. Si determinamos que un Contratiempo de Organización ha impedido el correcto desarrollo del Pop Up, tramitaremos un reembolso completo o parcial y, dependiendo de las circunstancias, podremos prestar asistencia al ÛUPER para que encuentre un Pop Up similar o mejor. El importe del reembolso depende de la gravedad del Contratiempo de Organización, el impacto para el ÛUPER, la fracción de la estancia que se haya visto afectada, y si el ÛUPER abandona o no el establecimiento. Si el ÛUPER decide abandonar el Pop Up debido al Contratiempo de Organización y se pone en contacto con nosotros, le ofreceremos asistencia para que encuentre otro espacio similar o mejor para los días restantes de su Pop Up.
      </p>

      <p className={css.titleRefund}>
        ¿Qué Contratiempos de Organización de Pop Up están cubiertos?
      </p>
      
      <p>
        El término “Contratiempo de Organización de Pop Up” hace referencia a las siguientes situaciones: 
        <ul className={css.listRefund}>
          <li>
            El ÛUPO cancela la reservación antes de la llegada.
          </li>
          <li>El ÛUPO no proporciona acceso al establecimiento.</li>
          <li>El ÛUPO no declara en el Anuncio que determinadas personas o mascotas estarán presentes en el ÛUPO durante la celebración del Pop Up.</li>
          <li>El establecimiento, o el Pop Up, no se encuentran en condiciones habitables en el momento de la entrada por cualquiera de las siguientes razones:
            <ul className={css.listRefund}>
              <li>El establecimiento, o cualquiera de sus partes, no está en condiciones de higiene y salubridad adecuadas.</li>
              <li>El establecimiento contiene elementos peligrosos para la salud o la seguridad.</li>
              <li>Hay pestes o plagas en el establecimiento.</li>
            </ul>
          </li>
          <li>El Anuncio contiene una imprecisión sustancial, por ejemplo:
            <ul className={css.listRefund}>
              <li>Se indica un tipo de establecimiento, o de Pop Up, incorrectos (p. ej., otras medidas o características).</li>
              <li>La ubicación del establecimiento es incorrecta.</li>
              <li>Un servicio o características especiales que se describen en el Anuncio no están disponibles o en funcionamiento.</li>
            </ul>
          </li>
        </ul>
      </p>

      <p className={css.titleRefund}>
        ¿Cómo funcionan las reclamaciones?
      </p>
      
      <p>
        Para ser elegible para un establecimiento alternativo o un reembolso, el ÛUPER que confirmó la reserva puede comunicarse con nosotros  para presentar una reclamación. Las reclamaciones deben enviarse, a más tardar, 72 horas después de haber tomado conocimiento del Contratiempo de Organización, y deberán ir acompañadas de pruebas relevantes, como fotografías o una confirmación de las condiciones por parte del ÛUPO. Evaluaremos las pruebas disponibles para determinar si se produjo un Contratiempo de Organización.
      </p>

      <p className={css.titleRefund}>
        ¿Cómo afecta esta Política a los ÛUPOs?
      </p>
      
      <p>
        Si un ÛUPO cancela una reserva o si un Contratiempo de Organización impide la correcta celebración de un Pop Up, el ÛUPO no recibirá ningún cobro o se deducirá de su cobro el importe del reembolso que se haya remitido a su ÛUPER.
      </p>
      <p>En la mayoría de los casos, intentaremos corroborar la reclamación de un ÛUPER con su ÛUPO. Los ÛUPOs también pueden impugnar las reclamaciones relativas a un Contratiempo de Desplazamiento poniéndose en contacto con nosotros.</p>

      <p className={css.titleRefund}>
        Otros aspectos que debes tener en cuenta
      </p>
      
      <p>
        Esta Política se aplica a todas las reservas confirmadas a partir de la Fecha de Entrada en Vigor, incluido el mismo día en que empiece a surtir efecto. Cuando se aplique esta Política a una reserva, estas disposiciones prevalecerán sobre la política de cancelación correspondiente a la reserva.
      </p>
      <p>Antes de presentar una reclamación, siempre que sea posible, el ÛUPER debe informar al ÛUPO e intentar resolver el Contratiempo de Organización con su ÛUPO. En lo que respecta a la resolución del contratiempo, el ÛUPER puede solicitar un reembolso directamente al ÛUPO a través del Centro de Resoluciones. Podremos reducir el importe de cualquier reembolso o ajustar cualquier ayuda destinada al cambio de reservación que se facilite en el marco de esta Política cuando así resulte necesario para reflejar los reembolsos o compensaciones que un ÛUPO haya proporcionado directamente. Como parte de la asistencia para el cambio de reserva, UUPOCOWORKING SLL podrá, cuando lo considere oportuno, pagar un Pop Up alternativo o cubrir una parte de su coste. También podemos ofrecer al ÛUPER la opción de destinar el importe que haya abonado por la Pop Up cancelada a reservar otro Pop Up.</p>
      <p>Cuando el ÛUPER demuestre que no fue posible notificar un Contratiempo de Organización a su debido tiempo, es posible que admitamos su comunicación fuera del plazo previsto en la presente Política. Esta Política no cubre los Contratiempos de Organización causados por el ÛUPER o sus acompañantes, invitados o mascotas. La presentación de una reclamación fraudulenta constituirá una infracción de nuestros Términos y Condiciones y podrá conllevar la eliminación de la cuenta.</p>
      <p>Las decisiones que adoptemos en el marco de esta Política serán de carácter vinculante, pero no afectarán a otros derechos contractuales o legales que puedan ser aplicables. Todo derecho que los ÛUPOs o los ÛUPERs tengan para iniciar acciones legales no se verán afectados. Esta Política no constituye un seguro, y ningún ÛUPER o ÛUPO ha abonado prima alguna por ella. Todos los derechos y obligaciones que la presente Política confiere a los ÛUPOs y los ÛUPERs de una reserva son individuales y no pueden ser objeto de transferencia o cesión. Cualquier cambio en esta Política se llevará a cabo de acuerdo con nuestros Términos y Condiciones.</p>

    </div>
  );
};

RefundPolicy.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

RefundPolicy.propTypes = {
  rootClassName: string,
  className: string,
};

export default RefundPolicy;
