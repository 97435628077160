import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import { Form, PrimaryButton, FieldTextInput, NamedLink } from '../../components';
import * as validators from '../../util/validators';

import css from './LoginForm.module.css';
import { loginSteps } from '../../util/loginSteps';
import FieldInternationalPhoneNumberInput from '../../components/FieldInternationalPhoneNumberInput/FieldInternationalPhoneNumberInput';
import FieldCodeInput from '../../components/FieldCodeInput/FieldCodeInput';

const LoginFormComponent = props => (
  <FinalForm
    {...props}
    render={fieldRenderProps => {
      const {
        rootClassName,
        className,
        formId,
        handleSubmit,
        inProgress,
        intl,
        invalid,
        loginStep,
        validationCodeInProgress,
        validationCodeError,
        codeVerificationInProgress,
        codeVerificationError,
        requestValidationCode,
        validateCodeAndLogin,
        values,
        goToLoginStep,
      } = fieldRenderProps;

      const sendVerificationCode = (e) => {
        e.preventDefault();
        requestValidationCode({ phoneNumber: values.phoneNumber });
      }

      const handleCodeVerification = (e) => {
        e.preventDefault();

        validateCodeAndLogin({
          verificationCode: values.smsCode,
          phoneNumber: values.phoneNumber,
        });
      }

      // phone number
      const phoneNumber = intl.formatMessage({
        id: 'LoginForm.phoneNumber',
      });

      const phoneNumberRequiredMessage = intl.formatMessage({
        id: 'LoginForm.phoneNumberRequired',
      });
      const phoneNumberRequired = validators.required(phoneNumberRequiredMessage);

      // email
      const emailLabel = intl.formatMessage({
        id: 'LoginForm.emailLabel',
      });
      const emailPlaceholder = intl.formatMessage({
        id: 'LoginForm.emailPlaceholder',
      });
      const emailRequiredMessage = intl.formatMessage({
        id: 'LoginForm.emailRequired',
      });
      const emailRequired = validators.required(emailRequiredMessage);
      const emailInvalidMessage = intl.formatMessage({
        id: 'LoginForm.emailInvalid',
      });
      const emailValid = validators.emailFormatValid(emailInvalidMessage);

      // password
      const passwordLabel = intl.formatMessage({
        id: 'LoginForm.passwordLabel',
      });
      const passwordPlaceholder = intl.formatMessage({
        id: 'LoginForm.passwordPlaceholder',
      });
      const passwordRequiredMessage = intl.formatMessage({
        id: 'LoginForm.passwordRequired',
      });
      const passwordRequired = validators.requiredStringNoTrim(passwordRequiredMessage);

      const classes = classNames(rootClassName || css.root, className);
      const submitInProgress = inProgress;
      const submitDisabled = invalid || submitInProgress;

      const passwordRecoveryLink = (
        <NamedLink name="PasswordRecoveryPage" className={css.recoveryLink}>
          <FormattedMessage id="LoginForm.forgotPassword" />
        </NamedLink>
      );

      return (
        <Form className={classes} onSubmit={handleSubmit}>

          {loginStep === loginSteps.stepPhoneInput &&
            <div>

              <h2>
                <FormattedMessage id="LoginForm.welcome" />
              </h2>

              <FieldInternationalPhoneNumberInput
                type="text"
                id={formId ? `${formId}.phoneNumber` : 'phoneNumber'}
                name="phoneNumber"
                defaultCountry="ES"
                withCountryCallingCode
                countryCallingCodeEditable={false}
                international
                label={phoneNumber}
                validate={phoneNumberRequired} />

              {validationCodeError &&
                <label className={css.error}>
                  {validationCodeError}
                </label>}

              <p>
                <FormattedMessage id="LoginForm.phoneNumberInfo" />
              </p>

              <div className={css.bottomWrapper}>
                {/* <p className={css.bottomWrapperText}>
                  <span className={css.emailText}>
                    <FormattedMessage
                      id="LoginForm.loginWith"
                    />

                    <span className={css.emailLink} onClick={() => {
                      goToLoginStep(loginSteps.stepLogin);
                    }}>
                      <FormattedMessage
                        id="LoginForm.emailAndPassword"
                      />
                    </span>
                  </span>
                </p> */}
                <PrimaryButton
                  type="submit"
                  className={css.submitButton}
                  inProgress={submitInProgress || validationCodeInProgress}
                  disabled={submitDisabled}
                  onClick={sendVerificationCode}>
                  <FormattedMessage id="LoginForm.continue" />
                </PrimaryButton>
              </div>
            </div>
          }

          {loginStep === loginSteps.stepSMSCodeInput &&
            <div>
              <p>
                <FormattedMessage id="LoginForm.enterCode" />
              </p>

              <FieldCodeInput
                className={css.smsCode}
                name="smsCode"
                id={formId ? `${formId}.smsCode` : 'smsCode'}
              />

              {codeVerificationError &&
                <label className={css.error}>
                  {codeVerificationError}
                </label>}

              <PrimaryButton
                className={css.submitButton}
                type="submit" inProgress={submitInProgress || codeVerificationInProgress}
                disabled={submitDisabled}
                onClick={handleCodeVerification}>
                <FormattedMessage id="LoginForm.verify" />
              </PrimaryButton>

            </div>
          }


          {loginStep === loginSteps.stepLogin &&
            <>
              <div>
                <FieldTextInput
                  type="email"
                  id={formId ? `${formId}.email` : 'email'}
                  name="email"
                  autoComplete="email"
                  label={emailLabel}
                  placeholder={emailPlaceholder}
                  validate={validators.composeValidators(emailRequired, emailValid)}
                />
                <FieldTextInput
                  className={css.password}
                  type="password"
                  id={formId ? `${formId}.password` : 'password'}
                  name="password"
                  autoComplete="current-password"
                  label={passwordLabel}
                  placeholder={passwordPlaceholder}
                  validate={passwordRequired}
                />
              </div>
              <div className={css.bottomWrapper}>
                <p className={css.bottomWrapperText}>
                  <span className={css.recoveryLinkInfo}>
                    <FormattedMessage
                      id="LoginForm.forgotPasswordInfo"
                      values={{ passwordRecoveryLink }}
                    />
                  </span>
                </p>
                <PrimaryButton className={css.submitButton}
                  type="submit" inProgress={submitInProgress} disabled={submitDisabled}>
                  <FormattedMessage id="LoginForm.logIn" />
                </PrimaryButton>
              </div>
            </>
          }
        </Form>
      );
    }}
  />
);

LoginFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  form: null,
  inProgress: false,
};

const { string, bool } = PropTypes;

LoginFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  form: string,
  inProgress: bool,
  intl: intlShape.isRequired,
};

const LoginForm = compose(injectIntl)(LoginFormComponent);
LoginForm.displayName = 'LoginForm';

export default LoginForm;
