import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './PrivacyPolicy.module.css';

const PrivacyPolicyEnglish = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  // prettier-ignore
  return (
    <div className={classes}>
      <p className={css.lastUpdated}>Last updated: December 1, 2022</p>

      <p>
        UUPOCOWORKING SLL exists to help connect professionals and build a more open and inclusive economy. Our community is built on trust. To earn that trust, it is essential that we are transparent about how we use your data and how we protect your human right to privacy.
      </p>
      <p>This Privacy Policy describes how UUPOCOWORKING SLL (“we,” “our(s),” “us,” or “UUPOCOWORKING SLL”) handles personal data in relation to your use of the ÛUPO.com Platform. Please review the information describing how we process personal information.</p>

      <p className={css.titleRefund}>
        PRIVACY POLICY
      </p>
      
      <p>
        UUPOCOWORKING S.L.L makes this privacy policy available to you through this website to inform you in detail about how we process your personal data and protect your privacy and the information you provide to us.
      </p>
      <p>On this website, UUPOCOWORKING S.L.L guarantees the security and confidentiality of the personal data provided by individuals with whom it interacts by implementing technical and organizational measures aimed at this purpose.</p>
      <p>If changes are made in the future, we will inform you through the website or other means so that you can be aware of the new privacy conditions introduced.</p>
      <p>In compliance with Regulation (EU) 2016/679, General Data Protection Regulation, and Organic Law 3/2018, of December 5, on the Protection of Personal Data and guarantee of digital rights, we inform you of the following:</p>

      <p className={css.titleRefund}>
        Data Controller
      </p>
      
      <p>
        UUPOCOWORKING S.L.L. CIF: B72446073. Email: hola@uupo.es
      </p>

      <p className={css.titleRefund}>
        For what purpose do we process your personal data?
      </p>
      
      <div>
        At UUPOCOWORKING S.L.L, we collect and process your personal information to manage our relationship with you, with the main identified purposes being:
        <ul className={css.listRefund}>
          <li>
            Management and contracting of the products and services offered by our company
          </li>
          <li>Channeling information requests, suggestions, and complaints that you may send us electronically or otherwise.</li>
          <li>Keeping you informed about events, offers, products, and services that may be of interest to you through various communication channels as long as you have given your consent.</li>
          <li>Management of the employment relationship, in the case of our employees.</li>
          <li>Management of the commercial relationship with our suppliers</li>
          <li>Management of personnel selection</li>
        </ul>
      </div>

      <p className={css.titleRefund}>
        What is the legal basis for processing your data?
      </p>
      
      <p>
      By completing this form and accepting the privacy policy, you consent to UUPOCOWORKING S.L.L processing your data to send you administrative, technical, organizational, informative, and/or commercial information about our products and services, responding to your request also for a legitimate interest.
      </p>
      <p>UUPOCOWORKING S.L.L, in the course of its business, may also use other legal bases for processing personal data</p>
      <ul className={css.listRefund}>
        <li>Consent of the data subject for one or more specific purposes.</li>
        <li>Necessary for the performance of a contract to which the data subject is party or for the implementation of pre-contractual measures at the data subject's request.</li>
        <li>Necessary to protect the vital interests of the data subject or another person.</li>
        <li>Necessary for compliance with a legal obligation to which the controller is subject.</li>
        <li>Necessary for the performance of a task carried out in the public interest or in the exercise of official authority vested in the controller.</li>
        <li>Necessary for the purposes of the legitimate interests pursued by the controller or by a third party.</li>
      </ul>
      <p>You will be informed of the legal basis used in each commercial relationship.</p>
      <p className={css.titleRefund}>
        How do we collect your information?
      </p>
      <p>We collect your personal information through various means, but you will always be informed at the time of collection by means of informative clauses about the data controller, the purpose, and the legal basis of the processing, the recipients of the data, and the period for which the information will be retained, as well as how you can exercise your data protection rights.</p>
      <p>In general, the personal information we process is limited to identification data (name and surname, date of birth, address, DNI, phone number, and email), contracted services, and payment and billing data.</p>
      <p>In the cases of personnel management and selection, we collect academic and professional data to fulfill obligations arising from maintaining the employment relationship or, as the case may be, to become part of our workforce.</p>
      <p>UUPOCOWORKING S.L.L may use social networks, and this is another way to reach you. Information collected through messages and communications you post may contain personal information that is publicly available online. These social networks have their own privacy policies explaining how they use and share your information, so UUPOCOWORKING S.L.L recommends that you review them before using these platforms to confirm that you agree with how your information is collected, processed, and shared.</p>
      <p>Through our website, we collect personal information related to your browsing through the use of cookies. To learn clearly and precisely about the cookies we use, their purposes, and how you can configure or disable them, please see our Cookie Policy.</p>

      <p className={css.titleRefund}>
        User Responsibility
      </p>
      <p>By providing us with your data through electronic channels, the user guarantees that they are over 14 years of age and that the data provided to UUPOCOWORKING S.L.L are true, accurate, complete, and up to date. In this regard, the user confirms that they are responsible for the accuracy of the communicated data and will keep such information duly updated to reflect their real situation, being responsible for any false or inaccurate data provided and for any damages, direct or indirect, that may arise.</p>

      <p className={css.titleRefund}>
        How long do we retain your information?
      </p>
      <p>At UUPOCOWORKING S.L.L, we retain your information only for the period necessary to fulfill the purpose for which it was collected, to comply with the legal obligations imposed on us, and to address any liabilities that may arise from fulfilling the purpose for which the data was collected.</p>
      <p>If you wish to become part of our workforce and apply for one of our positions, the data provided will become part of our employment pool and will be retained for the duration of the selection process and for a maximum of one year or until you exercise your right to deletion.</p>
      <p>If at any time we have collected your data to contact you as a potential user of our services or to respond to an information request you made, such data will be retained for a maximum of one year from collection, being deleted after this period if no contractual relationship has been formalized or at the moment you request it.</p>
      <p>In any case, and as a general rule, we will keep your personal information as long as there is a contractual relationship that binds us or you do not exercise your right to deletion and/or limitation of processing, in which case, the information will be blocked without further use beyond its retention, while it may be necessary for the exercise or defense of claims or any type of liability that may need to be addressed.</p>

      <p className={css.titleRefund}>
        To whom do we communicate your data?
      </p>
      <p>In general, at UUPOCOWORKING S.L.L, we do not share your personal information, except for those transfers we must make based on imposed legal obligations.</p>
      <p>However, to develop and provide the requested service, we share your data with other collaborating companies and/or startups.</p>
      <p>You can communicate your opposition to the transfer of your data, although in that case, it would not be possible to provide the requested service.</p>
      <p>Although it is not a data transfer, to provide the requested service, third-party companies acting as our suppliers may access your information to carry out the service we have contracted them for. These processors access your data following our instructions and without being able to use them for a different purpose, maintaining the strictest confidentiality.</p>
      <p>Likewise, your personal information will be available to Public Administrations, Judges, and Courts, to address possible liabilities arising from processing.</p>

      <p className={css.titleRefund}>
        International Data Transfers
      </p>
      <p>There are no international transfers of your data to countries outside the European Economic Area (EEA).</p>
      <p>We have agreed with our suppliers that, for the provision of the contracted service, they will use servers located in the EEA, and if, in the future, we need to use servers located outside the EU territory, appropriate measures will be adopted, which will be incorporated into this Privacy Policy, ensuring that such providers are under the Privacy Shield agreement or that there are other adequate guarantees.</p>

      <p className={css.titleRefund}>
        What are your rights regarding the processing of your data and how can you exercise them?
      </p>
      <p>The data protection regulations allow you to exercise your rights of access, rectification, deletion, and data portability, and opposition and limitation to their processing, as well as not being subject to decisions based solely on automated processing of your data, where applicable.</p>
      <p>These rights are characterized by the following:</p>
      <ul className={css.listRefund}>
        <li>The exercise is free of charge, except for manifestly unfounded or excessive requests (e.g., repetitive nature), in which case UUPOCOWORKING S.L.L may charge a fee proportional to the administrative costs incurred or refuse to act.</li>
        <li>You can exercise the rights directly or through your legal or voluntary representative.</li>
        <li>We must respond to your request within one month, although, considering the complexity and number of requests, this period can be extended by another two months.</li>
        <li>We are obliged to inform you about the means to exercise these rights, which must be accessible and without denying you the exercise of the right for choosing another means. If the request is submitted electronically, the information will be provided by these means where possible, unless you request otherwise.</li>
        <li>If UUPOCOWORKING S.L.L does not act on your request, we will inform you, at the latest within one month, of the reasons for our failure to act and the possibility of lodging a complaint with a Supervisory Authority.</li>
      </ul>
      <p>To exercise your rights, UUPOCOWORKING S.L.L makes the following means available to you:</p>
      <ol type='1' className={css.listRefund2}>
        <li>By written and signed request addressed to UUPOCOWORKING S.L.L, Ref. Exercise of GDPR Rights.</li>
        <li>Sending a scanned and signed form to the email address hola@uupo.es indicating in the subject line Exercise of GDPR Rights.</li>
      </ol>
      <p>In both cases, you must prove your identity by attaching a photocopy or, if applicable, a scanned copy of your DNI or equivalent document to verify that we only respond to the data subject or their legal representative, who must provide a document proving the representation.</p>
      <p>Additionally, and especially if you consider that you have not obtained full satisfaction in the exercise of your rights, we inform you that you can file a complaint with the national supervisory authority by contacting the Spanish Data Protection Agency, C/ Jorge Juan, 6 – 28001 Madrid.</p>
      <p className={css.titleRefund}>
        How do we protect your information?
      </p>
      <p>At UUPOCOWORKING S.L.L, we are committed to protecting your personal information.</p>
      <p>We use reasonably reliable and effective physical, organizational, and technological measures, controls, and procedures aimed at preserving the integrity and security of your data and ensuring your privacy.</p>
      <p>In addition, all personnel with access to personal data have been trained and are aware of their obligations regarding the processing of your personal data.</p>
      <p>In the contracts we sign with our suppliers, we include clauses requiring them to maintain the duty of confidentiality regarding the personal data they have accessed by virtue of the assignment, as well as to implement the necessary technical and organizational security measures to ensure the confidentiality, integrity, availability, and permanent resilience of personal data processing systems and services.</p>
      <p>All these security measures are periodically reviewed to ensure their adequacy and effectiveness.</p>
      <p>However, absolute security cannot be guaranteed, and no security system is impenetrable, so if any information under our control is compromised as a result of a security breach, we will take appropriate measures to investigate the incident, notify the Supervisory Authority, and, if applicable, those users who may have been affected so they can take appropriate measures.</p>
    </div>
);

};

PrivacyPolicyEnglish.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

PrivacyPolicyEnglish.propTypes = {
  rootClassName: string,
  className: string,
};

export default PrivacyPolicyEnglish;
