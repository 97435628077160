import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './CancellationPolicy.module.css';

const CancellationPolicy = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  // prettier-ignore
  return (
    <div className={classes}>
      <p className={css.lastUpdated}>Fecha de entrada en vigor: 1 de diciembre 2022</p>

      <p>
        Aunque las cancelaciones por parte de los ÛUPOs son muy poco frecuentes y algunas se deben a motivos que escapan a su control, pueden suponer un gran trastorno para los ÛUPERs y deteriorar su confianza en nuestra comunidad. Por ese motivo, en determinadas circunstancias, si el ÛUPO cancela una reserva confirmada de un Pop Up, o si se le considera responsable de una cancelación en el marco de esta Política, ÛUPOCOWORKING SLL podrá aplicar tarifas u otras consecuencias. Las tarifas y el resto de consecuencias previstas en esta Política tienen por objeto reflejar los costes y el impacto que estas cancelaciones suponen para los ÛUPERs, la comunidad de ÛUPOs y ÛUPOCOWORKING SLL en general. No aplicaremos tarifas ni, en algunos casos, otras consecuencias si el ÛUPO cancela debido a una Causa de Fuerza Mayor  o por una razón válida  que escape a su control.
      </p>

      <p className={css.titleRefund}>
        Tarifas de cancelación
      </p>
      
      <p>
        La tarifa mínima de cancelación es de 100 euros, y la máxima asciende a 1.500 euros. La suma se calculará en función del importe de la reserva y de la antelación con que se cancele:
      </p>

			<ul className={css.listRefund}>
				<li>Si se cancela con 48 horas de antelación o menos, o después de la llegada, la tarifa será un 50 % del importe correspondiente a los días restantes de la reserva.</li>
				<li>Si se cancela con más de 48 horas de antelación, y cuando falten 30 días o menos para la llegada, la tarifa corresponderá a un 25 % del importe de la reserva.</li>
				<li>Si se cancela cuando falten más de 30 días para la llegada, la tarifa corresponderá a un 10 % del importe de la reserva.</li>
			</ul>

			<p>Al calcular las tarifas de cancelación, el importe de la reserva incluye el precio base, los gastos de limpieza y servicio de apoyo a la venta, pero no los impuestos ni las comisiones del ÛUPER. La tarifa de cancelación mínima será de 100 euros, aunque el importe que se obtenga del cálculo sea inferior. Del mismo modo, el importe máximo será de 1.500 euros, aunque el cálculo dé lugar a una cifra superior.</p>

			<p>Por lo general, las tarifas de cancelación se retienen de los siguientes cobros del ÛUPO, conforme a lo previsto en los Términos de Pago del Servicio . Además de las tarifas y las consecuencias establecidas en esta Política, los ÛUPOs que cancelen o sean responsables de una cancelación no recibirán ningún cobro por esa reserva. Si el ÛUPO ya ha recibido el dinero, se retendrá el importe correspondiente en sus futuros cobros.</p>

      <p className={css.titleRefund}>
				Situaciones en las que pueden no aplicarse tarifas de cancelación
      </p>
      
      <p>
				Renunciaremos a las tarifas establecidas en esta Política en determinadas situaciones, por ejemplo, si el ÛUPO cancela debido a una Causas de Fuerza Mayor o a ciertas razones válidas  que escapen a su control.
      </p>

			<p>Aunque no se cobre una tarifa de cancelación, es posible que sí se apliquen otras consecuencias, como que se marquen como no disponibles las fechas correspondientes en el calendario de un Anuncio.</p>

			<p>Los ÛUPOs que consideren que se encuentran en una de estas situaciones tendrán que facilitar documentación u otro tipo de pruebas para que valoremos si podemos eximir el cobro de la tarifa de cancelación o la aplicación de otras consecuencias.</p>

      <p className={css.titleRefund}>
				Otras consecuencias
      </p>
      
			<p>Además de una tarifa de cancelación, pueden aplicarse otras consecuencias, como que se marquen no disponibles en el calendario las fechas correspondientes a una reserva cancelada. De este modo, el ÛUPO no podrá aceptar otras reservas del mismo Anuncio esos días.</p>

			<p>Los ÛUPOs que cancelen repetidamente reservas confirmadas sin un motivo válido podrían enfrentarse a otras consecuencias, tal y como se explica en nuestros Términos y Condiciones de Servicio. Por ejemplo, es posible que suspendamos o eliminemos el anuncio o la cuenta de dichos ÛUPOs.</p>

			<p></p>

      <p className={css.titleRefund}>
				¿Cuándo es el ÛUPO responsable de una cancelación?
      </p>
      
      <p>
				Cuando esta Política menciona que un ÛUPO es responsable de una cancelación, se refiere a aquellas situaciones en que las condiciones reales difieren de manera sustancial y evidente de cómo se describía el Anuncio en el momento de su reserva. Por ejemplo, se incluyen los casos de reservas que se solapen; la sustitución del Pop Up reservado por otra propiedad, o las imprecisiones de peso en el Anuncio que afecten de manera considerable al desarrollo del evento comercial de un ÛUPER, por ejemplo, si se ofrecía una característica del Pop Up, pero esta no está disponible.
      </p>

			<p className={css.titleRefund}>
				Otros aspectos que debes tener en cuenta
      </p>
      
      <p>
				Si un ÛUPO no puede ofrecer una reserva, debe cancelarla, y no pedir al ÛUPER que lo haga.
      </p>
			<p>Realizar falsas declaraciones o proporcionar información engañosa en lo relativo a esta Política supone una infracción de nuestros Términos y Condiciones de Servicio  y podría conllevar la eliminación de tu cuenta u otras consecuencias.</p>
			<p>Esta Política se aplica a las cancelaciones que se produzcan a partir de la fecha de entrada en vigor (incluida). Los derechos que tengan los ÛUPOs o los ÛUPERs para emprender acciones legales no se verán afectados. Cualquier cambio en la presente Política se efectuará de acuerdo con nuestros Términos y Condiciones de Servicio. </p>
    </div>
  );
};

CancellationPolicy.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

CancellationPolicy.propTypes = {
  rootClassName: string,
  className: string,
};

export default CancellationPolicy;
