import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './ProtectionData.module.css';

const ProtectionData = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  // prettier-ignore
  return (
    <div className={classes}>
   
      <p>
        <strong>Información sobre Protección de datos</strong>
      </p>

      <p>
        <strong>(Reglamento UE 2016/679 y Ley Orgánica 3/2018)</strong>
      </p>

      <p className={css.titleRefund}>¿Quién es el responsable del tratamiento de sus datos?</p>
      <p>UUPOCOWORKING S.L.L. CIF: B72446073</p>
      <p>Correo electrónico: <a href="mailto:hola@uupo.es" className={css.enlaceEmail}>hola@uupo.es</a></p>
      <p>Más información sobre nosotros en <a className={css.enlaceEmail} href="/terminos-condiciones">Aviso Legal</a></p>
      <p className={css.titleRefund}>¿Con qué finalidad tratamos sus datos personales?</p>
      <p>Para la gestión administrativa de su registro formando parte de la agenda de contactos.</p>
      <p>Para la efectiva prestación contractual del servicio de coworking que Usted conoce a través de nuestros Términos y Condiciones, en su fase de validación y culminación, y en todos los trámites administrativos y fiscales del proceso.</p>
      <p className={css.titleRefund}>¿Por cuánto tiempo conservamos sus datos?</p>
      <p>Sus datos se conservarán durante los años necesarios para el cumplimiento de las obligaciones legales.</p>
      <p className={css.titleRefund}>¿Cuál es la legitimación para el tratamiento de sus datos?</p>
      <p>El tratamiento es necesario para la ejecución contractual del servicio en el que Usted es parte como interesado o para la aplicación a petición suya de medidas precontractuales.</p>
      <p>El tratamiento es necesario para la satisfacción de los intereses legítimos de UUPOCOWORKING S.L.L siempre que no prevalezcan sus intereses, derechos y libertades fundamentales.</p>
      <p className={css.titleRefund}>¿A qué destinatarios se comunicarán sus datos?</p>
      <p>Sus datos no serán comunicados a terceros</p>
      <p>Salvo imperativo legal y a las Administraciones Públicas para el correcto cumplimiento de las obligaciones administrativas y legales que se deriven.</p>
      <p>Salvo a las personas, Empresas, y Entidades financieras que presten servicios a UUPOCOWORKING S.L.L para el eficaz desarrollo de los servicios contratados.</p>
      <p className={css.titleRefund}>Previsión de transferencia a terceros países.</p>
      <p>Si el desarrollo de nuestros servicios requiriera trasferencia internacional de datos, ésta se ajustará a la legalidad establecida en el Capítulo V del RGPD y Título VI de la LOPDGD. Para países sin decisión de adecuación, o garantías adecuadas, se aplicará el artículo 49 del RGPD UE 2016/679, siendo la legitimación en este caso la ejecución del contrato en todos sus términos, las obligaciones legales que se deriven y el consentimiento expreso si se considerara necesario.</p>
      <p className={css.titleRefund}>¿Cuáles son sus derechos cuando nos facilita sus datos?</p>
      <p>Puede ejercitar los derechos de acceso, rectificación, supresión, oposición, portabilidad o limitación enviando solicitud escrita a nuestra dirección postal o electrónica, acreditando su identidad, poniendo a su disposición los formularios a dichos efectos.</p>
      <p>Así mismo tiene usted el derecho a presentar una reclamación ante la AEPD en el supuesto de que considere que se infringen sus derechos de protección de datos.</p>
      <p className={css.titleRefund}>TRATAMIENTO DE FOTOGRAFIAS Y MATERIAL AUDIOVISUAL</p>
      <p>Las fotografías y el material audiovisual subidos a la plataforma ÛUPO deben de referirse únicamente a espacios comerciales que puedan ser de interés para ÛUPOs y ÛUPERs.</p>
      <p><strong>UUPOCOWORKING S.L.L no validará registros que contengan fotos o cualquier otro material audiovisual que suponga la identificación de personas, quedando exonerada de responsabilidad ante cualquier vulneración de esta norma por parte de los usuarios</strong></p>
    </div>
  );
};

ProtectionData.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

ProtectionData.propTypes = {
  rootClassName: string,
  className: string,
};

export default ProtectionData;
