import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import * as validators from '../../util/validators';
import { Form, PrimaryButton, FieldTextInput } from '../../components';

import css from './SignupForm.module.css';
import FieldInternationalPhoneNumberInput from '../../components/FieldInternationalPhoneNumberInput/FieldInternationalPhoneNumberInput';
import { signUpSteps } from '../../util/signUpSteps';
import FieldCodeInput from '../../components/FieldCodeInput/FieldCodeInput';

const KEY_CODE_ENTER = 13;

const SignupFormComponent = props => (
  <FinalForm
    {...props}
    render={fieldRenderProps => {
      const {
        rootClassName,
        className,
        formId,
        handleSubmit,
        inProgress,
        invalid,
        intl,
        onOpenTermsOfService,
        validationCodeInProgress,
        validationCodeError,
        codeVerificationInProgress,
        codeVerificationError,
        signupStep,
        goToSignupStep,
        requestValidationCode,
        validateCode,
        values
      } = fieldRenderProps;

      const sendVerificationCode = (e) => {
        e.preventDefault();
        requestValidationCode({ phoneNumber: values.phoneNumber });
      }

      const handleCodeVerification = (e) => {
        e.preventDefault();

        validateCode({
          verificationCode: values.smsCode,
          phoneNumber: values.phoneNumber,
          formValues: values,
        });
      }

      // phone number
      const phoneNumber = intl.formatMessage({
        id: 'SignupForm.phoneNumber',
      });

      const phoneNumberRequiredMessage = intl.formatMessage({
        id: 'SignupForm.phoneNumberRequired',
      });
      const phoneNumberRequired = validators.required(phoneNumberRequiredMessage);

      // email
      const emailLabel = intl.formatMessage({
        id: 'SignupForm.emailLabel',
      });
      const emailPlaceholder = intl.formatMessage({
        id: 'SignupForm.emailPlaceholder',
      });
      const emailRequiredMessage = intl.formatMessage({
        id: 'SignupForm.emailRequired',
      });
      const emailRequired = validators.required(emailRequiredMessage);
      const emailInvalidMessage = intl.formatMessage({
        id: 'SignupForm.emailInvalid',
      });
      const emailValid = validators.emailFormatValid(emailInvalidMessage);

      // password
      const passwordLabel = intl.formatMessage({
        id: 'SignupForm.passwordLabel',
      });
      const passwordPlaceholder = intl.formatMessage({
        id: 'SignupForm.passwordPlaceholder',
      });
      const passwordRequiredMessage = intl.formatMessage({
        id: 'SignupForm.passwordRequired',
      });
      const passwordMinLengthMessage = intl.formatMessage(
        {
          id: 'SignupForm.passwordTooShort',
        },
        {
          minLength: validators.PASSWORD_MIN_LENGTH,
        }
      );
      const passwordMaxLengthMessage = intl.formatMessage(
        {
          id: 'SignupForm.passwordTooLong',
        },
        {
          maxLength: validators.PASSWORD_MAX_LENGTH,
        }
      );
      const passwordMinLength = validators.minLength(
        passwordMinLengthMessage,
        validators.PASSWORD_MIN_LENGTH
      );
      const passwordMaxLength = validators.maxLength(
        passwordMaxLengthMessage,
        validators.PASSWORD_MAX_LENGTH
      );
      const passwordRequired = validators.requiredStringNoTrim(passwordRequiredMessage);
      const passwordValidators = validators.composeValidators(
        passwordRequired,
        passwordMinLength,
        passwordMaxLength
      );

      // firstName
      const firstNameLabel = intl.formatMessage({
        id: 'SignupForm.firstNameLabel',
      });
      const firstNamePlaceholder = intl.formatMessage({
        id: 'SignupForm.firstNamePlaceholder',
      });
      const firstNameRequiredMessage = intl.formatMessage({
        id: 'SignupForm.firstNameRequired',
      });
      const firstNameRequired = validators.required(firstNameRequiredMessage);

      // lastName
      const lastNameLabel = intl.formatMessage({
        id: 'SignupForm.lastNameLabel',
      });
      const lastNamePlaceholder = intl.formatMessage({
        id: 'SignupForm.lastNamePlaceholder',
      });
      const lastNameRequiredMessage = intl.formatMessage({
        id: 'SignupForm.lastNameRequired',
      });
      const lastNameRequired = validators.required(lastNameRequiredMessage);

      const classes = classNames(rootClassName || css.root, className);
      const submitInProgress = inProgress;
      const submitDisabled = invalid || submitInProgress;

      const handleTermsKeyUp = e => {
        // Allow click action with keyboard like with normal links
        if (e.keyCode === KEY_CODE_ENTER) {
          onOpenTermsOfService();
        }
      };
      const termsLink = (
        <span
          className={css.termsLink}
          onClick={onOpenTermsOfService}
          role="button"
          tabIndex="0"
          onKeyUp={handleTermsKeyUp}
        >
          <FormattedMessage id="SignupForm.termsAndConditionsLinkText" />
        </span>
      );

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          {signupStep === signUpSteps.stepPhoneInput &&
            <div>

              <h2>
                <FormattedMessage id="SignupForm.welcome" />
              </h2>

              <FieldInternationalPhoneNumberInput
                type="text"
                id={formId ? `${formId}.phoneNumber` : 'phoneNumber'}
                name="phoneNumber"
                defaultCountry="ES"
                withCountryCallingCode
                countryCallingCodeEditable={false}
                international
                label={phoneNumber}
                validate={phoneNumberRequired} />

              {validationCodeError &&
                <label className={css.error}>
                  {validationCodeError}
                </label>}

              <p>
                <FormattedMessage id="SignupForm.phoneNumberInfo" />
              </p>

              <div className={css.bottomWrapper}>
                <p className={css.bottomWrapperText}>
                  <span className={css.termsText}>
                    <FormattedMessage
                      id="SignupForm.termsAndConditionsAcceptText"
                      values={{ termsLink }}
                    />
                  </span>
                </p>
                <PrimaryButton
                  type="submit"
                  className={css.submitButton}
                  inProgress={submitInProgress || validationCodeInProgress}
                  disabled={submitDisabled}
                  onClick={sendVerificationCode}>
                  <FormattedMessage id="SignupForm.continue" />
                </PrimaryButton>
              </div>
            </div>
          }

          {signupStep === signUpSteps.stepSMSCodeInput &&
            <div>
              <p>
                <FormattedMessage id="SignupForm.enterCode" />
              </p>

              <FieldCodeInput
                className={css.smsCode}
                name="smsCode"
                id={formId ? `${formId}.smsCode` : 'smsCode'}
              />

              {codeVerificationError &&
                <label className={css.error}>
                  {codeVerificationError}
                </label>}

              <PrimaryButton
                className={css.submitButton}
                type="submit" inProgress={submitInProgress || codeVerificationInProgress}
                disabled={submitDisabled}
                onClick={handleCodeVerification}>
                <FormattedMessage id="SignupForm.verify" />
              </PrimaryButton>

            </div>
          }


          {signupStep === signUpSteps.stepSignUp &&
            <div>
              <div>
                <FieldTextInput
                  type="email"
                  id={formId ? `${formId}.email` : 'email'}
                  name="email"
                  autoComplete="email"
                  label={emailLabel}
                  placeholder={emailPlaceholder}
                  validate={validators.composeValidators(emailRequired, emailValid)}
                />
                <div className={css.name}>
                  <FieldTextInput
                    className={css.firstNameRoot}
                    type="text"
                    id={formId ? `${formId}.fname` : 'fname'}
                    name="fname"
                    autoComplete="given-name"
                    label={firstNameLabel}
                    placeholder={firstNamePlaceholder}
                    validate={firstNameRequired}
                  />
                  <FieldTextInput
                    className={css.lastNameRoot}
                    type="text"
                    id={formId ? `${formId}.lname` : 'lname'}
                    name="lname"
                    autoComplete="family-name"
                    label={lastNameLabel}
                    placeholder={lastNamePlaceholder}
                    validate={lastNameRequired}
                  />
                </div>
                <FieldTextInput
                  className={css.password}
                  type="password"
                  id={formId ? `${formId}.password` : 'password'}
                  name="password"
                  autoComplete="new-password"
                  label={passwordLabel}
                  placeholder={passwordPlaceholder}
                  validate={passwordValidators}
                />
              </div>

              <div className={css.bottomWrapper}>
                <p className={css.bottomWrapperText}>
                  <span className={css.termsText}>
                    <FormattedMessage
                      id="SignupForm.termsAndConditionsAcceptText"
                      values={{ termsLink }}
                    />
                  </span>
                </p>
                <PrimaryButton type="submit" inProgress={submitInProgress} disabled={submitDisabled} className={css.submitButton}>
                  <FormattedMessage id="SignupForm.signUp" />
                </PrimaryButton>
              </div>
            </div>
          }
        </Form >
      );
    }}
  />
);

SignupFormComponent.defaultProps = { inProgress: false };

const { bool, func } = PropTypes;

SignupFormComponent.propTypes = {
  inProgress: bool,

  onOpenTermsOfService: func.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const SignupForm = compose(injectIntl)(SignupFormComponent);
SignupForm.displayName = 'SignupForm';

export default SignupForm;
