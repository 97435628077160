import React from 'react';
import { bool, func, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import classNames from 'classnames';

// These relative imports need to point to correct directories
import {
  intlShape,
  injectIntl,
  FormattedMessage,
} from '../../util/reactIntl';
import { findOptionsForSelectFilter } from '../../util/search';
import { propTypes } from '../../util/types';
import { required } from '../../util/validators';
import config from '../../config';
import { Form, Button, FieldCheckboxGroup } from '../../components';

// Create this file using EditListingFeaturesForm.module.css
// as a template.
import css from './EditListingCategoriaForm.module.css';
import { localizeFilters } from '../../util/filtersLocalizationHelpers';

export const EditListingCategoriaFormComponent = props => (
  <FinalForm
    {...props}
    mutators={{ ...arrayMutators }}
    render={formRenderProps => {
      const {
        className,
        name,
        disabled,
        handleSubmit,
        intl,
        invalid,
        pristine,
        saveActionMsg,
        updated,
        updateError,
        updateInProgress,
        filterConfig,
      } = formRenderProps;

      const errorMessage = updateError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingCategoriaForm.updateFailed" />
        </p>
      ) : null;

      const classes = classNames(css.root, className);
      const submitReady = updated && pristine;
      const submitInProgress = updateInProgress;
      const submitDisabled = invalid || disabled || submitInProgress;

      const options = localizeFilters(findOptionsForSelectFilter('categoria', filterConfig), intl);

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          {errorMessage}
          <h1 className={css.title}>
            <FormattedMessage id="EditListingCategoriaForm.whatMakesSpecial" />
          </h1>
          <p className={css.textPlace} >
            <FormattedMessage id="EditListingCategoriaForm.categoriaPlaceholder" />
          </p>
          <FieldCheckboxGroup id={name} name={name} options={options} />
          <Button
            className={css.submitButton}
            type="submit"
            inProgress={submitInProgress}
            disabled={submitDisabled}
            ready={submitReady}
          >
            {saveActionMsg}
          </Button>
        </Form>
      );
    }}
  />
);

EditListingCategoriaFormComponent.defaultProps = {
  selectedPlace: null,
  updateError: null,
  filterConfig: config.custom.filters,
};

EditListingCategoriaFormComponent.propTypes = {
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  updated: bool.isRequired,
  updateError: propTypes.error,
  updateInProgress: bool.isRequired,
  filterConfig: propTypes.filterConfig,
};

export default compose(injectIntl)(EditListingCategoriaFormComponent);