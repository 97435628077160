import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './TermProtection.module.css';

const TermProtection = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  // prettier-ignore
  return (
    <div className={classes}>
   
      <p>
        <strong>Lee detenidamente estos Términos de la Protección contra Daños para ÛUPOs, ya que contienen información importante sobre tus derechos, recursos y obligaciones legales. Al publicar un Anuncio o utilizar la Plataforma UUPO.COM en calidad de ÛUPO, aceptas cumplir y quedar sujeto a estos Términos de la Protección contra Daños para ÛUPOs.</strong>
      </p>
      <p>
        <strong>La protección de daños para ÛUPOs es un servicio de UUPOCOWORKING SLL para la vigilancia, cuidado y mantenimiento de las normas  y fundamentos que hacen posible esta plataforma. Conoce los requisitos que aquí te presentamos para contribuir al  éxito de tu experiencia en ÛUPO.COM.</strong>
      </p>

      <ol type="I" className={css.listRefund2}>
      <li>
          <strong>Verificamos tu identidad: </strong>
          La seguridad y la privacidad de las personas que utilizan ÛUPO.COM son muy importantes para nosotros. Por eso, siempre estamos buscando formas de hacer que nuestra comunidad sea lo más segura posible para todo el mundo.
          <br></br>
          Queremos que todas las personas que usan la plataforma tengan la tranquilidad de saber que los ÛUPOs y los ÛUPERS en ÛUPO.COM son quienes dicen ser. Por eso, cuando reserves un POP UP o te hagas ÛUPO, verificaremos tus datos personales como tu nombre completo, DNI, dirección, número de teléfono y tus datos profesionales como denominación social de tu negocio, ubicación, nota simple de registro de la propiedad o contrato de alquiler, seguro RC del comercio y factura reciente de suministros.
          <p>¿Por qué verificamos tu identidad</p>
          <p>Verificar la identidad de los ÛUPOs y los ÛUPERs nos permite:</p>
          <ul className={css.listRefund}>
            <li>
              Asegurarnos de que son quienes dicen ser.
            </li>
            <li>Averiguar si un agente malintencionado se ha hecho con el control de una cuenta.</li>
            <li>Investigar problemas de seguridad como agresiones, robos o actividades fraudulentas.</li>
          </ul>
          <p>Interactúa siempre a través de ÛUPO.COM</p>
          <p>Por tu seguridad, te recomendamos que siempre realices todas las transacciones y te comuniques con otros usuarios a través de ÛUPO. Además, conviene que utilices el sistema de mensajes de la plataforma para conocer a los ÛUPERs y explicarles algunos detalles de tu negocio.</p>
        </li>
        <li><strong>Te recomendamos</strong>
          <p>Asegúrate de tener la cobertura necesaria.</p>
          <p>Comprueba con tu proveedor de seguros si es posible añadir una protección adicional a tu seguro RC del comercio para ÛUPERs (coworkers) o coméntaselo al arrendador.</p>
          <p>Programa de Protección de servicio frente a daños para ÛUPO</p>
          <p>ÛUseguro es un servicio de  protección de UUPOCOWORKING SLL que procura una cobertura de observación, seguimiento y supervisión; frente a determinados daños y situaciones que generen responsabilidad civil por parte de los ÛUPERs. Sin embargo, esta protección no sustituye a los seguros del comercio que deban contratar los propietarios o los arrendatarios, según el caso, ni constituyen una cobertura exhaustiva por responsabilidad civil. Por otra parte, puede que también tengas que cumplir otros requisitos en lo que respecta a los seguros.</p>
          <p>Recomendamos encarecidamente a todos los ÛUPOs que lean con atención las condiciones de su póliza de seguros y se aseguren de comprenderlas bien. No todos los planes de seguros cubren los daños o pérdidas materiales que pueda causar un ÛUPER en tu local.</p>
          <p>Relee la póliza del seguro del comercio que tengas contratado como propietario o arrendatario, y consulta a tu agente o aseguradora para comprobar si dispones de una cobertura adecuada que incluya responsabilidad civil y los daños materiales a la propiedad.</p>
          <p className={css.textItalic}>Ten en cuenta que UUPOCOWORKING SLL no ejerce ningún tipo de control sobre la conducta de los ÛUPOs y renuncia a toda responsabilidad a este respecto. Si los ÛUPO no cumplen con sus obligaciones, podríamos suspender su actividad o eliminar su cuenta del sitio web de ÛUPO.COM.</p>
          <p>Cómo ser un ÛUPO responsable:</p>
          <p>La actividad de los ÛUPOs es muy gratificante, pero requiere cierto nivel de compromiso.</p>
          <p>Hemos redactado este artículo para ayudar a los ÛUPOs en ÛUPO.COM a familiarizarse con sus obligaciones, así como para ofrecerles una visión general sobre las diferentes leyes, normativas y prácticas recomendadas que correspondan en su caso. Para formar parte de nuestra comunidad, debes respetar nuestras normas, como las normas de la comunidad que puedes ver en Tu seguridad es nuestra prioridad , y asegurarte de cumplir la legislación local y otras normativas específicas según tus circunstancias y tu zona.</p>
          <p>Este artículo no es exhaustivo y no puede considerarse asesoramiento jurídico o fiscal, de modo que te recomendamos que investigues por tu cuenta sobre estas cuestiones. Además, como no actualizamos este contenido en tiempo real, tendrás que consultar todas las fuentes y asegurarte de que la información no haya cambiado recientemente.</p>
          <p>Impuestos nacionales</p>
          <p>La fiscalidad es un tema complejo. Tus obligaciones tributarias pueden variar en función de tus circunstancias particulares, por lo que te recomendamos que te informes al respecto o que consultes a un asesor fiscal para obtener información más específica.</p>
          <p>La actividad a desarrollar por el ÛUPO es el coworking, atendiendo al criterio de que no sólo se estaría arrendando un espacio físico sino todos los servicios accesorios necesarios en un local para el desarrollo de una actividad por parte del ÛUPER.</p>
          <p>Le recomendamos inscribirse en los epígrafes correspondientes para desarrollar y comercializar un servicio de coworking en ÛUPO.COM</p>
          <p>Tenga en cuenta que no existiría un epígrafe de IAE específico para la prestación de servicios de coworking sino que serían varios en función de los servicios accesorios que se presten.</p>
          <ul className={css.listRefund}>
            <li>Epígrafe 861.2 “Alquiler de locales industriales y otros alquileres n.c.o.p.” por el arrendamiento del espacio físico.</li>
            <li>Epígrafe 856.1 “Alquiler de bienes de consumo” por el arrendamiento de mobiliario.</li>
            <li>Grupo 999 “Otros servicios n.c.o.p.” por la prestación de diversos servicios (servicio telefónico, internet, seguridad, limpieza)</li>
          </ul>
          <p>Por lo general, el dinero que ganas como ÛUPO en ÛUPO.COM se considera un ingreso y como tal estará sujeto a tributación según la normativa aplicable en función de la forma jurídica y país de residencia del ÛUPO.</p>
          <p>Puedes consultar los importes que has obtenido a través de ÛUPO.COM en tu resumen de ingresos.</p>
          <p><strong>Durante la celebración del POP UP, debes entregar la factura al ÛUPER en la que se haga constar el servicio de coworking por los días correspondientes, cargos extras e IVA.</strong></p>
          <p>Normativa y permisos</p>
          <p>Es importante que te asegures de que puedes hacer coworking en tu propiedad. A veces existen restricciones en los contratos que te impiden recibir ÛUPERs. Te recomendamos que solicites asesoramiento jurídico o que consultes tus contratos de arrendamiento para obtener más información sobre la normativa, las restricciones y las obligaciones que se aplican en tu caso.</p>
          <p>Contratos y permisos</p>
          <p>A veces, los contratos de arrendamiento establecen restricciones a la hora de subarrendar una local. Por eso, es importante que leas con detenimiento cualquier contrato que hayas firmado o que te pongas en contacto con el propietario para aclarar sobre la nueva comercialización de un nuevo servicio de coworking dentro de tu establecimiento.</p>
          <p>Es posible que se pueda añadir un anexo a tu contrato de alquiler para aclarar cualquier posible duda, así como las responsabilidades y obligaciones de todas las partes.</p>
          <p>Restricciones hipotecarias</p>
          <p>Si tu propiedad tiene una hipoteca (o está sujeta a cualquier otra forma de préstamo), ponte en contacto con la entidad acreedora para asegurarte de que no hay restricciones que impidan el coworking.</p>
          <p>Instalación o centro comercial compartidos</p>
          <p>Si compartes una instalación comercial con otros negocios, ayúdales a comprender las peculiaridades de celebrar POP Ups en tu establecimiento. Incluso podéis firmar un acuerdo para tener claro cuáles son los derechos y obligaciones de cada parte. Los acuerdos para compartir una instalación o centro comercial pueden incluir la frecuencia con la que planeas hacer coworking, cómo vas a asegurarte de que cumplan las normas, entre otras cuestiones.</p>
          <p>Cómo facilitar información sobre tu POP UP y su precio</p>
          <p>Descríbelo con exactitud y con todo lujo de detalles. No omitas información que pueda ser del interés del  ÛUPER.</p>
          <p className={css.textItalic}><strong>Nota: </strong>Esta página tiene carácter meramente informativo. La información que te ofrecemos no debe utilizarse con el fin de obtener asesoramiento en materia legal. Si tienes alguna duda sobre la forma en la que te afecta la normativa que mencionamos a continuación, te recomendamos que consultes a un abogado o a un experto en la materia</p>
          <p>Seguridad</p>
          <p>En ÛUPO, la seguridad de los ÛUPOS y los ÛUPERS  es una de nuestras grandes prioridades. Para contribuir a la tranquilidad de los ÛUPERs, puedes proporcionarles información útil para casos de emergencia o advertirles de cualquier posible riesgo. ¡Es un gesto muy sencillo que para ellos significa mucho!</p>
          <p>Información de contacto para emergencias</p>
          <p>Te recomendamos que cuentes en tu local una lista con los siguientes números de teléfono:</p>
          <ul className={css.listRefund}>
            <li>Los números de los servicios de emergencia locales.</li>
            <li>El número del hospital más cercano.</li>
            <li>Tu número de teléfono.</li>
            <li>El número de una persona de contacto en casos de emergencia (por si los ÛUPERs no logran comunicarse contigo).</li>
          </ul>
          <p>También es importante que los ÛUPERs sepan cuál es la mejor manera de contactar contigo si ocurre algo. Recuerda que comunicarte con ellos a través del sistema de mensajes de ÛUPO es una alternativa segura.</p>
          <p>Botiquín</p>
          <p>Prepara un botiquín de primeros auxilios para tu local e indica a los ÛUPERs dónde está. Además, comprueba regularmente que cuenta con todo lo necesario y repón lo que se haya gastado.</p>
          <p>Prevención de incendios</p>
          <p>Si tienes electrodomésticos o maquinaria que funcionen con gas, asegúrate de que cumplen la normativa aplicable en materia de seguridad e instala un detector de monóxido de carbono. También te recomendamos que tengas un extintor a la vista en el local y que se revise periódicamente.</p>
          <p>Salidas de emergencia</p>
          <p>Asegúrate de indicar claramente cuáles son los itinerarios de evacuación en caso de incendio. Deja un mapa con la ruta en un lugar de fácil acceso para los ÛUPERs.</p>
          <p>Prevención de riesgos</p>
          <p>A continuación, te dejamos algunos consejos que pueden ayudarte a evitar riesgos:</p>
          <ul className={css.listRefund}>
            <li>Recorre tu local y valora en qué zonas los ÛUPERs podrían tropezarse o caerse.</li>
            <li>Retira los elementos que puedan suponer un peligro o asegúrate de que están bien señalizados.</li>
            <li>Retira todos los cables que queden al descubierto.</li>
            <li>Asegúrate de que las escaleras son estables y cuentan con pasamanos.</li>
            <li>Retira o guarda bajo llave los objetos que puedan ser peligrosos para los ÛUPERs.</li>
          </ul>
          <p>Climatización</p>
          <p>Es fundamental que los electrodomésticos, como los calentadores o los aparatos de aire acondicionado, funcionen bien para que los ÛUPERs disfruten de una experiencia cómoda. Además, hay otras formas de garantizar que no pasen frío ni calor:</p>
          <ul className={css.listRefund}>
            <li>Asegúrate de que tu local esté bien ventilado.</li>
            <li>Indica cómo utilizar de forma segura la calefacción y el aire acondicionado.</li>
            <li>Comprueba que el termostato funcione correctamente y asegúrate de que los ÛUPERs sepan dónde está.</li>
            <li>Realiza el mantenimiento de los aparatos con regularidad.</li>
          </ul>
          <p>Aforo</p>
          <p>Establece e informa al ÛUPER del aforo máximo de clientes que pueden entrar en tu local de forma segura. Es posible que la administración local de tu zona disponga de normas al respecto.</p>
          <p>Civismo</p>
          <p>Ser un ÛUPO responsable consiste, en parte, en ayudar a que tus ÛUPERs entiendan cuál es la mejor forma de relacionarse con la comunidad. Cuando les comunicas las normas y costumbres locales, estás contribuyendo a generar una experiencia más agradable para todos.</p>
          <p>Normas del edificio</p>
          <p>Si el inmueble donde se encuentra tu local tiene zonas comunes o servicios compartidos, explica a los ÛUPERs cuáles son las normas.</p>
          <p>Normas de tu negocio</p>
          <p>Puedes incluir las normas de tu negocio como nota adicional en la sección Descripción de tu ÛUPO que encontrarás en tu cuenta de ÛUPO.COM. Los ÛUPERs suelen agradecer que les expliques desde el principio cómo esperas que se comporten en tu local.</p>
          <p>Vecinos</p>
          <p>Es recomendable que informes a tus vecinos de que estás planeando celebrar un POP UP. Así tendrán la oportunidad de manifestar las cuestiones que les preocupen y de facilitarte información que debas tener en cuenta.</p>
          <p>Ruidos</p>
          <p>Los ÛUPERs reservan POP UPs a través de ÛUPO.COM por muchas razones, como promocionar o reforzar su marca o llamar la atención sobre una idea. Para que tanto ellos como los residentes de tu comunidad disfruten de una experiencia agradable, recuérdales que el ruido puede molestar a tus vecinos.</p>
          <p>Si te preocupa que los ÛUPERs molesten a los vecinos, puedes poner en práctica varias estrategias para evitar el exceso de ruido:</p>
          <ul className={css.listRefund}>
            <li>Establece unas horas en las que hay que respetar el silencio.</li>
            <li>No permitas que los ÛUPERs lleven mascotas.</li>
            <li>Indica que tu local no es apto para niños ni bebés.</li>
            <li>Prohíbe las fiestas.</li>
          </ul>
          <p>Aparcamiento</p>
          <p>Informa a los ÛUPERs sobre las normas de aparcamiento de tu vecindario y de la zona. Aquí tienes algunos ejemplos:</p>
          <ul className={css.listRefund}>
            <li>Solo se puede aparcar en una plaza en concreto.</li>
            <li>Los martes y los jueves no se puede aparcar en la acera de enfrente de esta calle porque se llevan a cabo servicios de limpieza.</li>
            <li>Solo se puede aparcar en la calle entre las 19:00 y las 7:00.</li>
          </ul>
          <p>Mascotas</p>
          <p>Si permites que los ÛUPERs lleven a sus animales de compañía, agradecerán que les indiques los lugares idóneos para sacarlos a pasear y dónde deben desechar los residuos que generen. También es buena idea que les facilites algunas alternativas de emergencia, como el número de un hotel para mascotas cercano, por si el animal molestara.</p>
          <p>Privacidad</p>
          <p>Respeta siempre la privacidad de tu ÛUPER. Nuestras normas sobre dispositivos de vigilancia (1)  establecen claramente la conducta que esperamos de los ÛUPOs. Sin embargo, algunas zonas tienen leyes y normativas adicionales que debes conocer.</p>
          <p><strong>(1) Uso de cámaras y dispositivos de grabación: </strong></p>
          <p>Para garantizar la tranquilidad de los ÛUPOs y los ÛUPERs, se permiten medidas de seguridad como cámaras de seguridad y dispositivos de control de ruido, siempre y cuando se indiquen claramente en la descripción del anuncio y no vulneren la privacidad de ninguna persona. Las normas sobre dispositivos se aplican a todas las cámaras, dispositivos de grabación, mecanismos inteligentes y sistemas de vigilancia.</p>
          <p>¿Qué permitimos?</p>
          <p>Dispositivos declarados que solo se utilicen para supervisar espacios públicos y zonas comunes: se permiten dispositivos que sirvan para supervisar o vigilar una zona pública (p. ej., la puerta principal o una entrada para vehículos) o una zona común, siempre que estén claramente identificados y que se haya informado a los ÛUPERs sobre su existencia antes de reservar. Los espacios comunes no incluyen estancias privadas ni baño.</p>
          <p>La instalación de dichos dispositivos conlleva la implantación de medidas legales oportunas en aras del cumplimiento del Reglamento Europeo General de Protección de datos 2016/679.</p>
          <p>¿Qué no permitimos?</p>
          <ul className={css.listRefund}>
            <li>Dispositivos ocultos o no declarados que vigilen zonas comunes: cualquier dispositivo que vigile un espacio común debe instalarse de forma visible y aparecer en la descripción del anuncio.</li>
            <li>Dispositivos ubicados en zonas privadas o que las vigilen: los dispositivos nunca deben vigilar espacios privados (p. ej. Salas de descanso, baños o zonas comunes que se utilicen para dormir, como una sala de estar con un sofá cama). Puede haber dispositivos desconectados siempre y cuando estén apagados y los ÛUPERs tengan constancia de su existencia.</li>
          </ul>
          <p>Cancelación de reservas</p>
          <p>Envía un correo a: <a href="mailto:hola@uupo.es" className={css.enlaceEmail}>hola@uupo.es </a>con todos los datos de tu reserva para tramitar una cancelación. Nos pondremos en contacto a la mayor brevedad para agilizar el proceso.</p>
          <p>Aunque las cancelaciones por parte de los ÛUPOs son muy poco frecuentes y algunas se deben a motivos que escapan a su control, pueden suponer un gran trastorno para los ÛUPERs y deteriorar su confianza en nuestra comunidad.</p>
          <p>Modificaciones de reservas</p>
          <p>Envía un correo a: <a href="mailto:hola@uupo.es" className={css.enlaceEmail}>hola@uupo.es </a>con todos los datos de tu reserva para tramitar una modificación. Nos pondremos en contacto a la mayor brevedad para agilizar el proceso.</p>
          <p>ÛUPO y ÛUPER son responsables de cuantas modificaciones de reservas acepten realizar a través de la Plataforma ÛUPO.COM o que ordenen realizar al servicio de Atención al Cliente de UUPOCOWORKING SLL en su nombre (en lo sucesivo, «Modificaciones de Reservas»), y aceptan abonar cualquier importe, comisión o impuesto adicional asociado a cualquier Modificación de la Reserva.</p>
          <p>Anuncios</p>
          <p>Si eres ÛUPO y, al crear un nuevo anuncio, te pedimos que verifiques tu identidad, dispondrás de 48 horas para completar el proceso. Este plazo incluye el tiempo que tardes en mandarnos la información que te pidamos y el que nos lleve a nosotros verificar tu identidad.</p>
          <p>Una vez que hayamos confirmado tu identidad, podrás empezar a aceptar reservas.</p>
          <p>Cómo tratamos tus datos</p>
          <p>Únicamente los empleados autorizados de ÛUPO.COM podrán acceder a la información que nos envíes. Todos los datos se almacenan y transfieren mediante un cifrado seguro. Solo conservaremos tus datos personales el tiempo que sea necesario para cumplir las normativas aplicables y otros requisitos legales, tales como:</p>
          <ul className={css.listRefund}>
            <li>Los requisitos de seguridad y lucha contra el fraude</li>
            <li>Las normas de la comunidad de ÛUPO.COM</li>
            <li>Las normativas contra el blanqueo de capitales</li>
            <li>Las leyes fiscales y en materia de pagos</li>
          </ul>
          <p>De acuerdo con la legislación aplicable, eliminaremos los datos personales cuando la persona interesada así lo solicite.</p>
        </li>
        <li>
          <strong>Te informamos</strong>
          <p>Los ÛUPOs pueden beneficiarse del programa de Protección contra Daños para ÛUPOs que está sujeto a las exclusiones, limitaciones y otros términos y condiciones establecidos a continuación y según se modifiquen periódicamente. Estos Términos de la Protección contra Daños para ÛUPOs se aplican junto con los Términos y Condiciones de UUPOCOWORKING SLL y los ÛUPAGOS de UUPOCOWORKING SLL y constituyen las normas y servicios que hacen posible ÛUPO.COM.</p>
          <p>Si reconoces y aceptas estos Términos de la Protección contra Daños para ÛUPOs en nombre de una empresa u otra entidad legal, declaras y garantizas que tienes la autoridad para vincular a dicha empresa u otra entidad legal a estos Términos de la Protección contra Daños para ÛUPOs y, en tal caso, <strong>“tú”</strong> y <strong>“tuyo”</strong> se referirán y aplicarán a dicha empresa u otra entidad legal.</p>
          <p>Excepto en la medida en que la ley lo permita, estos Términos de la Protección contra Daños para ÛUPOs no afectarán tus derechos establecidos por ley. </p>
          <p>La Protección contra Daños para ÛUPOs no es una póliza de seguro. En la medida en que desees protección más allá de la Protección contra Daños para ÛUPOs, UUPOCOWORKING SLL te recomienda encarecidamente que contrates un seguro que te cubra a ti y a tu propiedad por las pérdidas causadas por ÛUPERs o Invitados de los ÛUPERs para el evento que tu pérdida no sea cubierta por los Términos de la Protección contra Daños para ÛUPOs.</p>
          <ol type="A" className={css.listRefund2}>
            <li>
              <strong>La Protección contra Daños para ÛUPOs: </strong>
              Reconoces y aceptas que harás todo lo que esté a tu alcance para ponerte en contacto con el ÛUPER Responsable (como se define a continuación) a la mayor brevedad posible tras detectar cualquier pérdida o daño material a tu Propiedad Cubierta. Deberás notificar tu reclamación a UUPOCOWORKING SLL y al ÛUPER Responsable y tratarás de llegar a un acuerdo con el ÛUPER Responsable para subsanar la pérdida o el daño dentro de los catorce (14) días posteriores a la fecha de salida del ÛUPER Responsable. Puedes cumplir con esta obligación presentando una reclamación a través de <a href="mailto:hola@uupo.es" className={css.enlaceEmail}>hola@uupo.es</a>.
              <br></br>
              Reconoces y aceptas que el importe de cualesquiera Pérdidas Cubiertas que tengas derecho a percibir por parte del ÛUPER con arreglo a la Protección contra Daños para ÛUPOS se reducirá de manera proporcional a las cantidades que ya hayas percibido por las mismas Pérdidas Cubiertas a través de una fuente distinta a la Protección contra Daños para ÛUPOs, lo que incluye a título enunciativo y no limitativo: (i) las cantidades percibidas con arreglo a una póliza de seguro, una garantía o una indemnización; (ii) un depósito de seguridad; o (iii) elcualquier pago efectuado directamente por el ÛUPER Responsable o un Invitado (definido a continuación) u otro tercero o la aseguradora o el garante de dicho tercero.
            </li>
            <li><strong>Definición de Términos Clave </strong>
              Los siguientes términos, cuando se escriban con mayúscula inicial, tendrán el significado que se les atribuye a continuación:
              <br></br>
              <strong>“Valor Efectivo Real”: </strong>significa la cantidad que costaría reparar o sustituir la Propiedad Cubierta que haya sufrido daños o haya sido destruida como resultado de una Pérdida Cubierta, calculada en la fecha en que se produzca dicha Pérdida Cubierta, con bienes o materiales del mismo tipo y calidad, y con la correspondiente deducción por obsolescencia y la amortización de su valor físico.
              <br></br>
              <strong>“Formulario de Solicitud de Pago de la Protección contra Daños para ÛUPOs”: </strong>significa el formulario estándar de UUPOCOWORKING SLL con la información necesaria que se detalla más abajo y contactando con el servicio de Atención al cliente directamente  a través de <a href="mailto:hola@uupo.es" className={css.enlaceEmail}>hola@uupo.es</a> 
              <br></br>
              <strong>“ÛUPO Cubierto”: </strong>significa un Establecimiento Comercial situado en el Territorio que ofrezca servicios de coworking en la forma de un POP UP y que (i) poseas o controles legalmente como ÛUPO durante el periodo de estancia del ÛUPER Responsable en dicho ÛUPO, y (ii) esté anunciado por ti en la Plataforma ÛUPO.com y haya sido reservado por dicho ÛUPER Responsable conforme a los Términos y Condiciones.
              <br></br>
              <strong>“Pérdidas Cubiertas”: </strong>significa y se limita a:
              <br></br>
              (i) Pérdidas físicas o daños materiales directos a la Propiedad Cubierta de un ÛUPO causados por el ÛUPER Responsable o un Invitado suyo durante los días reservados para la celebración de un POP UP en ÛUPO.com.
              <br></br>
              (ii) Pérdidas físicas o daños materiales a la Propiedad Cubierta de un ÛUPO causados por una mascota que sea propiedad o responsabilidad del ÛUPER Responsable o un Invitado suyo durante una estancia en ÛUPO.com.
              <br></br>
              (iii) Gastos de limpieza adicionales razonables, habituales e inesperados, para limpiar la Propiedad Cubierta de un ÛUPO en los que se incurra como resultado directo de la presencia en el ÛUPO de una mascota que sea propiedad o responsabilidad del ÛUPER Responsable o un Invitado suyo durante la celebración de un POP UP en ÛUPO.com.
              <br></br>
              (iv) Gastos adicionales de limpieza razonables, habituales e inesperados para limpiar la Propiedad Cubierta de un ÛUPO como resultado directo de los actos de un ÛUPER Responsable o un Invitado suyo durante una estancia en ÛUPO.com. Solo se pagarán los gastos de limpieza que excedan de los que se abonen a un ÛUPO o que este cobre en relación con dicha reserva.
              <br></br>
              Las Pérdidas Cubiertas no incluyen las pérdidas o los daños que se describen más adelante en la descripción de Pérdidas Excluidas.
              <br></br>
              <strong>“Propiedad Cubierta”:</strong>
              hace referencia, exclusivamente, a las siguientes propiedades situadas en un ÛUPO Cubierto o en un radio de 300 metros alrededor de este, en tanto en cuanto tengas interés en dichas propiedades, salvo que dicha propiedad constituya una Propiedad Excluida (conforme se define más adelante)
              <br></br>
              <strong>“Pérdidas Excluidas”: </strong>
              tendrá el significado que se le atribuye en la sección III que se encuentra más adelante.
              <br></br>
              <strong>“Propiedad Excluida”: </strong>
              significa cualquiera de las siguientes propiedades:
              <ol type="1" className={css.listRefund2}>
                <li>Divisas, dinero, metales preciosos en forma de lingote, billetes o valores.</li>
                <li>Tierra, agua o cualquier otra sustancia que se encuentre dentro o sobre el terreno, si bien esta exclusión no se aplica a (i) mejoras realizadas en el terreno como zonas ajardinadas, aceras y superficies pavimentadas, sin incluir la materia que se encuentra debajo de dicha propiedad, o (ii) el agua que contenga cualquier tipo de depósito cerrado, sistema de tuberías o cualesquiera otros equipos de procesamiento.</li>
                <li>Animales, incluidos el ganado y las mascotas, entre otros.</li>
                <li>Árboles en pie y cultivos plantados.</li>
                <li>Embarcaciones (incluidos, entre otros, barcos, yates, motos acuáticas y embarcaciones similares), aviones, naves espaciales y satélites. Esta exclusión de las embarcaciones no aplica a ninguna embarcación o avión que constituya un Pop Up Cubierto a menos que, en el momento de la pérdida, esté en tránsito o se haya movido a más de 3 metros de su ubicación fija habitual y se desplace a más de 1.6 kilómetros por hora.</li>
                <li>Vehículos (incluidos, entre otros, automóviles, scooters, vespas y motocicletas). Esta exclusión no aplica a ningún vehículo que sea un ÛUPO Cubierto. No obstante, esta exclusión sí aplica a vehículos que, en el momento de la pérdida, estén en tránsito o se hayan movido a más de 3 metros de su ubicación fija habitual y se desplacen a más de 1.6 kilómetros por hora.</li>
                <li>Minas subterráneas, pozos mineros o cualquier propiedad que se encuentre en dicha mina o pozo.</li>
                <li>Presas y diques.</li>
                <li>Propiedad en tránsito, salvo que se establezca lo contrario en los presentes Términos de la Protección contra Daños para ÛUPOs.</li>
                <li>Líneas de transmisión y distribución que se extiendan a más de 300 metros de distancia del ÛUPO Cubierto.</li>
                <li>Cualquier daño a una propiedad que no se encuentre en un ÛUPO Cubierto.</li>
                <li>Inmuebles que sean propiedad de alguien que no seas tú y que no estén bajo tu responsabilidad.</li>
                <li>Armas, incluidas, entre otras, armas de fuego, pistolas de aire comprimido, dispositivos disuasorios o de defensa personal como pistolas de descarga eléctrica o gas pimienta, municiones de cualquier tipo e imitaciones de armas de fuego, excepto si dichas armas se almacenan y protegen, y su presencia se comunica, de acuerdo con los Estándares y Expectativas de ÛUPOCOWORKING SLL u otras normas, con las modificaciones pertinentes en cada momento.</li>
                <li>Cámaras de seguridad y otros dispositivos de grabación, incluidas, entre otras, cámaras wifi (por ejemplo, Nest Cam o Dropcam), cámaras de supervisión, cámaras web instaladas en monitores, monitores para bebés, sistemas de vigilancia de cualquier tipo, monitores de decibelios y dispositivos o teléfonos inteligentes con grabación de video y audio, a menos que dichos dispositivos se ajusten a los Estándares y Expectativas de UUPOCOWORKING SLL u otras normas, con las modificaciones que corresponda en cada momento.</li>
              </ol>
              <strong>“Obras de Arte”: </strong>
              hace referencia a pinturas; grabados; fotografías impresas; cuadros; tapices; arte en cristal o cristal de gran rareza; vidrieras; alfombras valiosas; estatuas; esculturas; muebles antiguos; joyas antiguas; pequeños objetos artísticos de escaso valor; porcelanas y bienes similares de gran rareza, valor histórico o mérito artístico. Las “Obras de Arte” no incluyen automóviles, monedas, sellos, otros artículos coleccionables, colecciones, pieles, joyas, piedras preciosas, metales preciosos, embarcaciones, aviones, dinero ni valores.
              <br></br>
              <strong>“Invitado”: </strong>
              hace referencia a una persona a quien un ÛUPER Responsable ha invitado a acudir al ÛUPO Cubierto.
              <br></br>
              <strong>“Desgaste Normal”: </strong>
              significa deterioro del estado de la propiedad que se produce en condiciones normales de uso.
              <br></br>
              <strong>“ÛUPER Responsable”: </strong>
              significa el ÛUPER que reservó el ÛUPO Cubierto para el periodo durante el cual sufrió las Pérdidas Cubiertas.
              <br></br>
              <strong>“Territorio”: </strong>
              hace referencia a los países donde UUPOCOWORKING SLL  permite que el ÛUPO y la Protección contra Daños para ÛUPOs estén disponibles. Cualquier Territorio donde la Protección contra Daños para ÛUPOs no esté disponible se indicará en la página de destino de la Protección Contra Daños para ÛUPOs.
            </li>
            <li>
              <strong>Limitaciones y Exclusiones: </strong>
              La Protección contra Daños para ÛUPOs cubre únicamente las Pérdidas Cubiertas y no cubre nada de lo siguiente (“Pérdidas Excluidas”):
              <ol type="1" className={css.listRefund2}>
                <li>cualquier pérdida ocasionada por un ÛUPER o Invitado tras la finalización de la duración de la reservación que se muestra en el Anuncio correspondiente.</li>
                <li>en el caso de Obras de Arte, las pérdidas o daños sufridas por Obras de Arte que no pueden ser sustituidas por otras de características y calidad similares, así como las pérdidas o daños que puedan derivarse de cualquier proceso de reparación, restauración o retoque.</li>
                <li>
                  cualesquier pérdida, daño, costo o gasto de cualquier naturaleza, causado por, relacionado con o derivado de, directa o indirectamente, cualquiera de los siguientes:
                  <ol type="1" className={css.listRefund2}>
                    <li>Propiedad Excluida</li>
                    <li>Fenómenos naturales, incluidos, sin limitación, los terremotos y eventos meteorológicos, como huracanes y tornados</li>
                    <li>Consumo excesivo de electricidad, gas, combustible o agua u otros suministros proporcionados en el ÛUPO Cubierto</li>
                    <li>Causas indirectas o remotas</li>
                    <li>Interrupción de la actividad comercial, pérdida de mercado y/o pérdida de uso, excepto que la Protección contra Daños para ÛUPOs sí cubra la Pérdida de Ingresos por Reservaciones</li>
                    <li>Pérdida, daño o deterioro derivados de cualquier retraso</li>
                    <li>Desaparición, pérdida o falta misteriosa de inventario descubierta durante la elaboración de un inventario o cualquier diferencia inexplicable de inventario</li>
                    <li>Aplicación de cualquier ley o reglamento (i) que regule la construcción, reparación, sustitución, uso o retirada de cualquier propiedad, incluida la remoción de escombros o (ii) que requiera la demolición de cualquier propiedad, incluido el costo del retiro de sus escombros</li>
                    <li>Animales, lo que incluye lesiones producidas a animales, atención veterinaria, alojamiento, medicación y demás servicios relacionados con animales, excepto por los daños causados por mascotas como se definen en las subsecciones (ii) y (iii) de la definición de Pérdidas Cubiertas; o</li>
                    <li>Robo de identidad o utilización fraudulenta de la identidad.</li>
                  </ol>
                </li>
                <li>Cualquier pérdida, daño, costo o gasto de cualquier naturaleza causado, directa o indirectamente, por cualquiera de los hechos siguientes, con independencia de que haya existido cualquier otra causa o circunstancia que haya contribuido al mismo:
                  <ol type="1" className={css.listRefund2}>
                    <li>Cualquier acto hostil o acto de guerra, terrorismo, insurrección o rebelión</li>
                    <li>Uso o amenaza de uso malintencionado de sustancias químicas o biológicas tóxicas</li>
                    <li>Reacción nuclear, radiación o contaminación radiactiva</li>
                    <li>Incautación o destrucción de conformidad con la normativa aplicable en materia de cuarentena o aduanas, o confiscación por orden de cualquier autoridad pública o administrativa</li>
                    <li>Contrabando o transporte o comercio ilegal</li>
                    <li>Cualquier acto deshonesto, lo que incluye, sin limitación, cualquier robo cometido por ti o cualquier persona o entidad contratada por ti para hacer cualquier cosa en relación con la Pop Up Cubierta, salvo que dichas personas o entidades sean un ÛUPER Responsable o un Invitado y el acto en cuestión se lleve a cabo sin tu conocimiento; o</li>
                    <li>Falta de suministro eléctrico, de combustible, agua, gas, vapor, refrigerante, alcantarillado, servicios telefónicos o de internet por causas imputables a factores externos</li>
                  </ol>
                </li>
                <li>Las siguientes condiciones
                  <ol type="1" className={css.listRefund2}>
                    <li>Defectos de fábrica, material, construcción o diseño por cualquier causa</li>
                    <li>Deterioro, merma, oxidación, corrosión o erosión, defectos intrínsecos o defectos ocultos</li>
                    <li>Desgaste y Deterioro Normal</li>
                    <li>Asentamiento, agrietamiento, contracción, abultamiento o dilatación de cimientos, suelos, pavimentos, paredes, techos o tejados</li>
                    <li>Cambios de temperatura o humedad relativa; o</li>
                    <li>daños causados por insectos, animales o plagas excepto por daños causados por mascotas como se describe en las subsecciones (ii) y (iii) de la definición de Pérdidas Cubiertas; cualesquiera pérdidas, daños, reclamaciones, costos, gastos o demás cantidades directa o indirectamente relacionadas con la existencia de moho, hongos, esporas, virus, bacterias u otros microorganismos de cualquier tipo, clase o naturaleza, lo que incluye, sin limitación, cualquier sustancia cuya presencia suponga una amenaza real o potencial para la salud del ser humano. Esta exclusión se aplicará aun cuando exista (i) cualquier pérdida física o daño material en la Propiedad Cubierta; (ii) cualquier peligro o causa cubierta por esta Protección, esté o no contribuyendo de manera simultánea o en cualquier secuencia; (iii) cualquier pérdida de utilización, ocupación o funcionalidad; o (iv) la necesidad de llevar a cabo cualquier actuación, lo que incluye, sin limitación, una reparación, sustitución, retirada, limpieza, reducción, enajenación, reubicación o adopción de medidas oportunas para resolver cualquier incidencia sanitaria o problema legal. Esta exclusión no se aplica a costos de limpieza adicionales razonables, habituales e inesperados como se describe en las subsecciones (iii) y (iv) de la definición de Pérdidas Cubiertas.</li>
                  </ol>
                </li>
                <li>No se pagarán los costos derivados de la pérdida, pérdida de uso, daño, corrupción, imposibilidad de acceso, o incapacidad para manipular, cualquier y todos los Datos Electrónicos. “Datos Electrónicos” significa la información, los datos o los programas, almacenados como o en, creados o usados en, o transmitidos a o desde cualquier Medio Electrónico. “Medios Electrónicos” significa programas informáticos, incluidos sistemas y aplicaciones informáticas, discos duros o disquetes, CD-ROM, cintas, unidades, células, dispositivos de procesamiento de datos o cualquier otro medio utilizado con equipos controlados electrónicamente.</li>
                <li>Cualquier pérdida o daño que no se pueda recuperar del ÛUPER Responsable y/o Invitado según los Términos y Condiciones de UUPOCOWORKING.</li>
              </ol>
            </li>
            <li>
              <strong>Condiciones de la Protección contra Daños para ÛUPOs: </strong>
              Para poder recibir pagos en virtud de estos Términos de la Protección contra Daños para ÛUPOs, deberás cumplir cada una de las siguientes condiciones. Si no cumples con ellas, no tendrás derecho a recuperar ninguna Pérdida Cubierta. En todos los casos recaerá sobre ti la responsabilidad de demostrar que has cumplido con las siguientes condiciones.
              <br></br>
              Debes haber incurrido en Pérdidas Cubiertas.
              <br></br>
              Debes inspeccionar el ÛUPO Cubierto correspondiente para determinar si hay alguna pérdida física o daño en alguna Propiedad Cubierta, y debes notificar tu reclamación a UUPOCOWORKING SLL y al ÛUPER Responsable y tratar de resolver la pérdida o el daño con el ÛUPER Responsable en los catorce (14) días a partir de la fecha de salida del ÛUPER. Puedes cumplir con esta obligación presentando una reclamación a través del Centro de Resoluciones de ÛUPO.com.
              <br></br>
              No debes haber tergiversado ningún hecho o haber cometido fraude o cualquier otro acto deshonesto o engañoso en relación con la reserva de la Propiedad Cubierta o la preparación o presentación de cualquier solicitud de pago pasada o presente en virtud de los Términos de la Protección contra Daños para ÛUPOs. Cualquier tergiversación, fraude, acto deshonesto o engañoso de tu parte, en cualquier momento, resultará en la denegación de todas las solicitudes de pago pendientes de conformidad con los Términos de la Protección contra Daños para ÛUPOs y la terminación inmediata de estos Términos de la Protección contra Daños para ÛUPOs en la medida en que se relacionen contigo, sin perjuicio de la Sección VII a continuación.
              <br></br>
              Siempre que una Propiedad Cubierta resulte dañada o destruida debido a un incumplimiento de la ley o un robo, o un acto delictivo o delito, y presentes un Formulario de Solicitud de Pago de la Protección contra Daños para ÛUPOs, deberás presentar un informe o denuncia policial en el que figure dicha Propiedad Cubierta y entregar una copia de dicho informe o denuncia a UUPOCOWORKING SLL, y debes certificar que dicha copia es una copia fiel y exacta.
              <br></br>
              Debes proporcionar a UUPOCOWORKING SLL una prueba de propiedad o responsabilidad legal de la Propiedad Cubierta, en forma de recibos, fotografías, videos, documentos u otras formas habituales de prueba (incluidos, entre otros, formularios o avisos de tasación o valoración dirigidos a ti) certificados por ti como fieles y correctos y razonablemente aceptables para UUPOCOWORKING SLL.
              <br></br>
              En un plazo de treinta (30) días tras haber sufrido una Pérdida Cubierta, debes (i) llenar y presentar un Formulario de Solicitud de Pago de la Protección contra Daños para ÛUPOs y (ii) entregarnos una prueba firmada y declaración jurada de pérdida, a menos que te otorguemos una extensión de dicho plazo por escrito. La prueba de pérdida debe expresar tu saber y entender con respecto a lo siguiente:
              <ul className={css.listRefund}>
                <li>La hora, la causa y el origen de la Pérdida Cubierta, así como evidencia de dicha pérdida mediante recibos, fotografías, videos, documentos y otras formas verificables de prueba.</li>
                <li>La titularidad, los derechos de arrendamiento u otro interés que puedas tener tú y las demás partes en la Propiedad Cubierta para la que se reclama la Pérdida Cubierta.</li>
                <li>El Valor Efectivo Real y el valor de sustitución de cada artículo de la Propiedad Cubierta, así como la cuantía de dicha pérdida o daño para cada artículo de la Propiedad Cubierta.</li>
                <li>Todos los derechos de retención, gravámenes, hipotecas, garantías y demás contratos de seguro, independientemente de su vigencia, que cubran la Propiedad Cubierta objeto de la Pérdida Cubierta.</li>
                <li>Cualquier cambio producido en el título, uso, ocupación, ubicación, posesión o riesgos del Establecimiento Cubierto desde la fecha del Anuncio.</li>
                <li>La identidad y otra información conocida sobre el ÛUPER Responsable, cualquier Invitado y demás partes presentes en o que utilicen el ÛUPO Cubierto donde se encuentra la Propiedad Cubierta para la que se reclama la Pérdida Cubierta en la fecha que ocurrió la Pérdida Cubierta, y el propósito para el cual dicho ÛUPO Cubierto estaba siendo usado por las partes en dicha fecha y si en ese momento estaba ubicado en suelo arrendado.</li>
                <li>La fecha en la que te hayas puesto en contacto con el ÛUPER Responsable para solicitar el pago por la pérdida que esté reclamando, y la fecha en la que el ÛUPER Responsable haya rehusado efectuar el pago por la pérdida o no haya efectuado dicho pago.</li>
              </ul>
              Junto con la prueba de pérdida firmada y jurada descrita anteriormente, deberás brindar a UUPOCOWORKING SLL toda la información que UUPOCOWORKING solicite razonablemente para determinar el Valor Efectivo Real con respecto a la Propiedad Cubierta, lo que incluye: el precio de compra original de la Propiedad Cubierta, la fecha de adquisición de la Propiedad Cubierta, el estado de conservación de la Propiedad Cubierta y el costo estimado de reparación o sustitución de la Propiedad Cubierta.
              <br></br>
              Debes (i) proteger y preservar la Propiedad Cubierta dañada de futuras pérdidas o daños; y (ii) separar rápidamente la Propiedad Cubierta dañada de la intacta; colocarla en el mejor orden posible, y proporcionar un inventario completo de la propiedad perdida, destruida, dañada e intacta que muestre en detalle las cantidades, los costos, el Valor Efectivo Real, y la cantidad de la pérdida reclamada.
              <br></br>
              Debes, con la frecuencia que pueda ser razonablemente requerida por UUPOCOWORKING SLL o sus delegados, (i) mostrar todo lo que quede de cualquier Propiedad Cubierta dañada y firmar los documentos escritos de las inspecciones; (ii) presentar a examen todos los libros de cuentas, documentos comerciales, recibos, facturas y vales (ya sean originales o copias certificadas si los originales se han perdido); y (iii) permitir que se emitan extractos y se realicen copias por medios mecánicos de los documentos mencionados anteriormente.
              <br></br>
              Debes permitir a UUPOCOWORKING SLL o a su(s) delegado(s) realizar inspecciones de la Propiedad Cubierta en cualquier momento razonable. No obstante, el derecho a realizar inspecciones, la realización de las inspecciones y cualquier análisis, asesoramiento o informe de inspección no constituirá garantía alguna de que UUPOCOWORKING SLL o la aseguradora de UUPOCOWORKING SLL determinen o aseguren que la Propiedad Cubierta dañada es segura o se encuentra en buenas condiciones. No tendremos responsabilidad alguna ante ti o ninguna otra persona por cualquier inspección o la falta de inspección.
              <br></br>
              Deberás cooperar con UUPOCOWORKING SLL, y dicha cooperación incluirá firmar cualquier documento y responder de manera puntual a cualquier solicitud razonable de información o documentación adicional que pueda requerir o solicitar UUPOCOWORKING SLL o sus delegados para procesar el Formulario de Solicitud de Pago de la Protección contra Daños para ÛUPOs correspondiente.
            </li>
            <li>
              <strong>Disposición de las Solicitudes de Pago del ÛUPO</strong>
              <br></br>
              UUPOCOWORKING SLL completará el procesamiento de cualquier Formulario de Solicitud de Pago de la Protección contra Daños para ÛUPOs que presentes dentro de un periodo de tiempo razonable a partir de la fecha en que hayas (a) completado y presentado un Formulario de Solicitud de Pago de la Protección contra Daños para ÛUPOs, y (b) proporcionado a UUPOCOWORKING SLL toda la información y documentación que estés obligado a proporcionar para cumplir con las condiciones establecidas en las “Condiciones de la Protección contra Daños para ÛUPOs” indicadas anteriormente. En cualquier caso, haremos todos los esfuerzos que sean comercialmente razonables con el fin de procesar tu Formulario de Solicitud de Pago de la Protección contra Daños para ÛUPOs en un plazo de tres (3) meses a partir del momento en el que recibamos dichos documentos e información. Si recibes una Solicitud de Pago Aprobada (conforme se define a más adelante), como condición para que puedas recibir el pago con arreglo a la Protección contra Daños para ÛUPOs, deberás firmar y entregar a UUPOCOWORKING SLL el “Acuerdo de Solicitud de Pago Aprobada de la Protección contra ÛUPO”, mediante el cual acuerdas lo siguiente:
              <ol type='1' className={css.listRefund2}>
                <li>Ceder a UUPOCOWORKING SLL o tu aseguradora cualquier derecho o acción para reparar daños a los que tengas derecho para recuperar las cantidades que te hayan sido abonadas en relación a una Solicitud de Pago Aprobada (según se define a más adelante) por parte del ÛUPER Responsable o de un Invitado o por cualquier otra parte que sea financieramente responsable de las Pérdidas Cubiertas objeto de la Solicitud de Pago Aprobada</li>
                <li>Colaborar de manera razonable con nosotros, lo que incluye, a nuestra solicitud, comparecer como testigo en cualquier tribunal, arbitraje o procedimiento similar, si pretendemos recuperar la cantidad que te hubiera sido abonada con relación a la Solicitud de Pago Aprobada por el ÛUPER Responsable o por un Invitado o cualquier otro tercero</li>
                <li>Exonerar y eximir a UUPOCOWORKING SLL, a tu aseguradora y a todos los ejecutivos, directivos, empleados, contratistas y agentes de UUPOCOWORKING SLL de cualquier otra responsabilidad u obligación con respecto a los hechos y circunstancias de los asuntos y el incidente registrado en el Formulario de Solicitud de Pago de la Protección contra Daños para ÛUPOs</li>
                <li>Si se solicita, tratar como “información confidencial” la cantidad de cualquier pago realizado con arreglo a la Protección contra Daños para ÛUPOs; y</li>
                <li>Reembolsarnos cualquier cantidad que exceda la Pérdida Cubierta en la Solicitud de Pago Aprobada como resultado de un error del sistema o del procesamiento de pagos.</li>
              </ol>
              El tiempo que tome el procesamiento de cualquier Formulario de Solicitud de Pago de la Protección contra Daños para ÛUPOs que presentes dependerá, entre otros, de los siguientes factores: (i) la cantidad del pago que solicites por la Pérdida Cubierta; (ii) la ubicación del ÛUPO Cubierto; (iii) la naturaleza de la Propiedad Cubierta y la naturaleza de las Pérdidas Cubiertas; (iv) la integridad y el tipo de información y documentación que proporciones a UUPOCOWORKING SLL con respecto a las Pérdidas Cubiertas; y (v) la cantidad de Formularios de Solicitud de Pago de la Protección contra Daños para ÛUPOs que estén siendo procesados actualmente para otros ÛUPOs.
              <br></br>
              <strong>Solicitud de Pago Aprobada</strong>
              <br></br>
              Si presentaste un Formulario de Solicitud de Pago de la Protección contra Daños para ÛUPOs y dicha solicitud de pago es aprobada en su totalidad o en parte para una Pérdida Cubierta (definiéndose tal solicitud de pago aprobada como una “Solicitud de Pago Aprobada”), percibirás la cantidad correspondiente a la Pérdida Cubierta tal y como sea calculada por UUPOCOWORKING SLL o sus delegados. El proceso de cálculo de tales Pérdidas Cubiertas en el apartado “Determinación de la Cantidad de la Pérdida Cubierta” se describe a continuación. Recibirás una notificación por parte de ÛUPOCOWORKING SLL y, como condición de pago, a continuación, deberás entregar a UUPOCOWORKING SLL un Acuerdo de Solicitud de Pago Aprobada completado y firmado. UUPOCOWORKING SLL podrá utilizar proveedores de servicios externos para ayudar con el procesamiento de los Formularios de Solicitud de Pago de la Protección contra Daños para ÛUPOs y UUPOCOWORKING SLL o sus delegados podrán utilizar a proveedores de servicios externos para que asistan en la investigación y el ajuste de las solicitudes de pago relacionadas con los mismos.
              <br></br>
              Para una Solicitud de Pago Aprobada que incluya Pérdidas Cubiertas para una Propiedad Cubierta que sea propiedad de un tercero, nos reservamos el derecho, a nuestra entera discreción, de pagar toda o una parte de la cantidad cubierta en dicha Solicitud de Pago Aprobada ya sea a ti o directamente al propietario de dicha Propiedad Cubierta. Si el pago conforme a la Protección contra Daños para ÛUPOs de una parte o toda la cantidad es efectuado directamente al propietario de dicha Propiedad Cubierta, aceptas que dicho pago sea tratado para todos los efectos relacionados con la Solicitud de Pago Aprobada como si te hubiera sido pagado a ti directamente, y también acuerdas ser el único responsable de cobrar al propietario de dicha Propiedad Cubierta la parte de dicho pago respecto a la cual creas tener legalmente derecho. Para evitar dudas, tus obligaciones de indemnización establecidas a continuación en el párrafo titulado “Indemnización” se aplicarán a las reclamaciones que surjan de cualquier pago realizado a raíz de la Protección contra Daños para ÛUPOs, lo que incluye, sin limitación, cualquier pago realizado directamente al propietario de cualquier Propiedad Cubierta.
              <br></br>
              <strong>Determinación de la Cantidad de la Pérdida Cubierta</strong>
              <br></br>
              El monto de las Pérdidas Cubiertas será calculado a partir de la fecha de la pérdida, en la ubicación de la pérdida y no excederá del interés que poseas sobre las mismas, conforme a las siguientes condiciones:
              <ol type='1' className={css.listRefund2}>
                <li>En el caso de películas expuestas, grabaciones, manuscritos, dibujos y Medios Electrónicos, el valor en blanco más el costo de copiar la información de copias de seguridad o de los originales de una generación anterior. No se pagarán los costos de investigación, ingeniería o restauración o recreación de información o Datos Electrónicos perdidos.</li>
                <li>Por lo que corresponde a Obras de Arte, la menor de las siguientes cantidades (i) el costo razonable y necesario para reparar o restaurar dicha propiedad al estado físico en el que se encontraba en la fecha de la pérdida; (ii) el costo de sustitución del artículo; y (iii) el valor de tasación actual. Si la Obra de Arte forma parte de un par o conjunto, no recibirás ningún pago (1) por el costo derivado de la sustitución de cualquier elemento no dañado o restante que forme parte de tal par o conjunto, (2) por un importe superior al de la parte que el elemento perdido o dañado represente con respecto al valor asegurado del par o conjunto o (3) por el costo derivado de la sustitución o reparación de cualquier parte no dañada de las Obras de Arte que formen parte de un par, conjunto o juego o sean parte de un diseño o una función común cuando la pérdida o el daño se limiten a un área claramente identificable o a una parte específica.</li>
                <li>Para toda Propiedad Cubierta (excepto la que se describe en los párrafos 1 y 2 anteriores), la cantidad de la pérdida será la menor de las siguientes cantidades (i) el Valor Efectivo Real; (ii) el costo de reparación de dicha Propiedad Cubierta dañada; (iii) el costo de reconstrucción o sustitución de dicha Propiedad Cubierta dañada en el mismo lugar con nuevos materiales de tamaño, tipo y calidad similares; (iv) el costo de reconstrucción, reparación o sustitución en el mismo o en otro lugar, pero sin exceder el tamaño y la capacidad operativa que existía en la fecha de la Pérdida Cubierta; o (v) el costo de sustitución de equipos mecánicos o eléctricos irreparables, incluidos equipos informáticos y Medios Electrónicos, por equipos que sean lo más parecidos desde el punto de vista funcional a los dañados o destruidos, incluso si dichos equipos presentan ventajas tecnológicas y/o representan una mejora en su función y/o forman parte de un programa de mejora del sistema.</li>
                <li>Cualquier cantidad de cualquier Pérdida Cubierta que deba abonarse en virtud de la Protección contra Daños para ÛUPOs quedará reducida por la cantidad que ya te haya sido abonada a ti o a tu beneficio por un ÛUPER Responsable, Invitado u otra fuente (como una aseguradora, garante u otra parte responsable) por la misma Pérdida Cubierta.</li>
                <li>Las Pérdidas Cubiertas se abonarán en la divisa de Euros a menos que, a la entera discreción de UUPOCOWORKING SLL, UUPOCOWORKING SLL elija pagar las pérdidas en una divisa diferente. Si se requiriese hacer conversiones de divisa, utilizaremos un tipo de cambio general, conocido como el tipo de cambio básico, para la conversión de divisas utilizando datos de uno o más terceros, como OANDA (www.oanda.com).</li>
                <li>Cualquier cantidad de cualquier Pérdida Cubierta que deba abonarse en virtud de la Protección contra Daños para ÛUPOs no excederá la cantidad que, de otro modo, tú, como ÛUPO, podrías recuperar del Invitado o ÛUPER Responsable.</li>
              </ol>
              <strong>La Protección contra Daños para ÛUPOs no es una póliza de seguro. En la medida en que desees protección más allá de la Protección contra Daños para ÛUPOs, ÛUPOCOWORKING SLL te recomienda encarecidamente que contrates un seguro que te cubra a ti y a tu propiedad por las pérdidas causadas por ÛUPERs o Invitados de los ÛUPERs para el evento que tu pérdida no sea cubierta por los Términos de la Protección contra Daños para ÛUPOs.</strong>
            </li>
            <li>
              <strong>Reconocimientos y acuerdos por parte del ÛUPO: </strong>
              Reconoces y aceptas que:
              <ul className={css.listRefund}>
                <li>La Protección contra Daños para ÛUPOs es una garantía de la obligación principal del ÛUPER Responsable de pagarle, como ÛUPO, para reparar o reemplazar tu Propiedad Cubierta dañada o destruida como resultado de una Pérdida Cubierta, y está supeditada a dicha obligación.</li>
                <li>La Protección contra Daños para ÛUPOs está sujeta a que ejerzas los derechos y recursos que tienes contra el ÛUPER Responsable, o en contra de cualquier otra parte que sea financieramente responsable de la Pérdida Cubierta.</li>
                <li>Cualquier cantidad que UUPOCOWORKING SLL le pague en virtud de estos Términos de la Protección contra Daños para ÛUPOs no excederá la cantidad que tú, como ÛUPO, tienes derecho a recuperar del ÛUPER Responsable.</li>
                <li>UUPOCOWORKING SLL ofrece a los ÛUPOs los beneficios del servicio de Protección contra Daños para ÛUPOs descritos en el presente documento exclusivamente con el propósito de promocionar el uso de la Plataforma ÛUPO.com fidelizando a los clientes y reforzando la confianza de los clientes en lo que respecta al uso de la Plataforma ÛUPO.com.</li>
                <li>Estos Términos de la Protección contra Daños para ÛUPOs no pretenden constituir una oferta para asegurar, no constituyen un seguro ni un contrato de seguro ni tampoco sustituyen al seguro que hayas contratado o que puedas contratar. Adicionalmente, estos Términos de la Protección contra Daños para ÛUPOs no constituyen un contrato de servicios de seguros según se define en una póliza de seguros de acuerdo con el clausulado ISO o según la póliza de propietario o inquilino.</li>
                <li>Los beneficios previstos en virtud de estos Términos de la Protección contra Daños para ÛUPOs son exclusivamente tal y como se establecen en el párrafo anterior titulado “Protección contra Daños para ÛUPOs” y no podrás ceder ni transferir dichos beneficios, lo que incluye, sin limitación, cualquier transferencia o cesión por ministerio de ley o en relación con tu divorcio o fallecimiento.</li>
                <li>UUPOCOWORKING SLL y/o su aseguradora se reservan el derecho de investigar de manera independiente (o contratar a un investigador independiente), a su entera discreción y cargo, los hechos y las circunstancias de una solicitud de pago establecidos en cualquier Formulario de Solicitud de Pago de la Protección contra Daños para ÛUPOs que usted presente a UUPOCOWORKING SLL, independientemente de que usted haya presentado toda la información y documentación que está obligado a proporcionar a UUPOCOWORKING SLL para cumplir con las condiciones establecidas en la Sección IV anterior titulada “Condiciones de la Protección contra Daños para ÛUPOs”.</li>
              </ul>
              Reconoces y aceptas que si presentas una reclamación de acuerdo con la Protección contra Daños para ÛUPOs, otorgas tu consentimiento para que UUPOCOWORKING SLL lea todas las comunicaciones entre tú y el ÛUPER presuntamente Responsable a través de la Plataforma ÛUPO.com.
              <br></br>
              UUPOCOWORKING SLL se reserva el derecho, en cualquier momento, de compensar o deducir de las cantidades pagaderas o pagadas por UUPOCOWORKING SLL a ti en virtud de estos Términos de la Protección contra Daños para ÛUPOs, cualesquier cantidades que pudiese tener en su posesión, o a cobrar posteriormente, de cualesquier otra persona o entidad que esté obligada a compensarte respecto de tus pérdidas o daños.
              <br></br>
              Debido a que estos Términos de la Protección contra Daños para ÛUPOs constituyen un acuerdo de garantía, la teoría de la exoneración es aplicable. Por tanto, si la Propiedad Cubierta en cuestión o el riesgo asociado a dicha Propiedad Cubierta cambia sustancialmente, UUPOCOWORKING SLL tendrá el derecho de exoneración con respecto a cualquier obligación potencial de garantía en virtud de estos Términos de la Protección contra Daños para ÛUPOs.
              <br></br>
              Reconoces y aceptas que UUPOCOWORKING SLL tiene el derecho, a su discreción, a denegar el pago total o parcial en virtud de estos Términos de la Protección contra Daños para ÛUPOs en caso de que no cumplas, en cualquier momento, con tus obligaciones establecidas en los Términos y Condiciones, los ÛUPAGOS y nuestras políticas.
            </li>
            <li>
              <strong>Modificación o Rescisión de los Términos de la Protección contra Daños para ÛUPOs: </strong>
              En la medida en que la legislación aplicable en tu jurisdicción lo permita, UUPOCOWORKING SLL se reserva el derecho de modificar o rescindir estos Términos de la Protección contra Daños para ÛUPOs, en cualquier momento, a su entera discreción.
              <br></br>
              Si UUPOCOWORKING SLL rescinde estos Términos de la Protección contra Daños para ÛUPOs, UUPOCOWORKING SLL se te notificará mediante correo electrónico al menos treinta (30) días antes de dicha terminación y UUPOCOWORKING SLL continuará procesando todos los Formularios de Solicitud de Pago de la Protección contra Daños para ÛUPOs que hayas presentado antes de la fecha de efecto de la terminación, pero tu derecho a presentar cualquier nuevo Formulario de Solicitud de Pago de Protección contra Daños para ÛUPOs quedará rescindido inmediatamente.
              <br></br>
              Si UUPOCOWORKING SLL modifica estos Términos de la Protección contra Daños para ÛUPOs, publicaremos la modificación en la Plataforma ÛUPO.com. UUPOCOWORKING SLL continuará procesando todos los Formularios de Solicitud de Pago de la Protección contra Daños para ÛUPOs que hayas presentado antes de la fecha de entrada en vigor de la modificación.
              <br></br>
              Adicionalmente y sin limitar los derechos de UUPOCOWORKING SLL establecidos anteriormente en el párrafo inmediatamente precedente, UUPOCOWORKING SLL se reserva el derecho a modificar o rescindir estos Términos de la Protección contra Daños para ÛUPOs de forma general o en cualquier jurisdicción, en cualquier momento y a su entera discreción, si: (i) estos Términos de la Protección contra Daños para ÛUPOs son interpretados como una oferta para asegurar o constituir un seguro o un contrato de seguro o acuerdo de servicio de seguro por parte de cualquier autoridad gubernamental u organismo regulador en cualquier jurisdicción; (ii) UUPOCOWORKING SLL es obligada a obtener una licencia o permiso de cualquier tipo para continuar ofreciendo estos Términos de la Protección contra Daños para ÛUPOs en cualquier jurisdicción; o (iii) UUPOCOWORKING SLL determina, o un tribunal o árbitro sostiene, que las disposiciones de estos Términos de la Protección contra Daños para ÛUPOs infringen la legislación aplicable. Si UUPOCOWORKING SLL modifica o rescinde estos Términos de la Protección contra Daños para ÛUPOs de acuerdo con lo antedicho, UUPOCOWORKING SLL procesará todos los Formularios de Solicitud de Pago de la Protección contra Daños para ÛUPOs que presentes antes o hasta la fecha de efecto de dicha modificación o rescisión a menos que dicho procesamiento esté prohibido por una ley, regulación, ordenanza, orden o decreto de cualquier autoridad gubernamental o de otro tipo.
              <br></br>
              La versión actual de los Términos de la Protección contra Daños para ÛUPOs estará disponible a través del Sitio y la Aplicación. La versión relevante es aquella vigente en la fecha en que UUPOCOWORKING SLL recibe el Formulario de Solicitud de Pago de la Protección contra Daños para ÛUPOs.
            </li>
            <li>
              <strong>Subrogación: </strong>
              UUPOCOWORKING SLL y/o su aseguradora tienen el derecho de subrogarse contra cualquier persona o entidad que supuestamente sea responsable de las pérdidas o daños en cuestión, que pueden incluir todos y cada uno de los derechos que tú, como ÛUPO, puedas tener contra el ÛUPER Responsable, un Invitado o cualquier otro tercero de conformidad con los Términos y Condiciones de UUPOCOWORKING SLL. Además, por la presente, garantizas que, con respecto a cualquier pago realizado de conformidad con la Protección contra Daños para ÛUPOs, por UUPOCOWORKING SLL o en tu nombre, ayudarás y cooperarás plenamente con UUPOCOWORKING SLL en relación a todos y cada uno de los esfuerzos para conseguir la subrogación.
            </li>
            <li>
              <strong>Descargos y limitaciones de responsabilidad: </strong>
              Si optas por utilizar la Plataforma ÛUPO.com como ÛUPO, lo harás bajo tu propia cuenta y riesgo. La Protección contra Daños para ÛUPOs se ofrece “tal cual”, sin garantía de ningún tipo, ni expresa ni implícita.
              <br></br>
              Reconoces y aceptas que, en la máxima medida permitida por la ley, asumes todo el riesgo derivado del acceso y uso de la Plataforma ÛUPO.com y tu anuncio de cualquier Pop Up a través de la Plataforma ÛUPO.com. Ni UUPOCOWORKING SLL ni ninguna otra parte involucrada en la creación, producción o entrega de la Plataforma ÛUPO.com será responsable de ningún daño indirecto, especial, ejemplar o resultante, incluida la pérdida de beneficios, pérdida de datos o pérdida de fondo de comercio, interrupción de servicio, daños informáticos o fallos de sistemas, ni ningún daño por (1) lesiones personales o corporales o angustia emocional que surja de o tengan conexión con estos Términos de la Protección contra Daños para ÛUPOs, (2) la utilización o la imposibilidad de utilizar la Plataforma ÛUPO.com, (3) cualquier comunicación, interacción o reunión con otros usuarios de la Plataforma ÛUPO.com u otras personas con quienes te comuniques o interactúes como resultado de tu uso de la Plataforma ÛUPO.com o (4) tu anuncio de cualquier Pop Up a través de la Plataforma ÛUPO.com. UUPOCOWORKING SLL no será responsable de ninguno de los daños descritos anteriormente, ya sea con base en una garantía, contrato, agravio (incluida la negligencia), responsabilidad de producto u otra teoría legal, y sin importar si se informó o no a UUPOCOWORKING SLL de la posibilidad de dicho daño, incluso si se confirma que ha fallado el propósito esencial de alguna medida limitada para reparar daños a la que tengas derecho y que se haya establecido en el presente documento.
              <br></br>
              Excepto por lo que respecta a las obligaciones de UUPOCOWORKING SLL de abonarte determinadas cantidades en relación con una Solicitud de Pago Aprobada de conformidad con estos Términos de la Protección contra Daños para ÛUPOs, en ningún caso la responsabilidad total de UUPOCOWORKING SLL que surja de o en relación con (a) estos Términos de la Protección contra Daños para ÛUPOs; (b) tu utilización o imposibilidad de utilizar la Plataforma ÛUPO.com, lo que incluye, pero no se limita a, la publicación de un Anuncio, (c) cualquier Pop Up y (d) tus interacciones con otros Miembros, superará las cantidades que le hayas abonado UUPOCOWORKING SLL en el periodo de doce (12) meses anteriores al evento que haya dado origen a la responsabilidad, o cien euros (100 EUR) si no se ha realizado ningún pago, según sea aplicable. Las limitaciones de las indemnizaciones por daños y perjuicios anteriormente estipuladas son elementos fundamentales del contrato entre UUPOCOWORKING SLL y tú. Algunas jurisdicciones no permiten la exclusión de determinadas limitaciones de responsabilidad y, por lo tanto, las limitaciones anteriores pueden no ser aplicables a ti en tu caso.
              <br></br>
              Si resides en la UE, ÛUPOCOWORKING SLL es responsable en virtud de las disposiciones reglamentarias por actuaciones dolosas y por negligencia grave cometida por nosotros, nuestros representantes legales, directivos y demás agentes indirectos. Lo mismo será aplicable respecto a la asunción de garantías o cualquier otra responsabilidad objetiva o en caso de lesión culposa que afecte a la vida, a la integridad física o a la salud. UUPOCOWORKING SLL es responsable de cualquier incumplimiento negligente de las obligaciones contractuales básicas que sea cometido por nosotros, nuestros representantes legales, directivos y demás agentes indirectos. Las obligaciones contractuales esenciales son aquellas obligaciones de UUPOCOWORKING SLL en cuyo cumplimiento correcto confías con regularidad y debes confiar en pos de la correcta ejecución del contrato, pero el importe estará limitado a la indemnización por daños y perjuicios previsibles y normales. Queda excluida toda responsabilidad adicional de UUPOCOWORKING SLL.
            </li>
            <li>
              <strong>Disposiciones generales: </strong>
              <br></br>
              <strong>Indemnización</strong>
              <br></br>
              Aceptas liberar, defender, indemnizar y exonerar a UUPOCOWORKING SLL y sus afiliadas y subsidiarias, y sus ejecutivos, directivos, empleados y agentes en paz y a salvo en relación a cualquier reclamación, responsabilidad, daño, pérdida y gasto, lo que incluye, sin limitación, los honorarios legales y contables razonables que surjan en relación a o estén conectados de cualquier manera con la Protección contra Daños para ÛUPOs y estos Términos de la Protección contra Daños para ÛUPOs.
              <br></br>
              Si alquilas (en lugar de poseer) el Establecimiento que anuncias como ÛUPO Cubierto, el apartado inmediatamente anterior se aplica específicamente a cualquier disputa que pudiere surgir entre tú y el propietario del Establecimiento. Tú eres el único responsable de obtener el permiso del arrendador para anunciar el Pop Up con ÛUPOCOWORKING SLL y cumplir con los límites de cualquier permiso que se conceda.
              <br></br>
              <strong>Acuerdo Íntegro</strong>
              <br></br>
              Estos Términos de la Protección contra Daños para ÛUPOs constituyen el entendimiento y acuerdo completo y exclusivo entre UUPOCOWORKING SLL y tú con respecto a la Protección contra Daños para ÛUPOs y estos Términos de la Protección contra Daños para ÛUPOs sustituyen y reemplazan a todos y cualesquiera acuerdos anteriores, escritos u orales, suscritos entre UUPOCOWORKING SLL y tú con respecto a la Protección contra Daños para ÛUPOs.
              <br></br>
              <strong>Cesión</strong>
              <br></br>
              No puedes ceder ni transferir estos Términos de la Protección contra Daños para ÛUPOs, por ministerio de ley o de ningún otro modo, sin el consentimiento previo y por escrito de UUPOCOWORKING SLL. Cualquier intento por tu parte de ceder o transferir estos Términos de la Protección contra Daños para ÛUPOs, sin dicho consentimiento, será nulo de pleno derecho y no tendrá ningún efecto. UUPOCOWORKING SLL podrá ceder o transferir estos Términos de la Protección contra Daños para ÛUPOs, a su entera discreción, sin ninguna restricción. Tu derecho a terminar el Contrato con UUPOCOWORKING SLL no se verá afectado. De conformidad con lo anterior, estos Términos de la Protección contra Daños para ÛUPOs serán vinculantes y redundarán en beneficio de las partes, sus sucesores y cesionarios autorizados.
              <br></br>
              <strong>Avisos</strong>
              <br></br>
              Salvo que se indique lo contrario, cualquier aviso u otra comunicación destinada a los Miembros, que esté permitida o sea requerida a tenor de este Contrato, se realizará por escrito y será entregada por UUPOCOWORKING SLL mediante correo electrónico, una notificación en la Plataforma ÛUPO.com o por servicio de mensajes (incluidos SMS y WeChat).
              <br></br>
              <strong>Renuncia y Divisibilidad</strong>
              <br></br>
              En el supuesto de que UUPOCOWORKING SLL no aplicara cualquier derecho o disposición de estos Términos de la Protección contra Daños para ÛUPOs, ello no constituirá una renuncia a la aplicación futura de dicho derecho o disposición. La renuncia a dicho derecho o disposición únicamente será efectiva si se realiza por escrito y está firmada por un representante de UUPOCOWORKING SLL debidamente autorizado. Excepto por lo establecido expresamente en estos Términos de la Protección contra Daños para ÛUPOs, el ejercicio por cualquiera de las partes de cualquiera de las medidas para reparar daños a las que tengan derecho en virtud de estos Términos de la Protección contra Daños para ÛUPOs, lo será sin perjuicio del resto de las medidas para reparar daños a las que tengan derecho en virtud de estos Términos de la Protección contra Daños para ÛUPOs o de otro modo. Si, por cualquier motivo, un árbitro o un tribunal de jurisdicción competente determinaran que cualquiera de las disposiciones de estos Términos de la Protección contra Daños para ÛUPOs no tuvieren validez o no fuesen aplicables, dicha disposición será aplicada en lo que mayormente sea permisible y las otras disposiciones de estos Términos de la Protección contra Daños para ÛUPOs seguirán teniendo plena validez y efecto.
            </li>
            <li>
              <strong>Cómo ponerte en contacto con ÛUPOCOWORKING SLL: </strong>
              Si tienes alguna pregunta sobre los Términos de la Protección contra Daños para ÛUPOs envíanos un correo electrónico con los siguientes datos:
              <br></br>
              <strong>INFORMACIÓN DE LA RESERVA</strong>
              <ul className={css.listRefund}>
                <li>Código de la reserva</li>
                <li>Nombre del ÛUPO</li>
              </ul>
              <strong>DATOS DEL REMITENTE:</strong>
              <ul className={css.listRefund}>
                <li>Nombre</li>
                <li>Apellidos:</li>
                <li>Comunidad Autónoma</li>
                <li>Número de teléfono</li>
                <li>Correo electrónico</li>
              </ul>
              <strong>DATOS DEL INCIDENTE:</strong>
              <ul className={css.listRefund}>
                <li>Fecha del incidente</li>
                <li>Lugar del incidente</li>
                <li>
                  Tipo de pérdida
                  <ul className={css.listRefund}>
                    <li>Daños en la propiedad</li>
                    <li>Lesiones</li>
                    <li>Lesiones y daños en la propiedad</li>
                  </ul>
                </li>
                <li>Descripción del incidente</li>
              </ul>
            </li>
          </ol>
        </li>
      </ol>
    </div>
  );
};

TermProtection.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

TermProtection.propTypes = {
  rootClassName: string,
  className: string,
};

export default TermProtection;
