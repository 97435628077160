import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './CookiesPolicy.module.css';

const CookiesPolicyEnglish = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  // prettier-ignore
  return (
    <div className={classes}>
  
      <p className={css.titleRefund}>
        Last updated: December 1, 2022
      </p>
  
      <p className={css.titleRefund}>
        Responsible: This website is owned by ÛUPOCOWORKING SLL.
      </p>
  
      <p className={css.titleRefund}>
        Data Protection Officer: hola@uupo.es
      </p>
  
      <p>In this cookie policy, you will find the description and purpose of each of the cookies used, as well as their name, the sending server, their expiration, and, if applicable, the existence of an international data transfer.</p>
  
      <p>The legal basis for the use of strictly functional cookies is that the processing is necessary to comply with a legal obligation, according to Law 34/2002 (Law on Information Society Services and Electronic Commerce).</p>
  
      <p>The legal basis for the use of cookies for analytical, optimization, personalization, behavioral advertising, and assessment purposes is based on the consent you have given, in accordance with Art. 6.1.a) of Regulation (EU) 2016/679.</p>
  
      <p>At "ÛUPO", we use cookies to provide a better service and give you a better browsing experience. We want to inform you clearly and precisely about the cookies we use, detailing below what a cookie is, what it is for, why it is important, what types of cookies we use, their purpose, and how you can configure or disable them if you wish.</p>
  
      <p className={css.titleRefund}>
        Definition and General Function of Cookies:
      </p>
      
      <p>
        This website uses cookies and/or similar technologies that store and retrieve information when you browse. In general, these technologies can serve various purposes, such as recognizing you as a user, obtaining information about your browsing habits, or personalizing the way content is displayed.
      </p>
  
      <p className={css.titleRefund}>
        Why are they important?
      </p>
  
      <p>From a technical standpoint, they allow online services to work more agilely and adapted to user preferences, such as storing the language, the currency of the country, or detecting the access device.</p>
      <p>They establish levels of protection and security that prevent or hinder cyber-attacks against the website or its users.</p>
      <p>They serve to optimize the advertising we show users, offering the one that best suits their interests.</p>
     
      <p className={css.titleRefund}>
        How are the different types of cookies used on this site classified?
      </p>
      <p>By duration:</p>
      <ul className={css.listRefund}>
        <li><strong>Session cookies: </strong>expire when the user leaves the page or closes the browser, i.e., they are active during the visit to the service and therefore expire when leaving or a few hours after accessing.</li>
        <li><strong>Persistent cookies: </strong>expire when the purpose for which they serve is fulfilled or when they are deleted manually, they have a deletion date, and are usually used in online purchase processes, personalizations, or in registration, so as not to have to constantly enter our password.</li>
      </ul>
      <p>By ownership:</p>
      <ul className={css.listRefund}>
        <li><strong>Own cookies: </strong>are those sent to the user's terminal equipment from a device or domain managed by the editor itself and from which the service requested by the user is provided.</li>
        <li><strong>Third-party cookies: </strong>are those sent to the user's terminal equipment from a device or domain not managed by the editor, but by another entity that processes the data obtained through cookies.</li>
      </ul>
      <p>By purpose: see the intended purposes below:</p>
      <ul className={css.listRefund}>
        <li><strong>Analytics: </strong>are those that, processed by us or by third parties, allow us to quantify the number of users and thus perform the measurement and statistical analysis of the use made by users of the offered service. For this, your navigation on our website is analyzed to improve the offer of products or services we offer you, for example, Google Analytics.</li>
        <li><strong>Behavioral advertising: </strong>are those that, processed by us or by third parties, allow us to analyze your browsing habits on the Internet so that we can show you advertising related to your browsing profile.</li>
      </ul>
      <p>By type:</p>
      <ul className={css.listRefund}>
        <li><strong>Necessary: </strong>allow you to navigate the website and use its basic functions. They are usually installed only in response to actions you take that result in a request for services, such as accessing a secure area of our website, logging in, or filling out forms.</li>
        <li><strong>Not necessary: </strong>all other cookies.</li>
      </ul>
      <p>The following cookies are used on this website:</p>
      <table className={css.tableCookies} style={{width: "100%", fontSize: "14px"}}>
        <tr>
          <td className={css.titleTable}>Cookie</td>
          <td className={css.titleTable}>Type</td>
          <td className={css.titleTable}>Duration</td>
          <td className={css.titleTable}>Description</td>
        </tr>
        <tr>
          <td>euCookiesAccepted</td>
          <td>Necessary</td>
          <td>10 years</td>
          <td>Saves whether the visitor has clicked the continue button on the cookie use warning banner</td>
        </tr>
        <tr>
          <td>st-token de servicio-token</td>
          <td>Necessary</td>
          <td>1 month</td>
          <td>Saves the access token to the Sharetribe server, necessary for the transfer of information from the web application</td>
        </tr>
        <tr>
          <td>st-token de servicio-oauth2State</td>
          <td>Necessary</td>
          <td>30 minutes</td>
          <td>State cookie used for authentication authorization</td>
        </tr>
        <tr>
          <td>st-token de servicio-pkceCodeVerifier</td>
          <td>Necessary</td>
          <td>30 minutes</td>
          <td>Verification cookie used for authentication authorization</td>
        </tr>
        <tr>
          <td>st-authinfo</td>
          <td>Necessary</td>
          <td>15 minutes</td>
          <td>Used to allow the visitor to create a new user with an IdP (identity provider)</td>
        </tr>
        <tr>
          <td>st-autherror</td>
          <td>Necessary</td>
          <td>15 minutes</td>
          <td>Used to show the visitor information about an authentication error with an IdP (identity provider)</td>
        </tr>
      </table>
      <p className={css.titleRefund}>
        Cookie settings:
      </p>
      <p>If you want to delete or block the download of cookies, below are the links to the main browsers and devices so you have all the information to check how to manage cookies in your browser.</p>
      <p>For more information on the Opera™ Browser click <a href='https://help.opera.com/en/latest/web-preferences/#cookies' target="_blank" rel="noopener noreferrer">here</a></p>
      <p>For more information on the Google Chrome™ Browser click <a href='https://support.google.com/chrome/answer/95647' target="_blank" rel="noopener noreferrer">here</a></p>
      <p>For more information on the Google Chrome™ Browser on Android click <a href='https://support.google.com/chrome/answer/95647' target="_blank" rel="noopener noreferrer">here</a></p>
      <p>For more information on the Internet Explorer™ Browser click <a href='https://support.microsoft.com/en-us/topic/delete-and-manage-cookies-168dab11-0753-043d-7c16-ede5947fc64d' target="_blank" rel="noopener noreferrer">here</a></p>
      <p>For more information on the Edge™ Browser click <a href='https://support.microsoft.com/en-us/microsoft-edge/browsing-data-and-privacy-efc908ea-81fc-12b0-2d66-bb0a0f59454d' target="_blank" rel="noopener noreferrer">here</a></p>
      <p>For more information on the Mozilla Firefox™ Browser click <a href='https://support.mozilla.org/en/kb/enable-and-disable-cookies-websites-track-preferences' target="_blank" rel="noopener noreferrer">here</a></p>
      <p>For more information on the Safari™ Browser on macOS click <a href='https://support.apple.com/guide/safari/sfri11471/mac' target="_blank" rel="noopener noreferrer">here</a></p>
      <p>For more information on the Safari™ Browser on iOS click <a href='https://support.apple.com/en-us/HT201265' target="_blank" rel="noopener noreferrer">here</a></p>
      <p className={css.titleRefund}>
        Cookie acceptance:
      </p>
      <p>When you first access this website, you will see a window informing you about the use of cookies and where you can consult this "Cookie Policy". You can change the cookie settings at any time by configuring your browser to accept or reject the cookies you receive or for the browser to notify you when a server wants to save a cookie. We inform you that if you block or do not accept the installation of cookies, it is possible that certain services will not be available without their use or that you may not be able to access certain services or make full use of everything our website offers.</p>
      <p>"ÛUPO" appreciates your consent to accept cookies, as this helps us obtain more precise data that allows us to improve the content and design of our web pages to adapt them to your preferences.</p>
  
    </div>
  );
  
};

CookiesPolicyEnglish.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

CookiesPolicyEnglish.propTypes = {
  rootClassName: string,
  className: string,
};

export default CookiesPolicyEnglish;
