import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './CommunicationSafety.module.css';

const CommunicationSafety = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  // prettier-ignore
  return (
    <div className={classes}>
      <p className={css.titleRefund}>Por qué revisamos los mensajes en ÛUPO</p>

      <p>En ÛUPO, la seguridad de nuestros usuarios, tanto los ÛUPERs como los ÛUPOs, es nuestra prioridad. Por eso, prestamos especial atención a la forma en que se comunican los miembros de nuestra comunidad.</p>

      <p>Podríamos revisar, examinar o analizar los mensajes que envíes o recibas a través de la plataforma de ÛUPO. Lo hacemos con varios ﬁnes, entre ellos:</p>

      <ul className={css.listRefund}>
        <li>Asegurar el correcto funcionamiento de nuestros servicios.</li>
        <li>Detectar posibles riesgos para nuestros usuarios.</li>
        <li>Asegurar el cumplimiento de las normativas aplicables.</li>
        <li>Investigar posibles incumplimientos.</li>
        <li>Hacer cumplir nuestros Términos de Servicio y políticas, incluida nuestra Política de Inclusión.</li>
        <li>Proporcionar atención al cliente.</li>
        <li>Realizar estudios y análisis.</li>
        <li>Mejorar o expandir nuestros productos y servicios.</li>
      </ul>

      <p className={css.titleRefund}>
        Mantén tus conversaciones en ÛUPO
      </p>

      <p>Comunicarse o realizar pagos fuera de nuestra plataforma puede suponer ciertos riesgos. Uno de los principales motivos por los que analizamos los mensajes es para proteger a los miembros de la comunidad de posibles fraudes en línea. Por eso, te recomendamos que solo te comuniques a través del hilo de mensajes de ÛUPO hasta que hayas realizado una reserva conﬁrmada.</p>

      <p>Si te comunicas y reservas a través de ÛUPO, disfrutarás de la seguridad de nuestra plataforma de pago seguro, acceso a nuestro servicio de atención al cliente y estarás cubierto por nuestra protección para ÛUPOs y pagos.</p>

      <p>En algunos casos, nuestro sistema puede bloquear mensajes que contengan información que incluya datos de contacto, referencias a otras plataformas o enlaces externos, con el ﬁn de asegurar la seguridad de todos.</p>

      <p><strong>Tu seguridad es importante para nosotros</strong>, pero también respetamos tu privacidad. Nunca revisaremos ni analizaremos tus mensajes con ﬁnes publicitarios, ni compartiremos tus comunicaciones con terceros.</p>
    </div>
  );
};

CommunicationSafety.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

CommunicationSafety.propTypes = {
  rootClassName: string,
  className: string,
};

export default CommunicationSafety;
