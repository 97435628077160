import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './SecurityPriority.module.css';

const SecurityPriorityEnglish = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  // prettier-ignore
  return (
    <div className={classes}>
      <p>
        To make you feel that your proposal belongs anywhere in the world, it is necessary to establish trust based on coherent expectations of the behavior of ÛUPOs and ÛUPERs. We established these community standards as a guide for conduct and to define the values that represent the foundations of our global community.
      </p>

      <p>This document is regularly updated, as we never stop refining our approach to meet the needs of our community. These five standards (safety, protection, fairness, authenticity, and reliability) are the basic pillars of our efforts to help ensure safety and foster a sense of belonging. We continually strive to strengthen and implement them.</p>

      <p className={css.titleRefund}>
        Safety
      </p>
      
      <p>
        Your ÛUPO.com adventure begins the moment you embrace the new and unknown. This is only possible when you feel safe within this community. As a result, we ask that you refrain from endangering or threatening others.
      </p>

      <p className={css.titleRefund}>
        Harm to Yourself or Others
      </p>
      
      <p>
        You must not commit physical or sexual assault, sexual abuse, sexual harassment, domestic violence, theft, human trafficking, other acts of violence, or detain anyone against their will. Members of dangerous organizations, including terrorist, organized crime, and racist groups, are not welcome in this community. UUPOCOWORKING SLL is committed to cooperating with law enforcement as necessary and responding to their valid requests.
      </p>
      <p>We take suicide, eating disorders, and drug abuse very seriously and work to help people in crisis.</p>

      <p className={css.titleRefund}>
        How Claims Work
      </p>
      
      <p>
        To be eligible for an alternative establishment or a refund, the ÛUPER who confirmed the reservation can contact us to file a claim. Claims must be submitted no later than 72 hours after becoming aware of the Organizational Disruption and must be accompanied by relevant evidence, such as photographs or confirmation of the conditions from the ÛUPO. We will evaluate the available evidence to determine if an Organizational Disruption has occurred.
      </p>

      <p className={css.titleRefund}>
        Threatening Someone
      </p>
      
      <p>
        You must not attempt to harm others verbally or physically. Similarly, threats of deliberate self-harm are as serious to us as the actions we may take if such an event occurs.
      </p>

      <p className={css.titleRefund}>
        Creating Dangerous Situations
      </p>
      
      <p>
        You must not leave weapons, disease risk factors, or dangerous animals within reach in your space, nor should you create conditions that increase the likelihood of a fire or obstruct evacuation routes in case of an emergency.
      </p>
    
      <p className={css.titleRefund}>
        Security
      </p>
      
      <p>
        Members of the ÛUPO.com community share their potentials, businesses, and neighborhoods. Whether you are opening your business as a ÛUPO or experiencing it as a ÛUPER, we want you to always trust in your safety. We ask that you respect the property of others, their information, and personal belongings.
      </p>

      <p className={css.titleRefund}>
        Theft, Vandalism, or Extortion
      </p>
      
      <p>
      You must not take belongings that are not yours, use someone else's property without their permission, copy other people's keys or IDs, damage someone else's property, stay in a space after your stay has ended, or threaten anyone with negative reviews or any other sanction or harm to obtain compensation or other benefits.
      </p>

      <p className={css.titleRefund}>
        Spam, Theft of Confidential Information, or Fraud
      </p>
      
      <p>
        You must not conduct transactions outside the platform; engage in credit fraud or money laundering; attempt to divert traffic to other sites or products unrelated to the marketplace; divert payments made to third parties; abuse our referral system; or make false statements against another community member.
      </p>

      <p className={css.titleRefund}>
        Violating Others' Privacy or Intellectual Property Rights
      </p>
      
      <p>
        You must not spy on others; having cameras in your space is not allowed unless you disclose their presence in advance and they are visible, and they are never allowed in private areas (such as bathrooms or bedrooms). You must not access others' accounts without their permission, nor violate their privacy, copyrights, or trademarks.
      </p>

      <p className={css.titleRefund}>
        Fair Treatment
      </p>
      
      <p>
        The global ÛUPO.com community is as diverse, unique, and vibrant as the world around us. Fairness is what keeps us together, allowing us to integrate into a community and feel that we truly belong anywhere.
      </p>

      <p className={css.titleRefund}>
        Discriminatory Conduct or Hate Speech
      </p>
      
      <p>
        You must treat everyone respectfully in every interaction. You must adhere to relevant laws and not treat anyone differently based on their race, ethnicity, nationality, religion, sexual orientation, sex, gender, gender identity, disability, or serious illness. Consequently, we do not tolerate insults or offenses to others based on these grounds.
      </p>

      <p className={css.titleRefund}>
        Intimidating or Harassing Others
      </p>
      
      <p>
        You must not share other people's personal information to humiliate or blackmail them, display abusive behavior towards them, defame them, or violate our evaluation and content rules.
      </p>

      <p className={css.titleRefund}>
        Disturbing the Nearby Community
      </p>
      
      <p>
        You must not disrupt common areas, professionals, or neighbors, create widespread and severe disturbances for those around you, or fail to respond to concerns from professionals, neighbors, or the community.
      </p>

      <p className={css.titleRefund}>
        Authenticity
      </p>
      
      <p>
        Your experiences as a ÛUPO/ÛUPER should be filled with charming moments and surprising events. Since our community is based on trust, honesty is essential; it relies on a balance between common expectations, authentic interactions, and truthful information.
      </p>

      <p className={css.titleRefund}>
        Lying About Your Identity
      </p>
      
      <p>
        You must not provide a false name or date of birth, use listings for different commercial purposes without your ÛUPO's authorization, maintain duplicate accounts, or create an account if you are under 18 years old.
      </p>

      <p className={css.titleRefund}>
        Lying About Your Spaces
      </p>
      
      <p>
        You must not provide incorrect or misleading information in your listing about the location, availability, or type of property of your Pop Up, switch one Pop Up for another, create fraudulent listings, leave false reviews, apply deceptive pricing, or conceal information about potential hazards or habitability issues.
      </p>

      <p className={css.titleRefund}>
        Experiences That Are Only Transactions
      </p>
      
      <p>
        UUPOCOWORKING SLL started as a way to allow people to share their Commercial Spaces temporarily. Since its inception, UUPOCOWORKING SLL has grown, and shared establishments have spread throughout Spain, but we still expect each space to be more than just a transaction, a place where others can belong.
      </p>

      <p className={css.titleRefund}>
        Reliability
      </p>
      
      <p>
        Every ÛUPO.com experience is unique, and the details of each location, town, and ÛUPO are unique. Since our community's commitments are based on these details, we need to be able to trust each other, whether through timely communication, the condition of a space, or the expectations we set.
      </p>

      <p className={css.titleRefund}>
        Offering Inappropriate Spaces
      </p>
      
      <p>
        You must not provide commercial spaces with a cleanliness level below standard or that do not have running water or electricity. You must not offer spaces where exhibitions cannot be held, that do not receive potential customers, or that do not have access to sanitary facilities (e.g., telling ÛUPERs to use public restrooms).
      </p>

      <p className={css.titleRefund}>
        Breaking Commitments
      </p>
      
      <p>
        Except for force majeure, you must not cancel after the deadline set in the listing's cancellation policy, prevent check-in, fail to pay, or break the local rules and community standards.
      </p>

      <p className={css.titleRefund}>
        Not Responding
      </p>
      
      <p>
        You must not continuously and repeatedly receive low ratings, be absent during the booking process or the commercial event, fail to provide an appropriate point of contact, or refuse to participate in our resolution process.
      </p>
    </div>
  );

};

SecurityPriorityEnglish.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

SecurityPriorityEnglish.propTypes = {
  rootClassName: string,
  className: string,
};

export default SecurityPriorityEnglish;
