export const localizeFilters = (list, intl) => {
    if (!list) return;

    return list?.map((f) => {
        return {
            key: f.key,
            label: intl.formatMessage({ id: "Filters." + f.key })
        }
    })
}

export const localizeFilterLabelAndOptions = (list, intl) => {
    if (!list) return;

    return list?.map((f) => {
        f.label = intl.formatMessage({ id: "Filters." + f.id });
        if (f.config.options) {
            f.config.options = localizeFilters(f.config.options, intl)
        }

        return f;
    })
}

export const localizeLabel = (key, intl) => {
    if (!key) return;

    return intl.formatMessage({ id: "Filters." + key });
}